import {
  SET_GAMES_ADMIN,
  SET_TICKET_ADMIN,
  SET_WINNERS,
  SET_WINNERS_COUNT,
  SET_WIN_NUMBERS,
} from "./actions";
import { SET_USERS } from "./actions";

let initialState = {
  games: {
    results: [],
    count: 0,
  },
  users: {
    results: [],
    count: 0,
  },
  ticketNumbers: [false, false, false, false, false, false],
  winners: [],
  winnersCount: 1,
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GAMES_ADMIN:
      return {
        ...state,
        games: action.payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_TICKET_ADMIN:
      return {
        ...state,
        ticketNumbers: state.ticketNumbers.map((ticket, index) => {
          if (index === action.index) return action.number;
          else {
            return ticket;
          }
        }),
      };
    case SET_WIN_NUMBERS:
      return {
        ...state,
        ticketNumbers: action.newNumbers,
      };
    case SET_WINNERS:
      return {
        ...state,
        winners: action.payload,
      };
    case SET_WINNERS_COUNT:
      return {
        ...state,
        winnersCount: action.payload,
      };
    default:
      return state;
  }
};
