import React from 'react'
import c from "./ATable.module.scss";
var classNames = require('classnames/bind');
var cx = classNames.bind(c);

export const ATable = ({children, className}) => {
  let classNameCX = cx({
    'ATable': true,
    [className]: true
  });
  return (
    <div className={classNameCX}>
      {children}
    </div>
  )
}
ATable.defaultProps = {
  className: ''
};