import React, { useState } from "react";
import { getBase64 } from "utils/base64/base64";
import classes from "./ImageUploader.module.scss";
export const ImageUploader = ({ image, setImage, setIsError }) => {
  // const [image, setImage] = useState(undefined);
  const [imageBase64, setImageBase64] = useState("");
  // const [isError, setIsError] = useState(false);
  let deletePicture = () => {
    setImage(undefined);
  };
  let onUpload = (e) => {
    let file = e.target.files[0];
    if (Math.round(file.size / 1024) >= 10000) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setImage(file);

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setImageBase64(result);
      })

      .catch((err) => {});
  };

  return (
    <div>
      {image ? (
        <div className={classes.editImage}>
          <div className={classes.imageWrapper}>
            <img
              // @ts-ignore
              src={imageBase64}
            />
          </div>
          <div className="iconBucket" onClick={deletePicture}></div>
        </div>
      ) : (
        <label>
          <div className={classes.imageUploader}>
            <div className={classes.label}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                >
                  <path
                    d="M4 13H10V7H14L7 0L0 7H4V13ZM0 15H14V17H0V15Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className={classes.text}>Choose file</div>
            </div>
          </div>
          <input
            onChange={onUpload}
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
          />
        </label>
      )}
    </div>
  );
};
