import { AuthAPI } from "api/auth/authAPI";
import { IsErrorAC, setUserAC, toggleIsFetchingAC } from "./actions";
import { instance } from "api/axios-instance";
import jwt_decode from "jwt-decode";
import { displayToast } from "toast/toast";

// import { useHistory } from "react-router-dom";
export const signUpThunk = (user, succsess) => {
  return (dispatch) => {
    dispatch(toggleIsFetchingAC(true));
    AuthAPI.user
      .signUpAPI(user)
      // @ts-ignore
      .then((response) => {
        dispatch(toggleIsFetchingAC(false));
        dispatch(IsErrorAC(false));
        succsess();
      })
      .catch((error) => {
        dispatch(toggleIsFetchingAC(false));
        dispatch(IsErrorAC(error.response.data));
      });
  };
};
export const signInThunk = (
  user,
  handler,
  setIsError,
  sendRedirect,
  openBTC
) => {
  return (dispatch) => {
    dispatch(toggleIsFetchingAC(true));
    AuthAPI.user
      .signInAPI(user)
      .then((response) => {
        dispatch(toggleIsFetchingAC(false));
        let access = response.data.access;
        var decoded = jwt_decode(access);
        // @ts-ignore
        localStorage.setItem("exp", decoded.exp);
        localStorage.setItem("access", access);
        localStorage.setItem("refresh", response.data.refresh);
        dispatch(IsErrorAC(false));
        dispatch(getProfileThunk());
        handler();
        if (!response.data.is_admin) {
          if (!response.data.onboarding_complete) {
            openBTC();
          }
        }
        sendRedirect(response.data.is_admin);
      })
      .catch((error) => {
        dispatch(toggleIsFetchingAC(false));
        // dispatch(IsErrorAC(error.response.data));
        setIsError(error.response.data);
      });
  };
};

export const getProfileThunk = () => {
  return (dispatch) => {
    dispatch(toggleIsFetchingAC(true));
    AuthAPI.user
      .get()
      .then((response) => {
        dispatch(toggleIsFetchingAC(false));
        dispatch(setUserAC(response.data));
        dispatch(IsErrorAC(false));
        let timeout;
        let interval;
        // let interval;
        const GetSecondsToExpireToken = () => {
          let exp = Number(localStorage.getItem("exp")) * 1000;
          let date = Date.now();
          return exp - date - 10*1000;
          // return exp - date - 50*1000;
        }
        const refreshTimeout = () => {
          if (timeout) {
            clearTimeout(timeout);
          }
          if (interval) {
            clearInterval(interval)
          }
          let timeToRefresh = GetSecondsToExpireToken();
          interval = setInterval(() => {
            let timeToRefresh = GetSecondsToExpireToken()
            console.log('Seconds to refresh', Math.round(timeToRefresh/1000));
          }, 1000);
          timeout = setTimeout(async () => {
            if (interval) {
              clearInterval(interval)
            }
            await dispatch(await refresh());
            let timeToRefresh = GetSecondsToExpireToken()
            if (timeToRefresh > 0) {
              refreshTimeout();
            }
          }, timeToRefresh);
        };
        refreshTimeout();
      })
      .catch((error) => {
        if (error.status === 401) {
          let refreshToken = localStorage.getItem("refresh");

          //@ts-ignore
          dispatch(refresh(refreshToken));
        } else {
          dispatch(setUserAC(false));
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          delete instance.defaults.headers.common["Authorization"];
          // let history = useHistory();

          // history.push("/");
          dispatch(toggleIsFetchingAC(false));
          if (error.response !== undefined)
            dispatch(IsErrorAC(error.response.data));
          }
        });
  };
};
export const setProfileThunk = (user, handle) => {
  return (dispatch) => {
    dispatch(toggleIsFetchingAC(true));
    AuthAPI.user
      .set(user)
      .then((response) => {
        dispatch(setUserAC(response.data));
        dispatch(toggleIsFetchingAC(false));
        handle();
      })
      // @ts-ignore
      .catch((er) => {
        dispatch(toggleIsFetchingAC(false));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    dispatch(setUserAC(false));
    delete instance.defaults.headers.common["Authorization"];
  };
};
export const refresh = () => {
  // @ts-ignore
  return async (dispatch) => {
    await AuthAPI.user.refreshToken().then((response) => {
      let access = response.data.access;

      var decoded = jwt_decode(access);
      // @ts-ignore
      localStorage.setItem("exp", decoded.exp);
      localStorage.setItem("access", access);
      instance.defaults.headers.common["Authorization"] = `Bearer ${access}`;
    }).catch(() => {
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      dispatch(setUserAC(null));
    })
  };
};
export const resetPasswordThunk = (email, setServerError) => {
  // @ts-ignore
  return (dispatch) => {
    AuthAPI.user
      .resetPassword(email)
      .then((response) => {})
      // @ts-ignore
      .catch((er) => {
        setServerError(er.response.data);
      });
  };
};
export const confirmThunk = (uid, token, setIsError) => {
  // @ts-ignore
  return (dispatch) => {
    AuthAPI.user
      .confirm(uid, token)
      .then((response) => {})
      // @ts-ignore
      .catch((er) => {
        setIsError(true);
      });
  };
};
export const changePasswordThunk = (token, setErr) => {
  return (dispatch) => {
    AuthAPI.user
      .changePassword(token)
      .then((response) => {
        let access = response.data.access;
        var decoded = jwt_decode(access);
        // @ts-ignore
        localStorage.setItem("exp", decoded.exp);
        localStorage.setItem("access", access);
        localStorage.setItem("refresh", response.data.refresh);
        dispatch(IsErrorAC(false));
        dispatch(getProfileThunk());
      })
      .catch((err) => {
        setErr(err);
      });
  };
};
export const setNewPasswordThunk = (password, hadnler) => {
  return (dispatch) => {
    AuthAPI.user
      .setNewPassword(password)
      .then((response) => {
        displayToast("password successfully changed");
        hadnler();
      })
      .catch((err) => console.log(err));
  };
};
export const resendEmailThunk = (email, setAfterResend) => {
  return (dispatch) => {
    AuthAPI.user
      .reactivate(email)
      .then((response) => {
        setAfterResend(true);
      })
      .catch((err) => console.log(err));
  };
};
