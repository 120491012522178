import React, { useState } from "react";
import { MyGames } from "../myGames/MyGames";
import { ProfileSettings } from "../ProfileSettings/ProfileSettings";
import c from "./../Profile.module.scss";
import cx from "classnames";
import { HistoryOfGames } from "../HistoryOfGames/HistoryOfGames";
import { Invites } from "../invaites/Invites";
export const Tabs = () => {
  const [activeTab, setActiveTab] = useState("games");
  let setTab = (tabName) => {
    setActiveTab(tabName);
  };
  const selectedTab = () => {
    if (activeTab === "games") {
      return <MyGames />;
    }
    if (activeTab === "settings") {
      return <ProfileSettings />;
    }
    if (activeTab === "historyOfGames") {
      return <HistoryOfGames />;
    }
    if (activeTab === "Invites") {
      return <Invites />;
    }
  };

  return (
    <div>
      <div className={c.tabs}>
        <div
          className={cx(c.tab, activeTab === "games" ? c.active : "")}
          onClick={() => setActiveTab("games")}
        >
          My games
        </div>
        <div
          className={cx(c.tab, activeTab === "Invites" ? c.active : "")}
          onClick={() => setActiveTab("Invites")}
        >
          Invites
        </div>
        <div
          className={cx(c.tab, activeTab === "settings" ? c.active : "")}
          onClick={() => setActiveTab("settings")}
        >
          Settings
        </div>

        <div
          className={cx(c.tab, activeTab === "historyOfGames" ? c.active : "")}
          onClick={() => setActiveTab("historyOfGames")}
        >
          History of games
        </div>
      </div>
      {selectedTab()}
      {/* {activeTab === "games" ? <MyGames /> : <ProfileSettings />} */}
    </div>
  );
};
