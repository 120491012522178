import React, {useState, useEffect} from "react";
import { configAPI } from "api/admin/configAPI";
import c from "./Invites.module.scss";
import cn from "classnames";
import { Tickets } from "components/ticketsList/Tickets";
import { useSelector } from "react-redux";
import { displayToast } from "toast/toast";
export const Invites = () => {
  // @ts-ignore
  const [maxBonusTickets, setMaxBonusTickets] = useState(0);
  const inviteLink = useSelector((state) => state.userReducer.user.ref_link);
  const free_tickets = useSelector(
    // @ts-ignore
    (state) => state.userReducer.user.free_tickets
  );
  useEffect(() => {
    configAPI.config.get().then((response) => {
      setMaxBonusTickets(response.data.free_tickets_num);
    });
  }, []);
  return (
    <div className={c.invites}>
      <div className={c.invites__share}>
        {maxBonusTickets && <div className={c.title}>
          Share an invite and get up to {maxBonusTickets} bonus tickets
        </div>}
        <div className={c.content}>
          <div className={c.text}>
            When the new user picks their first ticket you will get a bonus
            ticket
          </div>
          <div className={c.refer}>
            <div>{inviteLink}</div>
            <div
              className={"copy-icon cursor-pointer"}
              onClick={() => {
                navigator.clipboard.writeText(inviteLink);
                displayToast("Copied to clipboard", "bottom-left");
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className={c.invites__tickets}>
        <div className={c.iconWrapper}>
          <div className={c.wrap}>
            <div className={"prize-icon"}></div>
          </div>
        </div>
        <div>
          <div className={cn(c.title, "flex center")}>You got</div>
          <div className={cn(c.subtitle, "flex center")}>
            {free_tickets} Bonus Tickets
          </div>
        </div>
        <div className={c.ticketWrapper}>
          <div className={c.ticketWrapper__tickets}>
            {/* <Tickets
              tickets={[
                {
                  numbers: [4, 10, 16, 25, 31],
                },
              ]}
              size={"31px"}
            /> */}
          </div>
          {/* <div className={c.ticketWrapper__openTicket}>Open ticket</div> */}
        </div>
      </div>
    </div>
  );
};
