export const PICK_NUMBER = "PICK_NUMBER";
export const SET_NUMBERS = "SET_NUMERS";
export const SET_GAME = "SET_GAME";
export const SET_WINNING_NUMBERS = "SET_WINNING_NUMBERS";
export const pickNumberAC = (index, number) => {
  return { type: PICK_NUMBER, index, number };
};

export const setNumbers = (payload) => {
  return { type: SET_NUMBERS, payload };
};

export const setGameAC = (payload) => {
  return { type: SET_GAME, payload };
};

export const setWinNumbersAC = (payload) => {
  return { type: SET_WINNING_NUMBERS, payload };
};
