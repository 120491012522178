import { AButton } from "components-admin/AButton/AButton";
import { APageName } from "components-admin/APageName/APageName";
import { APagination } from "components-admin/APagination/APagination";
import { ATable } from "components-admin/ATable/ATable";
import { ATableCol } from "components-admin/ATableCol/ATableCol";
import { ATableRow } from "components-admin/ATableRow/ATableRow";
import CCalendar from "components-сommon/CCalendar/CCalendar";
import Modal from "react-modal";
import React, { useEffect } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import c from "./Games.module.scss";
import { CreateEditGameModal } from "./components/CreateEditGameModal/CreateEditGameModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getGamesThunkAdmin,
  publishGameThunk,
  startGameThunk,
  unpublishGameThunk,
} from "store/reducers/admin-reducer/thunk";
import { getGamesThunk } from "store/reducers/games-reducer/thunk";
import { Game } from "./components/Game/Game";
import ACalendar from "./components/aCalendar/ACalendar";

var classNames = require("classnames/bind");
var cx = classNames.bind(c);
export const Games = () => {
  // @ts-ignore
  const games = useSelector((state) => state.adminReducer.games.results);
  // @ts-ignore
  const count = useSelector((state) => state.adminReducer.games.count);
  const maxPages = Math.ceil(count / 10);
  let history = useHistory();
  //@ts-ignore
  const { page } = useParams();
  const [dayPreview, setDayPreview] = React.useState("01");
  const [selectedId, setSelectedId] = React.useState(false);
  const [dayValue, setDayValue] = React.useState(new Date());
  const dispatch = useDispatch();
  // @ts-ignore
  const events = useSelector((state) => state.gamesReducer.games.results);
  //need to  compare  day from store and day in state only by day and month
  // because time  is different

  const startHandle = (gameId) => {
    history.push(`/adm/game/${gameId}`);
  };
  const eventDates = events.map((element) => {
    let date = new Date(element.start);
    return { date: date, isPublished: element.is_published };
  });
  const onSaveHandle = () => {
    dispatch(getGamesThunkAdmin(page));
    dispatch(getGamesThunk());

    setSelectedId(false);
  };

  useEffect(() => {
    dispatch(getGamesThunkAdmin(page));
    dispatch(getGamesThunk());
  }, [page]);

  let setDay = (value) => {
    if (value !== null) {
      if (value.getDate() < 10) {
        setDayPreview(`0${value.getDate()}`);
      } else {
        setDayPreview(`${value.getDate()}`);
      }
      setDayValue(value);
    }
  };
  let classNamePaginationCX = cx({
    "flex justify-end": true,
    PaginationContainer: true,
  });
  // @ts-ignore
  let url = (page) => {
    return `/adm/${page}`;
  };
  const onStartHandle = (game) => {
    game.status = 1;
    dispatch(startGameThunk(game.id, game, startHandle));
  };
  const onViewHandle = (game) => {
    startHandle(game.id);
  };
  const onUnpublishHandle = (game) => {
    dispatch(unpublishGameThunk(game));
    dispatch(getGamesThunkAdmin(page));
    dispatch(getGamesThunk());
  };
  const onPublishHandle = (game) => {
    dispatch(publishGameThunk(game));
    dispatch(getGamesThunkAdmin(page));
    dispatch(getGamesThunk());
  };

  const listOfGamesJsx = games.map((game, index) => {
    return (
      <Game
        onPublish={onPublishHandle}
        game={game}
        key={index}
        onStart={onStartHandle}
        onView={onViewHandle}
        onEdit={setSelectedId}
        onUnpublish={onUnpublishHandle}
      />
    );
  });
  return (
    <div className={c.Games}>
      <div className="container no-padding-top">
        <APageName>
          Calendar of games
          <AButton
            onClick={() => {
              setSelectedId(true);
            }}
          >
            <div className={classNames(c.padding10, "flex")}>
              <div className="admin-add-game"></div>
              <div>Create new game</div>
            </div>
          </AButton>
        </APageName>
        <div className={c.row}>
          <div className={c.colLeft}>
            <ACalendar
              enableAll={true}
              disablePrevDates={true}
              onDatesSelect={setDay}
              multiDaySelect={false}
              className="admin"
              eventDates={eventDates}
            />
          </div>
          <div className={c.colRight}>
            <ATable>
              <ATableRow header>
                <ATableCol className={c.date}>Game date</ATableCol>
                <ATableCol className={c.amount}>Prize amount</ATableCol>
                <ATableCol className={c.status}>Status</ATableCol>
                <ATableCol className={c.actions}>Actions</ATableCol>
              </ATableRow>
              {listOfGamesJsx}
            </ATable>
            {maxPages > 1 && (
              <div className={classNamePaginationCX}>
                <APagination
                  max={1}
                  maxPages={maxPages}
                  url={url}
                  pageNumber={page}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        appElement={document.getElementById("root")}
        isOpen={!!selectedId}
        onRequestClose={() => setSelectedId(false)}
      >
        <CreateEditGameModal
          id={selectedId}
          onSave={onSaveHandle}
          closeModal={() => {
            setSelectedId(false);
          }}
        />
      </Modal>
    </div>
  );
};
