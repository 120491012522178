import React, { useContext, useEffect, useState, useRef } from "react";
import classes from "./AInput.module.scss";
import cx from "classnames";
import { AField } from "components-admin/AField/AField";
import { FieldContext } from "../../context/formContext";
import { generateId } from "hooks/global";
import { formatNumber } from "utils/formatters/numberFormatter";
import CurrencyInput from "./currencyInput/CurrencyInput";
import { useOnKeyDownEnter } from "../../hooks/keyDown";

export const AInput = ({
  value,
  onChange,
  validate,
  label,
  type,
  error,
  unkey,
  icon,
  onBlur,
  withFormatter,
  placeholder,
  onKeyDownEnter
}) => {
  const [validateError, setValidateError] = useState("");
  const [serverError, setServerError] = useState("");
  const [touched, setTouched] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const onError = useContext(FieldContext).onError;
  const subscribe = useContext(FieldContext).subscribe;
  const serverErrors = useContext(FieldContext).serverErrors;
  const onNext = useContext(FieldContext).onNext;
  const ref = useRef(null);
  const keyDownEnter = useOnKeyDownEnter;
  useEffect(() => {
    if (serverErrors) {
      if (serverErrors[unkey]) {
        if (serverErrors[unkey].length) {
          setServerError(serverErrors[unkey][0]);
        }
      }
    }
  }, [serverErrors, unkey]);
  const [key, setKey] = useState("");
  useEffect(() => {
    setKey(generateId());
  }, []);
  const numberFormatterHandle = (e) => {};
  const validateHandle = (value) => {
    setValidateError("");
    for (let i = 0; i < validate.length; i++) {
      const func = validate[i];
      let result = func(value);
      if (typeof result === "string") {
        setValidateError(result);
      }
    }
  };
  const onKeyDownEnterHandle = (e) => {
    keyDownEnter(e, () => {
      onNext(e);
      if (onKeyDownEnter) {
        onKeyDownEnter(e);
      }
    });
  };
  const onChangeHandle = (e) => {
    onChange(e.target.value);
    setTouched(true);
    validateHandle(e.target.value);
  };
  useEffect(() => {
    validateHandle(value);
    if (!isSubscribed) {
      setIsSubscribed(true);
      subscribe({
        fieldName: key ? key : "",
        ref: ref,
        onSubmit: () => {
          setTouched(true);
        },
      });
    }
    onError({
      fieldName: key ? key : "",
      error: validateError,
    });
  }, [validateError, value]);
  let className = cx({
    input: true,
  });

  return (
    <div className={classes.inputWrapper}>
      <AField
        error={validateError || error || serverError}
        touched={touched}
        label={label}
        icon={icon}
      >
        {/* <input
          onChange={onChangeHandle}
          // value={value}
          type={type}
          onBlur={onBlur}
        /> */}
        {withFormatter ? (
          // @ts-ignore
          <CurrencyInput
            placeholder="$0.00"
            type="text"
            onChange={onChangeHandle}
            defaultValue={value}
          />
        ) : (
          <input
            ref={ref}
            onChange={onChangeHandle}
            value={value}
            type={type}
            onBlur={onBlur}
            placeholder={placeholder}
            onKeyDown={onKeyDownEnterHandle}
          />
        )}
      </AField>
    </div>
  );
};
AInput.defaultProps = {
  value: "",
  validate: [],
  type: "string",
  label: "",
  error: "",
  unkey: "",
  icon: "",
  withFormatter: false,
  onBlur: () => {},
  placeholder: "",
  onKeyDownEnter: () => {},
};
