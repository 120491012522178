import { CForm } from "components-сommon/CForm/CForm";
import { Button } from "components/button/Button";
import { ImageUploader } from "components/imageUploader/ImageUploader";
import { Input } from "components/input/Input";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signUpThunk } from "store/reducers/user-reducer/thunk";
import { displayToast } from "toast/toast";
import { validateEmail } from "utils/validators/validators";
import classes from "../SignUp.module.scss";

export const FirstStep = ({ setIsOpen, setIsConfirmModalOpen, openLogin }) => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [userRepeatPassword, setUserRepeatPassword] = useState("");
  const [avatarError, setAvatarError] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const ref = urlParams.get("ref");
  const closeModal = () => {
    setIsOpen(false);
    setIsConfirmModalOpen(true, userEmail);
  };

  const dispatch = useDispatch();
  // @ts-ignore
  const isFetching = useSelector((state) => state.userReducer.isFetching);
  // @ts-ignore
  const isError = useSelector((state) => state.userReducer.isError);
  let formData = new FormData();
  formData.append("email", userEmail);
  formData.append("username", userName);
  if (avatar) {
    formData.append("avatar", avatar);
  }
  formData.append("password", userPassword);
  if (ref) {
    formData.append("ref", ref);
  }
  let submitHandle = (e) => {
    dispatch(signUpThunk(formData, closeModal));
  };
  let errorHandle = (e) => {};
  return (
    <div>
      <div className={classes.loginWrapper__pageTitle}>Sign up</div>

      <CForm
        onSubmit={submitHandle}
        onError={errorHandle}
        serverErrors={isError}
      >
        <div className={classes.item}>
          <Input
            unkey="username"
            label={"User name*"}
            value={userName}
            onChange={setUserName}
            validate={[
              (val) => (val && val.length > 0) || "This field is required",
              (val) => val.length < 100 || "This field is too long",
            ]}
          />
          <div className={classes.subtitle}>To be shown as winner’s name</div>
        </div>
        <div className={classes.item}>
          <Input
            unkey="email"
            label={"Email*"}
            value={userEmail}
            onChange={setUserEmail}
            validate={validateEmail}
          />
        </div>
        <div className={classes.item}>
          <div className={classes.title}>Avatar</div>
          <ImageUploader
            setImage={setAvatar}
            image={avatar}
            setIsError={setAvatarError}
          />
          {avatarError && (
            <div className={classes.imageHelperError}>
              image size is too large
            </div>
          )}
        </div>

        <div className={classes.item}>
          <Input
            unkey="password"
            label={"Password*"}
            type="password"
            value={userPassword}
            onChange={setUserPassword}
            validate={[
              (val) => (val && val.length > 0) || "This field is required",
              (val) => val.length < 15 || "This field is too long",
            ]}
          />
        </div>
        <div className={classes.item}>
          <Input
            unkey="userRepeatPassword"
            label={"Repeat password*"}
            type="password"
            value={userRepeatPassword}
            onChange={setUserRepeatPassword}
            validate={[
              (val) => (val && val.length > 0) || "This field is required",
              (val) => (val && val === userPassword) || "Passwords don’t match",
            ]}
          />
        </div>

        <div className={classes.buttonNextWrapper}></div>
        <Button submit={!avatarError && true} width={"100%"}>
          <div className={classes.buttonNext__title}>Sign up</div>
        </Button>
      </CForm>
      <div className={classes.haveAccount}>
        Already have an account?{" "}
        <div
          className={classes.underline}
          onClick={() => {
            setIsOpen(false);
            openLogin();
          }}
        >
          Log in
        </div>
      </div>
    </div>
  );
};
