import { Button } from "components/button/Button";
import React from "react";
import c from "../ForgotPasswordModal/ForgotPassword.module.scss";
export const SuccessModal = ({ setIsOpen }) => {
  return (
    <div className="main-layout">
      <div
        className="iconClose"
        onClick={() => {
          setIsOpen(false);
        }}
      ></div>
      <div className={c.wrapper}>
        <div className={c.title}>Success</div>
        <div className={c.mainText}>
          If the email addrress is valid you should receive an email with a
          reset password link shortly
        </div>
        <div className={c.buttonWrapper}>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    </div>
  );
};
