import React from "react";
import moment from "moment";
import "./ACalendar.scss";

/*
<Calendar
  onDatesSelect={this.onDateSelected} // will pass list of selected dates as param(s) in js date object format
  selectedDates={[this.state.dates]}  // array of js date objects
  multiDaySelect={true}               // default only 1 day is allowed
  eventDates=[date, date2]         // js date
  minYear={1992}                      // default 2015
  maxYear={2025}                      // default now+5 years
/>
*/
export default class CCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCalendarDate: moment(),
      selectedDate: new Date(), // JS date objects
    };
    this.now = moment();
    // this.weekdaysShort = moment.weekdaysShort();
    this.weekdaysShort = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

    this.months = moment.months();
    this.years = this.getYears();
    this.minYear = this.years[0];
    this.maxYear = this.years[this.years.length - 1];

    this.onMonthOrYearChange = this.onMonthOrYearChange.bind(this);
    this.onMonthOrYearSelect = this.onMonthOrYearSelect.bind(this);
    this.onDaySelect = this.onDaySelect.bind(this);
    this.onDone = this.onDone.bind(this);

    if (this.props.value) {
      this.state.selectedDate = this.props.value;
      this.state.currentCalendarDate = moment(this.props.value);
      this.now = moment(this.props.value);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      const state = { selectedDate: new Date() };
      if (this.props.selectedDate) {
        state.selectedDate = this.props.selectedDate;
        state.currentCalendarDate = moment(this.props.selectedDate); // show month of first selected date in calendar
      }
      this.setState(state);
      if (this.props.value) {
        this.onDaySelect(this.props.value.getDate());
      }
    }
    if (this.props.value) {
      this.state.selectedDate = this.props.value;
      this.state.currentCalendarDate = moment(this.props.value);
      this.now = moment(this.props.value);
    }
  }

  getYears() {
    let start = this.props.minYear ? parseInt(this.props.minYear) : 2015;
    const end = this.props.maxYear
      ? parseInt(this.props.maxYear)
      : this.now.year() + 5;
    let result = [];
    while (start <= end) result.push(start++);
    return result;
  }
  canSelect(day, isDayFromPrevMonth, isDayFromNextMonth) {
    var dateFrom = moment(this.state.currentCalendarDate)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");

    let date = null;
    let currentCalendarDate = moment({ ...this.state.currentCalendarDate });
    if (isDayFromPrevMonth) {
      currentCalendarDate.subtract(1, "month");
    }
    if (isDayFromNextMonth) {
      currentCalendarDate.add(1, "month");
    }
    currentCalendarDate.set("date", day);
    date = currentCalendarDate.toDate();

    let current = new Date();
    if (
      date.getDate() === current.getDate() &&
      date.getMonth() === current.getMonth()
    ) {
      return true;
    } else {
      return new Date() <= date;
    }
  }

  onMonthOrYearChange(type, next) {
    let currentCalendarDate = moment({ ...this.state.currentCalendarDate });
    if (next) {
      currentCalendarDate.add(1, type);
    } else {
      currentCalendarDate.subtract(1, type);
    }
    this.setState({ currentCalendarDate });
  }

  onMonthOrYearSelect(e, type) {
    let currentCalendarDate = moment({ ...this.state.currentCalendarDate });
    currentCalendarDate.set(type, e.target.value);
    this.setState({ currentCalendarDate });
  }

  onDaySelect(day, isDayFromPrevMonth, isDayFromNextMonth) {
    var dateFrom = moment(this.state.currentCalendarDate)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");

    let date = null;
    let currentCalendarDate = moment({ ...this.state.currentCalendarDate });
    if (isDayFromPrevMonth) {
      currentCalendarDate.subtract(1, "month");
    }
    if (isDayFromNextMonth) {
      currentCalendarDate.add(1, "month");
    }
    currentCalendarDate.set("date", day);
    date = currentCalendarDate.toDate();

    this.setState({ currentCalendarDate, selectedDate: date });

    if (!this.props.multiDaySelect && this.props.onDatesSelect) {
      this.props.onDatesSelect(date);
    }
  }

  onDone() {
    if (this.props.onDatesSelect) {
      this.props.onDatesSelect(this.state.selectedDate);
    }
  }

  isToday(day) {
    let date = moment({ ...this.state.currentCalendarDate });
    date.set("date", day);
    return this.now.toDate().toDateString() === date.toDate().toDateString();
  }

  isSelected(day, isDayFromPrevMonth, isDayFromNextMonth) {
    let date = moment({ ...this.state.currentCalendarDate });
    if (isDayFromPrevMonth) {
      date.subtract(1, "month");
    }
    if (isDayFromNextMonth) {
      date.add(1, "month");
    }
    date.set("date", day);
    // return false;
    return (
      this.state.selectedDate.toDateString() === date.toDate().toDateString()
    );
  }

  isEvent(day, month) {
    let date = moment({ ...this.state.currentCalendarDate });
    // date.set("date", day);
    let currentCalendarDate = date.toDate();
    currentCalendarDate.setDate(day);

    if (month === "prev") {
      currentCalendarDate.setMonth(currentCalendarDate.getMonth() - 1);
    }
    if (month === "next") {
      currentCalendarDate.setMonth(currentCalendarDate.getMonth() + 1);
    }

    return (
      this.props.eventDates &&
      this.props.eventDates.some((d) => {
        // let currentMonth = currentCalendarDate.getMonth();
        // let currentDate = currentCalendarDate.getDate();
        // let currentYear = currentCalendarDate.getFullYear();
        let month = currentCalendarDate.getMonth(); //months from 1-12
        let day = currentCalendarDate.getDate();
        let year = currentCalendarDate.getFullYear();
        let secondMonth = d.date.getMonth();
        let secondDay = d.date.getDate();
        let secondYear = d.date.getFullYear();

        let value = year + "/" + month + "/" + day;
        let secondValue = secondYear + "/" + secondMonth + "/" + secondDay;

        return value === secondValue;
        // return d.toDateString() === date.toDate().toDateString();
      })
    );
  }

  isPublished(day, month) {
    let date = moment({ ...this.state.currentCalendarDate });
    // date.set("date", day);
    let currentCalendarDate = date.toDate();
    currentCalendarDate.setDate(day);

    if (month === "prev") {
      currentCalendarDate.setMonth(currentCalendarDate.getMonth() - 1);
    }
    if (month === "next") {
      currentCalendarDate.setMonth(currentCalendarDate.getMonth() + 1);
    }

    return (
      this.props.eventDates &&
      this.props.eventDates.some((d) => {
        // let currentMonth = currentCalendarDate.getMonth();
        // let currentDate = currentCalendarDate.getDate();
        // let currentYear = currentCalendarDate.getFullYear();
        let month = currentCalendarDate.getMonth(); //months from 1-12
        let day = currentCalendarDate.getDate();
        let year = currentCalendarDate.getFullYear();
        let secondMonth = d.date.getMonth();
        let secondDay = d.date.getDate();
        let secondYear = d.date.getFullYear();

        let value = year + "/" + month + "/" + day;
        let secondValue = secondYear + "/" + secondMonth + "/" + secondDay;

        if (value === secondValue) {
          return d.isPublished;
        }
        // return d.toDateString() === date.toDate().toDateString();
      })
    );
  }

  render() {
    const { currentCalendarDate } = this.state;

    let daysFromPrevMonth = [];
    let daysFromNextMonth = [];

    // let prevMonth = currentCalendarDate.month(currentCalendarDate.month() - 1);
    let prevMonth = moment(currentCalendarDate)
      .subtract(1, "months")
      .endOf("month");
    for (
      let i = 0;
      // @ts-ignore
      i < moment(currentCalendarDate).startOf("month").format("d");
      i++
    ) {
      let d = prevMonth.daysInMonth() - i;
      const isEvent = this.isEvent(d, "prev");
      const isPublished = this.isPublished(d, "prev");
      daysFromPrevMonth.unshift(
        // <td key={"p-" + i}>
        //   <div className="table-item-wrap">{prevMonth.daysInMonth() - i}</div>
        // </td>
        <td key={"n-" + d}>
          <div
            className={
              (isEvent ? "aevent " : "") +
              "table-item-wrap" +
              (isPublished ? " published" : "") +
              " " +
              (this.isSelected(d, true, false) ? "selected " : "")
            }
            onClick={() => {
              if (isEvent || this.props.enableAll) {
                if (this.props.disablePrevDates) {
                  if (this.canSelect(d, true, false) === true) {
                    this.onDaySelect(d, true, false);
                  }
                } else {
                  this.onDaySelect(d, true, false);
                }
              }
            }}
          >
            {d}
          </div>
        </td>
      );
    }
    let a =
      35 -
      // @ts-ignore
      moment(currentCalendarDate).startOf("month").format("d") -
      currentCalendarDate.daysInMonth();

    if (a < 0) {
      a += 7;
    }

    for (
      let i = 0;
      // @ts-ignore
      i < a;
      i++
    ) {
      let d = i + 1;
      const isEvent = this.isEvent(d, "next");
      const isPublished = this.isPublished(d, "next");
      daysFromNextMonth.push(
        <td key={"n-" + d}>
          <div
            className={
              (isEvent ? "aevent " : "") +
              "table-item-wrap" +
              (isPublished ? " published" : "") +
              " " +
              (this.isSelected(d, false, true) ? "selected " : "")
            }
            onClick={() => {
              if (isEvent || this.props.enableAll) {
                if (this.props.disablePrevDates) {
                  if (this.canSelect(d, false, true) === true) {
                    this.onDaySelect(d, false, true);
                  }
                } else {
                  this.onDaySelect(d, false, true);
                }
              }
            }}
          >
            {d}
          </div>
        </td>
      );
    }
    let daysInMonth = [];
    for (let d = 1; d <= currentCalendarDate.daysInMonth(); d++) {
      const isEvent = this.isEvent(d);
      const isPublished = this.isPublished(d);
      daysInMonth.push(
        <td key={"m-" + d}>
          <div
            className={
              (isEvent ? "aevent " : "") +
              "table-item-wrap" +
              (isPublished ? " published" : "") +
              " " +
              (this.isSelected(d) ? "selected " : "")
            }
            onClick={() => {
              if (isEvent || this.props.enableAll) {
                if (this.props.disablePrevDates) {
                  if (this.canSelect(d) === true) {
                    this.onDaySelect(d);
                  }
                } else {
                  this.onDaySelect(d);
                }
              }
            }}
          >
            {d}
          </div>
        </td>
      );
    }

    var allSlots = [...daysFromPrevMonth, ...daysInMonth, ...daysFromNextMonth];
    let weeks = [];
    let days = [];
    allSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        days.push(row);
      } else {
        weeks.push(days);
        days = [];
        days.push(row);
      }
      if (i === allSlots.length - 1) {
        weeks.push(days);
      }
    });
    return (
      <div className={`calendar ${this.props.className}`}>
        <div className="desktop-hidden">{this.props.children}</div>
        <div className="header">
          <div className="month">
            <div>
              {this.months[currentCalendarDate.month()]}{" "}
              <span className="hide-admin">games</span>{" "}
            </div>
            <div className="calendar-buttons">
              <div
                className="prev"
                onClick={() => this.onMonthOrYearChange("month", false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  stroke="white"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div
                className="next"
                onClick={() => this.onMonthOrYearChange("month", true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  stroke="white"
                >
                  <path
                    d="M0.999999 1L7 7L1 13"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <table>
          <thead>
            <tr>
              {this.weekdaysShort.map((wd, i) => (
                <th key={i}>{wd}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeks.map((d, i) => {
              return <tr key={i}>{d}</tr>;
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
