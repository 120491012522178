import { instance } from "api/axios-instance";

export const winnersAPI = {
  winners: {
    getWinners: (page) => {
      return instance.get("/games/winners/");
    },
    pay: (id) => {
      return instance.post(`/games/winners/${id}/pay/`);
    },
    
  },
};
