import { Button } from "components/button/Button";
import { Circle } from "components/circle/Circle";
import { Dots } from "components/dots/Dots";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import {
  setNumbers,
  setWinNumbersAC,
} from "store/reducers/furtune-reducer/actions";
import {
  getGameThunk,
  getTicketThunk,
} from "store/reducers/furtune-reducer/thunk";
import { dateFormatterGame } from "utils/formatters/dateFormatter";
import { BuyTicket } from "./buyTicket/BuyTicket";
import c from "./Furtune.module.scss";
import cn from "classnames";
import { IFrame } from "./Iframe/IFrame";
import { GameNotStarted } from "./gameNotStarted/GameNotStarted";
import { WinningNumbers } from "./winningNumbers/WinningNumbers";
import { formatNumber } from "utils/formatters/numberFormatter";
import { FinishedCard } from "./finished/finishedCard/FinishedCard";
import { AutoGame } from "./AutoGame/AutoGame";
import { getProfileThunk } from "store/reducers/user-reducer/thunk";
export const Furtune = (props) => {
  // @ts-ignore
  const ticketNumbers = useSelector((state) => state.furtuneReducer.tickets);
  const page = useParams();
  const {
    start,
    amount,
    is_finished,
    status,
    embed_code,
    time_from_start,
    win_numbers,
    // @ts-ignore
  } = useSelector((state) => state.furtuneReducer.game);
  // @ts-ignore
  const isGame = useSelector((state) => state.furtuneReducer.game);
  const [values, setValues] = useState(["-", "-", "-", "-", "-", "-"]);
  const [secondsFromStart, setSecondsFromStart] = useState(-1);
  const [initialValues, setInitialValues] = useState([]);
  // @ts-ignore
  const freeTickets = useSelector(
    // @ts-ignore
    (state) => state.userReducer.user.free_tickets
  );
  const setInitialValuesOnSocket = (values) => {};
  let valuesForWinningNumbersAutoGame = [];
  // @ts-ignore
  values.map((e) => {
    if (e !== "-" && e !== undefined) {
      valuesForWinningNumbersAutoGame.push(e);
    }
  });

  const dispatch = useDispatch();
  let buyTicket = async (isFree = false) => {
    // @ts-ignore
    await dispatch(await getTicketThunk(page.gameId, ticketNumbers, isFree));
    // @ts-ignore

    dispatch(await getGameThunk(page.gameId));
    dispatch(await getProfileThunk())
  };
  useEffect(() => {
    return () => {
      dispatch(setNumbers([false, false, false, false, false]));
    };
  }, []);

  useEffect(() => {
    if (status === 2) {
      setValues(win_numbers);
    }
    if (status === 1) {
      if (time_from_start > 60) {
        setValues(win_numbers);
      }
      if (time_from_start < 60) {
        setInitialValues(win_numbers);
      }
      setSecondsFromStart(time_from_start);
    }
  }, [status]);

  useEffect(() => {
    // @ts-ignore
    dispatch(getGameThunk(page.gameId));
  }, []);

  useEffect(() => {
    if (time_from_start !== undefined) {
      if (status !== 2) {
        setSecondsFromStart(time_from_start);
      }

      if (time_from_start === null) {
        setSecondsFromStart(2000);
      }
    }
  }, [time_from_start]);
  useEffect(() => {
    //@ts-ignore
    dispatch(getGameThunk(page.gameId));

    const chatSocket = new WebSocket(
      "wss://" +
        "app.bitcoinbingo.io" +
        "/ws/game/" +
        // @ts-ignore
        page.gameId +
        "/" +
        "?token=" +
        localStorage.getItem("access")
    );

    chatSocket.onmessage = function (e) {
      let response = JSON.parse(e.data);
      if (response.status === 1) {
        // @ts-ignore
        dispatch(getGameThunk(page.gameId));
      }

      if (response.status === 2) {
        // @ts-ignore
        dispatch(getGameThunk(page.gameId));
        setInitialValues(response.win_numbers);

        setSecondsFromStart(0);
      } else {
        dispatch(setWinNumbersAC(response.win_numbers));
        setInitialValues(response.win_numbers);
        setSecondsFromStart(0);
      }
    };
    chatSocket.onclose = function (e) {};
  }, []);

  return (
    <div>
      {isGame && (
        <div>
          <div className="container no-pl-pr-tablet">
            <div className={c.furtune}>
              <div className={c.furtune__ticketPicker}>
                <div className="main-layout">
                  <div className={cn(c.head, status > 0 ? c.live : "")}>
                    <NavLink to="/">
                      <div className={c.buttonBack}>
                        <div className="icon icon-arrow-back"></div>
                      </div>
                    </NavLink>
                    <div className={c.title}>
                      <div className={cn(c.statusTitleWrapper , "desktop-hidden --flex tablet-hidden" , c.statusTitleWrapper)}>
                      <div
                      className={cn(
                        c.status,
                        status === 1 && c.red,
                        status === 2 && c.black,
                      )}
                    >
                      {status === 0 && "Not Started"}
                      {status === 1 && "Live"}
                      {status === 2 && "Finished "}
                    </div>
                      </div>

                      <div className={c.title__sub}>

                        {start && dateFormatterGame(start)}
                      </div>
                      <div className={c.title__main}>
                        {amount && "$" + formatNumber(amount)}
                      </div>
                    </div>
                    <div
                      className={cn(
                        c.status,
                        status === 1 && c.red,
                        status === 2 && c.black,
                        "mobile-hidden"
                      )}
                    >
                      {status === 0 && "Not Started"}
                      {status === 1 && "Live"}
                      {status === 2 && "Finished "}
                    </div>
                  </div>
                  {status === 0 && <BuyTicket freeTicketsCount={freeTickets} />}
                  {status !== 0 && embed_code !== "" && (
                    <IFrame code={embed_code} />
                  )}
                  {embed_code === "" &&
                    (status === 1 || status === 2) &&
                    win_numbers && (
                      <AutoGame
                        secondsFromStart={secondsFromStart}
                        win_numbers={win_numbers}
                        values={values}
                        setValues={setValues}
                        initialValues={initialValues}
                        setInitialValues={setInitialValues}
                      />
                    )}
                </div>
              </div>

              <div
                className={cn(
                  c.furtune__ticket,
                  status === 0 && c.ticketNotSelected
                )}
              >
                <div className="second-layout">
                  {status === 0 && (
                    <GameNotStarted
                      ticketNumbers={ticketNumbers}
                      buyTicket={buyTicket}
                      setIsLoginOpen={props.setIsLoginOpen}
                      freeTickets={freeTickets}
                    />
                  )}
                  {embed_code === "" ? (
                    <WinningNumbers
                      status={status}
                      winNumbers={valuesForWinningNumbersAutoGame}
                    />
                  ) : (
                    <WinningNumbers status={status} winNumbers={win_numbers} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
