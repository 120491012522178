import React, { useEffect, useState } from "react";
import c from "../../pages-admin/GameLive/AGameLive.module.scss";
import cn from "classnames";
import { Button } from "components/button/Button";
import { PickNumberAuto } from "../Furtune/AutoGame/PickNumberAuto/PickNumberAuto";
export const AutoGamePage = () => {
  const [values, setValues] = useState(["-", "-", "-", "-", "-", "-"]);
  const [isStarted, setIsStarted] = useState(false);
  const [initialValues, setInitialValues] = useState([]);
  const [secondsFromStart, setSecondsFromStart] = useState(-1);
  let [showGifs, setShowGifs] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  // for test imitation axios
  useEffect(() => {
    setTimeout(() => {
      setInitialValues([2, 8, 15, 23, 30, 1]);
      setSecondsFromStart(0);
    }, 100);
  }, []);
  // end for test

  // for test imitation socket
  // click bottom button
  const socketHandle = () => {
    setInitialValues([2, 8, 15, 23, 30, 1]);
    setSecondsFromStart(0);
  };
  // end for test

  useEffect(() => {
    if (secondsFromStart >= 0 && !isStarted) {
      setIsStarted(true);
      startAnimate();
    }
  }, [secondsFromStart]);
  const startAnimate = () => {
    let initialNumberIndex = Math.floor(secondsFromStart / 10);
    let newValues = [...values];
    let newShowGifts = [...showGifs];
    for (let index = 0; index < initialNumberIndex; index++) {
      // @ts-ignore
      newValues[index] = initialValues[index];
    }
    setValues(newValues);
    if (initialNumberIndex < newShowGifts.length) {
      newShowGifts[initialNumberIndex - 1] = true;
    }
    if (initialNumberIndex < 6) {
      nextGif(newShowGifts, newValues);
    }
  };

  const nextGif = (arr, values) => {
    let newArray = [...arr];
    let index = newArray.indexOf(true);
    if (index > -1) {
      newArray[index] = false;
      showGifs[index] = false;
      if (index + 1 < newArray.length) {
        newArray[index + 1] = true;
        showGifs[index + 1] = true;
      }
    } else {
      newArray[0] = true;
      showGifs[0] = true;
    }
    let newValues = [...values];
    let indexVal = newValues.indexOf("-");
    setShowGifs(newArray);
    if (indexVal > -1 && indexVal < 6) {
      // @ts-ignore
      setTimeout(() => {
        newValues[indexVal] = initialValues[indexVal];
        setValues(newValues);
        nextGif(newArray, newValues);
      }, 10000);
    }
  };

  return (
    <div className={"main-layout"}>
      <div className={c.buyTicketWrapper}>
        <div className={c.buyTicketWrapper__numberPicker}>
          <div className={c.pickerHeader}>
            <div className={c.left}>
              <div className={c.title}> Live broadcast </div>
              <div className={c.subtitle}>{20} tickets used</div>
            </div>
          </div>

          <div className={c.pickers}>
            <PickNumberAuto
              min={1}
              max={7}
              index={0}
              disablePick={true}
              showGif={showGifs[0]}
              value={values[0]}
              withGif={true}
            />
            <div className={c.circles}>
              <div className={c.circlesIcon}></div>
            </div>
            <PickNumberAuto
              min={8}
              max={14}
              index={1}
              disablePick={true}
              showGif={showGifs[1]}
              value={values[1]}
              withGif={true}
            />
            <div className={cn(c.circles, "mobile-hidden")}>
              <div className={c.circlesIcon}></div>
            </div>
            <PickNumberAuto
              min={15}
              max={21}
              index={2}
              disablePick={true}
              value={values[2]}
              showGif={showGifs[2]}
              withGif={true}
            />
            <div className={c.circles}>
              <div className={c.circlesIcon}></div>
            </div>
            <PickNumberAuto
              min={22}
              max={28}
              index={3}
              disablePick={true}
              showGif={showGifs[3]}
              value={values[3]}
              withGif={true}
            />
            <div className={c.circles}>
              <div className={cn(c.circlesIcon, "mobile-hidden")}></div>
            </div>
            <PickNumberAuto
              min={29}
              max={35}
              index={4}
              value={values[4]}
              disablePick={true}
              showGif={showGifs[4]}
              withGif={true}
            />
          </div>

          <div className={c.pickers}>
            <PickNumberAuto
              min={1}
              max={7}
              index={5}
              withCircle={false}
              disablePick={true}
              showGif={showGifs[5]}
              withGif={true}
              value={values[5]}
            />
            <div className={c.circles}>
              <div className={cn(c.circlesIcon, c.none, "no-background")}></div>
            </div>
            <PickNumberAuto
              min={8}
              max={14}
              index={5}
              withCircle={false}
              disablePick={true}
              showGif={showGifs[5]}
              withGif={true}
              value={values[5]}
            />
            <div className={cn(c.circles, "mobile-hidden")}>
              <div className={cn(c.circlesIcon, c.none, "no-background")}></div>
            </div>
            <PickNumberAuto
              min={15}
              max={21}
              index={5}
              disablePick={true}
              border={"2px solid #FF3D3D"}
              showGif={showGifs[5]}
              withGif={true}
              value={values[5]}
            />
            <div className={c.circles}>
              <div className={cn(c.circlesIcon, c.none, "no-background")}></div>
            </div>
            <PickNumberAuto
              min={22}
              max={28}
              index={5}
              withCircle={false}
              disablePick={true}
              showGif={showGifs[5]}
              withGif={true}
              value={values[5]}
            />
            <div className={c.circles}>
              <div className={cn(c.circlesIcon, c.none, "no-background")}></div>
            </div>
            <PickNumberAuto
              min={29}
              max={35}
              index={5}
              withCircle={false}
              disablePick={true}
              showGif={showGifs[5]}
              withGif={true}
              value={values[5]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
