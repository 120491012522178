import React, { useEffect } from "react";
import c from "./AdminLayout.module.scss";
import { Games } from "pages-admin/Games/Games";
import { Settings } from "pages-admin/Settings/Settings";
import { Winners } from "pages-admin/Winners/Winners";
import { Statistics } from "pages-admin/Statistics/Statistics";
import { Route, Switch } from "react-router-dom";
import Header from "./components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { getProfileThunk } from "store/reducers/user-reducer/thunk";
import { AGameLive } from "pages-admin/GameLive/AGameLive";
import { Users } from "pages-admin/Users/Users";
export const AdminLayout = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const user = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    let token = localStorage.getItem("access");
    if (token) {
      dispatch(getProfileThunk());
    }
    document.body.classList.remove("background");
    return () => {
      document.body.classList.add("background");
    };
  }, []);
  return (
    <div>
      {user && user.role == 0 ? (
        <div className={c.AdminLayout}>
          <Header />
          <Switch>
            <Route exact path="/adm/settings" render={() => <Settings />} />
            <Route exact path="/adm/statistics" render={() => <Statistics />} />
            <Route exact path="/adm/winners/:page" render={() => <Winners />} />
            <Route exact path="/adm/users/:page" render={() => <Users />} />
            <Route exact path="/adm/:page" render={() => <Games />} />

            <Route
              exact
              path="/adm/game/:gameId"
              render={() => <AGameLive />}
            />
          </Switch>
        </div>
      ) : (
        <div></div>
      )}
      <div className={c.inviteLink}>
        Use this link to invite users: https://app.bitcoinbingo.io/dyhfds1op
      </div>
    </div>
  );
};
