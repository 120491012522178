import { Circle } from "components/circle/Circle";
// @ts-ignore
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pickNumberAC } from "store/reducers/furtune-reducer/actions";
import c from "./PickNumber.module.scss";
import cx from "classnames";
import { setTicketAdminAC } from "store/reducers/admin-reducer/actions";
import { setTicketsInGame } from "store/reducers/admin-reducer/thunk";
import { getGameThunk } from "store/reducers/furtune-reducer/thunk";
export const PickNumber = ({
  min,
  max,
  index,
  withAdmin,
  withCircle,
  gameId,
  border,
  disabled,
  width,
}) => {
  let pickedNumber = useSelector((state) =>
    // @ts-ignore
    state.furtuneReducer.tickets[index]
      // @ts-ignore
      ? state.furtuneReducer.tickets[index]
      : false
  );
  let allNumbers = useSelector(
    // @ts-ignore
    (state) => state.furtuneReducer.game.win_numbers
  );

  let pickedNumberAdmin = useSelector((state) =>
    // @ts-ignore
    state.adminReducer.ticketNumbers[index]
      // @ts-ignore
      ? state.adminReducer.ticketNumbers[index]
      : false
  );
  const checkIsAdmin = () => {
    if (withAdmin) {
      return pickedNumberAdmin;
    } else {
      return pickedNumber;
    }
  };
  const pickNumberHandle = (element) => {
    if (withAdmin) {
      if (index === 0 || allNumbers[index - 1]) {
        dispatch(setTicketAdminAC(index, element));
        dispatch(setTicketsInGame());
        dispatch(getGameThunk(gameId, true));
      }
    } else {
      dispatch(pickNumberAC(index, element));
    }
  };

  const dispatch = useDispatch();
  let numbers = [];
  for (let i = min; i <= max; i++) {
    numbers.push(i);
  }

  return (
    <div className={c.pickNumberWrapper}>
      <Circle border={withCircle && border} respond={true}
        width={`${width}px`}
        height={`${width}px`}
      >
        {withCircle && (
          <div className={cx(c.pickedNumber , "center-ios")}>
            {checkIsAdmin() ? (
              checkIsAdmin()
            ) : (
              <div className={c.numberNotSelected}>-</div>
            )}
          </div>
        )}
      </Circle>

      <div className={c.numbersContainer}>
        <div className={c.numbers}>
          {numbers.map((element) => {
            return (
              <div
                key={`pick-number-elements-${element}`}
                className={cx(c.number)}
                onClick={() => {
                  if (!disabled) {
                    pickNumberHandle(element);
                  }
                }}
              >
                <Circle
                  width={"36px"}
                  height={"36px"}
                  smallCircle={true}
                  respond={true}
                  active={element === checkIsAdmin() ? "#FFC107" : "#6490A0"}
                >
                  {element}
                </Circle>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

PickNumber.defaultProps = {
  value: "-",
  withAdmin: false,
  withCircle: true,
  gameId: false,
  border: "2px solid #9AE1F1",
  width: 104,
  disabled: false,
};
