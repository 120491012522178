import {
  SET_GAMES,
  SET_HISTORY_OF_GAMES,
  SET_NEXT_GAME,
  SET_STATISTICS,
  SET_UPCOMING_GAMES,
  SET_HOME_UPCOMING_GAMES,
} from "./actions";

let initialState = {
  games: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  selectedDay: false,
  statistics: false,
  nextGame: false,
  upcomingGames: false,
  homeUpcomingGames: false,
  historyOfGames: false,
};

export const gamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GAMES:
      return {
        ...state,
        games: action.payload,
      };
    case SET_STATISTICS:
      return {
        ...state,
        statistics: action.payload,
      };
    case SET_NEXT_GAME:
      return {
        ...state,
        nextGame: action.payload,
      };
    case SET_UPCOMING_GAMES:
      return {
        ...state,
        upcomingGames: action.payload,
      };
    case SET_HOME_UPCOMING_GAMES:
      return {
        ...state,
        homeUpcomingGames: action.payload,
      };
    case SET_HISTORY_OF_GAMES:
      return {
        ...state,
        historyOfGames: action.payload,
      };

    default:
      return state;
  }
};
