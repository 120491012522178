import React from "react";

export const FormContext = React.createContext({
  onSubmit: (e, submitParam) => {},
  onReject: (e) => {},
  subscribe: (subscribeData) => {},
});

export const FieldContext = React.createContext({
  onError: (error) => {},
  onNext: (event) => {},
  subscribe: (subscribeData) => {},
  serverErrors: [],
  isSubmited: false
});

export default FormContext;
