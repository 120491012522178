export const SET_GAMES = "SET_GAMES";
export const SET_STATISTICS = "SET_STATISTICS";
export const SET_NEXT_GAME = "SET_NEXT_GAME";
export const SET_UPCOMING_GAMES = "SET_UPCOMING_GAMES";
export const SET_HOME_UPCOMING_GAMES = "SET_HOME_UPCOMING_GAMES";
export const SET_HISTORY_OF_GAMES = "SET_HISTORY_OF_GAMES";
export const setGamesAC = (payload) => {
  return { type: SET_GAMES, payload };
};
export const setStatistics = (payload) => {
  return { type: SET_STATISTICS, payload };
};
export const setNextGameAC = (payload) => {
  return { type: SET_NEXT_GAME, payload };
};
export const setUpcomingGames = (payload) => {
  return { type: SET_UPCOMING_GAMES, payload };
};
export const setHomeUpcomingGames = (payload) => {
  return { type: SET_HOME_UPCOMING_GAMES, payload };
};
export const setHistoryOfGamesAC = (payload) => {
  return { type: SET_HISTORY_OF_GAMES, payload };
};
