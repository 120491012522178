import React from 'react'
import c from "./APageName.module.scss";

export const APageName = ({children}) => {
  return (
    <div className={c.APageName}>
      {children}
    </div>
  )
}
