import React from "react";
import c from "./IFrame.module.scss";
import ReactHtmlParser from "react-html-parser";

export const IFrame = (props) => {
  return (
    <div className={props.admin ? c.admin : ""}>
      <div className={c.iframeWrapper}>
        <div className={c.videoWrapper}>
          {/* <iframe src={props.code} width="100%" height="100%"></iframe> */}

          {props.code && props.code.length > 100 ? (
            ReactHtmlParser(props.code)
          ) : (
            <iframe src={props.code} width="100%" height="100%"></iframe>
          )}
        </div>
      </div>
    </div>
  );
};
IFrame.defaultProps = {
  admin: false,
};
