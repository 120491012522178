import React, { useState, useEffect } from "react";
import { configAPI } from "api/admin/configAPI";
import c from "./Settings.module.scss";
import { APageName } from "components-admin/APageName/APageName";
import { CForm } from "components-сommon/CForm/CForm";
import { AInput } from "components-admin/AInput/AInput";
import { AButton } from "components-admin/AButton/AButton";
import CSelect from "components-сommon/CSelect/CSelect";
import { displayToast } from "toast/toast";

var classNames = require("classnames/bind");
var cx = classNames.bind(c);
export const Settings = () => {
  const [maxBonusTickets, setMaxBonusTickets] = useState('')
  const [time, setTime] = useState('');
  const submitHandle = () => {
    configAPI.config.update({
      free_tickets_num: Number(maxBonusTickets),
      stop_selling: Number(time),
    }).then((response) => {
      setMaxBonusTickets(response.data.free_tickets_num.toString());
      setTime(response.data.stop_selling);
      displayToast("Saved", "top-right");
    });
  }
  const rejectHandle = (e, action) => {
    console.log('rejectHandle');
  }
  const errorHandle = (e, action) => {
    console.log('errorHandle');
  }
  useEffect(() => {
    configAPI.config.get().then((response) => {
      setMaxBonusTickets(response.data.free_tickets_num.toString());
      setTime(response.data.stop_selling);
    });
  }, []);
  const isError = () => {};
  const timeOptions = [
    {
      label: '30 min',
      value: 30
    },
    {
      label: '1 h',
      value: 60
    },
    {
      label: '2 h',
      value: 120
    },
    {
      label: '4 h',
      value: 240
    },
    {
      label: '8 h',
      value: 480
    },
    {
      label: '12 h',
      value: 7120
    },
    {
      label: '24 h',
      value: 1440
    },
  ]
  return (
    <div className={c.Settings}>
      <div className="container no-padding-top">
        <APageName>
          Settings
        </APageName>
        <CForm
          onSubmit={submitHandle}
          onReject={rejectHandle}
          onError={errorHandle}
          serverErrors={isError}
        >
          <div className={c.row}>
            <AInput
              type="number"
              unkey="maxBonusTickets"
              label="Max bonus tickets"
              value={maxBonusTickets}
              onChange={setMaxBonusTickets}
              validate={[
                (val) => (val && val.length > 0) || "This field is required",
                (val) => val.length < 100 || "This field is too long",
              ]}
            />
          </div>
          <div className={c.row}>
            <CSelect
              label="Time when tickets can no longer be bought"
              options={timeOptions}
              value={time}
              onChange={setTime}
            />
          </div>
          <div className={c.buttonWrapper}>
            <AButton
              fill
              submit
              bigger
            >
              <div>Save</div>
            </AButton>
          </div>
        </CForm>
      </div>
    </div>
  );
};
