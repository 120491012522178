import { AButton } from "components-admin/AButton/AButton";
import { ATableCol } from "components-admin/ATableCol/ATableCol";
import { ATableRow } from "components-admin/ATableRow/ATableRow";
import c from "./Game.module.scss";
import React from "react";
import { dateFormatterGame } from "utils/formatters/dateFormatter";
import { formatNumber } from "utils/formatters/numberFormatter";
import cn from "classnames";
export const Game = ({
  game,
  onStart,
  onUnpublish,
  onEdit,
  onPublish,
  onView,
}) => {
  const buttonPlayIconJsx = (
    <svg
      width="7"
      height="8"
      viewBox="0 0 7 8"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
    >
      <path d="M7 4L0.25 7.89711L0.25 0.102885L7 4Z" />
    </svg>
  );
  const buttonEditIconJsx = (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="#16A49E"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 18H15C15.2652 18 15.5196 18.1054 15.7071 18.2929C15.8946 18.4804 16 18.7348 16 19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19C0 18.7348 0.105357 18.4804 0.292893 18.2929C0.48043 18.1054 0.734784 18 1 18ZM0 13L10 3L13 6L3 16H0V13ZM11 2L13 0L16 3L13.999 5.001L11 2Z"
      />
    </svg>
  );
  const onEditHandle = () => {
    onEdit(game.id);
  };
  return (
    <ATableRow>
      <ATableCol className={cn(c.date, !game.is_published && c.unpublished)}>
        {game.start && dateFormatterGame(game.start)}
      </ATableCol>
      <ATableCol className={cn(c.amount, !game.is_published && c.unpublished)}>
        {game.amount && "$" + formatNumber(game.amount)}
      </ATableCol>
      <ATableCol className={cn(c.status, !game.is_published && c.unpublished)}>
        {game.is_published
          ? (game.status === 1 &&
              `Live ${
                game.embed_code === "" || game.embed_code === null
                  ? "(auto)"
                  : ""
              }`) ||
            (game.status === 0 &&
              `Not Started ${game.embed_code === "" ? "(auto)" : ""}`) ||
            (game.status === 2 &&
              `Finished ${game.embed_code === "" ? "(auto)" : ""}`)
          : `Draft ${game.embed_code === "" ? "(auto)" : ""}`}
      </ATableCol>
      <ATableCol className={c.actions}>
        {game.is_published ? (
          <AButton
            onClick={() => {
              if (game.status === 0) {
                onStart(game);
              } else {
                onView(game);
              }
            }}
            icon={(game.status === 0 && buttonPlayIconJsx) || ""}
            className={c.start}
          >
            {(game.status === 0 && "Start") || "View"}
          </AButton>
        ) : (
          <AButton
            onClick={() => {
              onPublish(game);
            }}
            className={c.unpublish}
            round
            fill={false}
          >
            Publish
          </AButton>
        )}
        {game.is_published && game.status === 0 && (
          <AButton
            onClick={() => {
              onUnpublish(game);
            }}
            className={c.unpublish}
            round
            fill={false}
          >
            Unpublish
          </AButton>
        )}
        {game.status === 0 && (
          <AButton
            onClick={onEditHandle}
            icon={buttonEditIconJsx}
            fill={false}
          ></AButton>
        )}
      </ATableCol>
    </ATableRow>
  );
};
