import { APageName } from "components-admin/APageName/APageName";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatisticsThunk } from "store/reducers/games-reducer/thunk";
import { formatNumber } from "utils/formatters/numberFormatter";
import c from "./Statistics.module.scss";
export const Statistics = () => {
  const dispatch = useDispatch();
  const statistics = useSelector((state) => state.gamesReducer.statistics);
  useEffect(() => {
    dispatch(getStatisticsThunk());
  }, []);

  return (
    <div className={c.Statistics}>
      <div className="container no-padding-top">
        <APageName>Statistics</APageName>
        <div className={c.items}>
          <div className={c.item}>
            <div className={c.title}>Active users</div>
            <div className={c.value}>{statistics.active_users}</div>
          </div>
          <div className={c.item}>
            <div className={c.title}>Games in the past</div>
            <div className={c.value}>{statistics.games_played}</div>
          </div>
          <div className={c.item}>
            <div className={c.title}>Total amount of winnings</div>
            <div className={c.value}>
              ${formatNumber(statistics.amount_of_winnings)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
