import React from "react";
import { Ticket } from "./ticket/Ticket";

export const Tickets = ({ tickets, size }) => {
  let profileNextGameTickets = tickets.map((t, i) => {
    return (
      <Ticket
        numbers={t.numbers}
        ticket={t}
        key={`tickets-next-game-profile-${i}`}
        index={i + 1}
        size={size}
      />
    );
  });
  return <div>{profileNextGameTickets}</div>;
};
Tickets.defaultProps = {
  size: "64px",
};
