import { CRowContaxt } from 'components-admin/contaxt/CTableContaxt';
import React, { useEffect, useState } from 'react'
import c from "./ATableRow.module.scss";

export const ATableRow = ({header, children}) => {
  return (
    <CRowContaxt.Provider value={{ header}}>
    <div className={c.ATableRow}>
      {children}
    </div>
    </CRowContaxt.Provider>
  )
}
ATableRow.defaultProps = {
  header: false,
};