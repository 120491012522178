import React from "react";
import c from "./Dots.module.scss";
export const Dots = ({ count }) => {
  let dotsJSX = [];
  for (let i = 0; i < count; i++) {
    dotsJSX.push(<div className={c.dot} key={`Dots-${i}`}></div>);
  }

  return <div className={c.dots}>{dotsJSX}</div>;
};
