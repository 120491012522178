import { CRowContaxt } from 'components-admin/contaxt/CTableContaxt';
import React, { useContext } from 'react'
import c from "./ATableCol.module.scss";
var classNames = require('classnames/bind');
var cx = classNames.bind(c);

export const ATableCol = ({children, className}) => {
  const header = useContext(CRowContaxt).header;
  let classNameCX = cx({
    'ATableCol': true,
    'header': header,
    [className]: true
  });
  return (
    <div className={classNameCX}>
      {children}
    </div>
  )
}
ATableCol.defaultProps = {
  className: ''
};
