import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { furtuneReducer } from "./reducers/furtune-reducer/index";
import { userReducer } from "./reducers/user-reducer/index";
import { gamesReducer } from "./reducers/games-reducer/index";
import { adminReducer } from "./reducers/admin-reducer/index";
let rootReducer = combineReducers({
  furtuneReducer,
  userReducer,
  gamesReducer,
  adminReducer,
});

const composeEnhancers =
  (typeof window !== "undefined" &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);
// @ts-ignore
window.store = store;

export default store;
