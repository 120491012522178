import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGamesThunk,
  getNextGameThunk,
  getStatisticsThunk,
} from "store/reducers/games-reducer/thunk";
import { DatePicker } from "./datePciker/DatePicker";
import { GameReminder } from "./gameReminder/GameReminder";
import { GameWinners } from "./gameWinners/GameWinners";
import classes from "./Main.module.scss";
import { Statistic } from "./statistic/Statistic";
import cn from "classnames";
import  { detect }  from 'detect-browser';
const browser = detect();

const Main = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGamesThunk());
    dispatch(getStatisticsThunk());
    dispatch(getNextGameThunk());
  }, []);
  const {
    active_users,
    amount_of_winnings,
    games_played,
    last_game_winners,
    // @ts-ignore
  } = useSelector((state) => state.gamesReducer.statistics);
  // @ts-ignore
  const nextGame = useSelector((state) => state.gamesReducer.nextGame);
  useEffect(() => {}, [nextGame]);
  return (
    <div className="container no-pl-pr-tablet">
      <div className={classes.mainWrapper}>
       
      {
          nextGame&& <div className={cn("second-layout desktop-hidden", classes.second)}>
     
           <GameReminder />
        </div>


          }
        
        <div className={cn(classes.datePicker, "main-layout" ,"ios-margin" )}>
          {nextGame && <DatePicker nextGame={nextGame.start} />}
          {!nextGame && <DatePicker nextGame={new Date()} />}
        </div>
        <div
          className={cn(
            classes.gamesInfo,
            "row-grid-lg-1 row-grid-md-1 row-grid-sm-1 "
          )}
        >
         
          <div
            className={
              "row-grid-lg-1 row-grid-md-1 row-grid-sm-1 second-layout mobile-hidden tablet-hidden  withBorder"
            }
          >
      
                       {
             <GameReminder />
           } 
          </div>
          <div className={cn(classes.dataWrapper )}>
            <div className="main-layout ios-margin">
              <Statistic
                activeUsers={active_users}
                gamesPlayed={games_played}
                winningsAmount={amount_of_winnings}
              />
            </div>
            <div className="main-layout ios-margin">
              <GameWinners
                title={"Last game winners"}
                items={last_game_winners ? last_game_winners : []}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Main;
