export let dateFormatter = (value) => {
  let date = new Date(value);
  return date.toUTCString();
};
export let dateFormatterProfile = (value) => {
  let date = new Date(value);
  let day = dayOfWeekAsInteger(date.getDay());
  let monthString = date.toLocaleString("en", { month: "short" });
  let month = date.getDate();
  // date.toLocaleString("default", { month: "long" });
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // @ts-ignore
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  let result = `${monthString},  ${month} at  ${strTime} EST`;
  return result;
};
export let dateFormatterWinners = (value) => {
  let date = new Date(value);
  let day = dayOfWeekAsInteger(date.getDay());
  let monthString = date.toLocaleString("en", { month: "short" });
  let month = date.getDate();
  // date.toLocaleString("default", { month: "long" });
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var year = date.getFullYear();
  var ampm = hours >= 12 ? "AM" : "pm";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // @ts-ignore
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  let result = `${monthString}  ${month} ,   ${year}`;
  return result;
};
export let dateFormatterGame = (value, pst = true, ampm = false) => {

  let date = new Date(value);
  if(typeof value === 'string') {
    const dayIOS = value.split('-')[2].split('T')[0];
    const hoursIOS = value.split('-')[2].split("T")[1].split(":")[0];
    const minutesIOS = value.split('-')[2].split("T")[1].split(":")[1];
   
    date.setHours(hoursIOS)
    date.setDate(dayIOS)
    date.setMinutes(minutesIOS);
    
  }
  
  let day = dayOfWeekAsInteger(date.getDay());
  let month = monthOfYearAsInteger(date.getMonth());
  let dateNumber = date.getDate();
 
  var hours = date.getHours();
  var minutes = date.getMinutes();
  if (!ampm) {
    // @ts-ignore
    var ampm = hours >= 12 ? "PM" : "AM";
  }
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // @ts-ignore
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  let result = `${month} ${dateNumber}, ${day} at ${strTime} ${pst ? "EST" : ""}`;
  return result;
};
function dayOfWeekAsInteger(day) {
  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return days[day];
}
function monthOfYearAsInteger(month) {
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return months[month];
}

export const newDateIOSCreator = (val)=>{
if(val){
  let date = new Date(val);


  if(typeof val === "string"){
    const ios = val.split('-')[2].split('T')[0];
    // @ts-ignore
    date.setDate(ios)  
  }
  
  return date;

}else{
  return new Date(val)
}
}
