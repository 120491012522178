import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHistoryOfGames,
} from "store/reducers/games-reducer/thunk";
import { Game } from "../myGames/game/Game";
import c from "../myGames/MyGames.module.scss";
export const HistoryOfGames = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const games = useSelector((state) => state.gamesReducer.historyOfGames);
  const userTickets = useSelector(
    // @ts-ignore
    (state) => state.furtuneReducer.game.user_tickets
  );
  useEffect(() => {
    dispatch(getHistoryOfGames());
  }, []);
  return (
    <div className={c.myGamesWrapper}>
      {games &&
        games.results.map((g, i) => {
          return (
            <Game game={g} key={`my-games-layout-${i}`} showWinNumbers={true} />
          );
        })}
    </div>
  );
};
