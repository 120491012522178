import React, { useEffect, useState, useRef } from "react";
import c from "../../../pages-admin/GameLive/AGameLive.module.scss";
import cn from "classnames";
import { Button } from "components/button/Button";
import { PickNumberAuto } from "./PickNumberAuto/PickNumberAuto";
import { useSelector } from "react-redux";
export const AutoGame = ({
  win_numbers,
  values,
  setValues,
  secondsFromStart,
  initialValues,
  setInitialValues,
}) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(104)
  
  useEffect(() => {
    function resizeHandle() {
      if (window.innerWidth>767) {
        setWidth(ref.current.offsetWidth/8)
      }
      else {
        setWidth(104)
      }
      // setWidth(ref.current.offsetWidth/8)
    }
    resizeHandle()
    window.addEventListener("resize", resizeHandle);
    return () => {
      window.removeEventListener("resize", resizeHandle);
    }
  }, [])
  const [isStarted, setIsStarted] = useState(false);
  const tickets_used = useSelector(
    // @ts-ignore
    (state) => state.furtuneReducer.game.tickets_used
  );
  // const [secondsFromStart, setSecondsFromStart] = useState(-1);
  let [showGifs, setShowGifs] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  // for test imitation axios
  useEffect(() => {
    setInitialValues(win_numbers);
    return () => {
      setIsStarted(false);
    };
  }, []);
  // end for test

  // for test imitation socket
  // click bottom button
  const socketHandle = () => {
    setInitialValues(win_numbers);
  };
  // end for test

  useEffect(() => {
    setInitialValues(win_numbers);

    if (secondsFromStart >= 0 && !isStarted) {
      if (initialValues.length !== 0) {
        setIsStarted(true);
        startAnimate();
      }
    }
  }, [secondsFromStart]);
  const startAnimate = () => {
    let initialNumberIndex = Math.floor(secondsFromStart / 10);
    let newValues = [...values];
    let newShowGifts = [...showGifs];
    // if (initialNumberIndex <= 6) {
    for (let index = 0; index < initialNumberIndex && index < 6; index++) {
      // @ts-ignore
      newValues[index] = initialValues[index];
    }
    // }
    setValues(newValues);

    if (initialNumberIndex < newShowGifts.length) {
      newShowGifts[initialNumberIndex - 1] = true;
    }
    if (initialNumberIndex < 6) {
      nextGif(newShowGifts, newValues);
    }
  };

  const nextGif = (arr, values) => {
    let newArray = [...arr];
    let index = newArray.indexOf(true);
    if (index > -1) {
      newArray[index] = false;
      showGifs[index] = false;
      if (index + 1 < newArray.length) {
        newArray[index + 1] = true;
        showGifs[index + 1] = true;
      }
    } else {
      newArray[0] = true;
      showGifs[0] = true;
    }
    let newValues = [...values];
    let indexVal = newValues.indexOf("-");
    setShowGifs(newArray);
    if (indexVal > -1 && indexVal < 6) {
      // @ts-ignore
      setTimeout(() => {
        newValues[indexVal] = initialValues[indexVal];
        setValues(newValues);
        nextGif(newArray, newValues);
      }, 10000);
    }
  };

  return (
    <div>
      <div className={c.buyTicketWrapper} ref={ref}>
        <div className={c.buyTicketWrapper__numberPicker}>
          <div className={c.pickerHeader}>
            <div className={c.left}>
              <div className={c.title}> Live broadcast </div>
              <div className={c.subtitle}> {tickets_used} tickets used</div>
            </div>
          </div>

          <div className={c.pickers}>
            <PickNumberAuto
              width={width}
              min={1}
              max={7}
              index={0}
              disablePick={true}
              showGif={showGifs[0]}
              value={values[0]}
              withGif={true}
            />
            <div className={c.circles} style={{paddingTop: `${width/2-2}px`}}>
              <div className={c.circlesIcon}></div>
            </div>
            <PickNumberAuto
              width={width}
              min={8}
              max={14}
              index={1}
              disablePick={true}
              showGif={showGifs[1]}
              value={values[1]}
              withGif={true}
            />
            <div className={cn(c.circles, "mobile-hidden")} style={{paddingTop: `${width/2-2}px`}}>
              <div className={c.circlesIcon}></div>
            </div>
            <PickNumberAuto
              width={width}
              min={15}
              max={21}
              index={2}
              disablePick={true}
              value={values[2]}
              showGif={showGifs[2]}
              withGif={true}
            />
            <div className={c.circles} style={{paddingTop: `${width/2-2}px`}}>
              <div className={c.circlesIcon}></div>
            </div>
            <PickNumberAuto
              width={width}
              min={22}
              max={28}
              index={3}
              disablePick={true}
              showGif={showGifs[3]}
              value={values[3]}
              withGif={true}
            />
            <div className={c.circles} style={{paddingTop: `${width/2-2}px`}}>
              <div className={cn(c.circlesIcon, "mobile-hidden")}></div>
            </div>
            <PickNumberAuto
              width={width}
              min={29}
              max={35}
              index={4}
              value={values[4]}
              disablePick={true}
              showGif={showGifs[4]}
              withGif={true}
            />
          </div>

          <div className={c.pickers}>
            <PickNumberAuto
              width={width}
              min={1}
              max={7}
              index={5}
              withCircle={false}
              disablePick={true}
              showGif={showGifs[5]}
              withGif={true}
              value={values[5]}
            />
            <div className={c.circles} style={{paddingTop: `${width/2-2}px`}}>
              <div className={cn(c.circlesIcon, c.none, "no-background")}></div>
            </div>
            <PickNumberAuto
              width={width}
              min={8}
              max={14}
              index={5}
              withCircle={false}
              disablePick={true}
              showGif={showGifs[5]}
              withGif={true}
              value={values[5]}
            />
            <div className={cn(c.circles, "mobile-hidden")} style={{paddingTop: `${width/2-2}px`}}>
              <div className={cn(c.circlesIcon, c.none, "no-background")}></div>
            </div>
            <PickNumberAuto
              width={width}
              min={15}
              max={21}
              index={5}
              disablePick={true}
              border={"2px solid #FF3D3D"}
              showGif={showGifs[5]}
              withGif={true}
              value={values[5]}
              gifType={true}
            />
            <div className={c.circles} style={{paddingTop: `${width/2-2}px`}}>
              <div className={cn(c.circlesIcon, c.none, "no-background")}></div>
            </div>
            <PickNumberAuto
              width={width}
              min={22}
              max={28}
              index={5}
              withCircle={false}
              disablePick={true}
              showGif={showGifs[5]}
              withGif={true}
              value={values[5]}
            />
            <div className={c.circles} style={{paddingTop: `${width/2-2}px`}}>
              <div className={cn(c.circlesIcon, c.none, "no-background")}></div>
            </div>
            <PickNumberAuto
              width={width}
              min={29}
              max={35}
              index={5}
              withCircle={false}
              disablePick={true}
              showGif={showGifs[5]}
              withGif={true}
              value={values[5]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
