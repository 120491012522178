import React from 'react'
import classes from "./UpcomingGame.module.scss";
import { dateFormatterGame } from "utils/formatters/dateFormatter";
import { formatNumber } from "utils/formatters/numberFormatter";

export const UpcomingGame = ({game}) => {
  return (
    <div className={classes.UpcomingGame}>
      <div className={classes.date}>
        {dateFormatterGame(game.start)}
      </div>
      <div className={classes.amount}>
        ${formatNumber(game.amount)}
        <div className={classes.icon}>
          <div className="icon-diamond"></div>
        </div>
      </div>
    </div>
  )
}
