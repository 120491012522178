import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import classes from "./Header.module.scss";
import cn from "classnames";
export const Header = (props) => {
  // @ts-ignore
  const user = useSelector((state) => state.userReducer.user);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <header className={classes.navbarWrapper}>
      <NavLink to="/">
        <div className={classes.logo}></div>
      </NavLink>
      <div
        className={cn(
          classes.buttons,
          "tablet-hidden no-margin-left desktop-hidden"
        )}
      >
        <div
          className={classes.mobileMenu}
          onClick={() => {
            isOpen === true ? setIsOpen(false) : setIsOpen(true);
          }}
        >
          <div className={cn(classes.singUp, "pl-26")}>
            <div className="menu-icon"> </div>
            <div className={classes.text}>Menu</div>
          </div>
          {isOpen && (
            <div className={classes.dropdown}>
              <div className={classes.dropdown__menu}>
                {user ? (
                  <div>
                    <NavLink to="/">
                      <div className={cn(classes.homeLink, "flex")}>
                        <div className="icon-home"></div>
                        Home
                      </div>
                    </NavLink>
                  </div>
                ) : (
                  <div onClick={() => props.setIsLoginOpen(true)}>
                    <div>Login</div>
                  </div>
                )}
                {user ? (
                  <div className={classes.singUp}>
                    <NavLink to="/profile">
                      <div className={classes.text}>{user.username}</div>
                    </NavLink>
                  </div>
                ) : (
                  <div onClick={() => props.openSignUp(true)}>
                    {/* <div className={classes.text}>Sign up</div> */}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={cn(classes.buttons, "mobile-hidden")}>
        {user ? (
          <div>
            <NavLink to="/">
              <div className={cn(classes.homeLink, "flex")}>
                <div className="icon-home"></div>
                Home
              </div>
            </NavLink>
          </div>
        ) : (
          <div onClick={() => props.setIsLoginOpen(true)}>
            <div>Login</div>
          </div>
        )}
        {user ? (
          <div className={classes.singUp}>
            <NavLink to="/profile">
              <div className={classes.text}>{user.username}</div>
            </NavLink>
          </div>
        ) : (
          // <div
          //   className={classes.singUp}
          //   onClick={() => props.openSignUp(true)}
          // >
          //   <div className={classes.text}>Sign up</div>
          // </div>
          <div></div>
        )}
      </div>
    </header>
  );
};
export default Header;
