import React, { useEffect, useState } from "react";
import { Header } from "./components/Header/Header";
import c from "./ClientLayout.module.scss";
import Main from "../../pages/Main/Main";
import { SignUp } from "pages/SignUp/SignUp";
import Modal from "react-modal";
import queryString from "query-string";
import { Furtune } from "pages/Furtune/Furtune";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import { Login } from "pages/Login/Login";
import { getProfileThunk, refresh } from "store/reducers/user-reducer/thunk";
import { useDispatch } from "react-redux";
import Profile from "pages/Profile/Profile";
import { ConfirmModal } from "pages/SignUp/FirstStep/ConfirmModal/ConfirmModal";
import { Confirm } from "pages/Confirm/Confirm";
import { ResetPassword } from "pages/ResetPassword/ResetPassword";
import { ErrorPage } from "pages/404/ErrorPage";
import { CLoader } from "components-сommon/Cloader/CLoader";
import { AutoGame } from "pages/Furtune/AutoGame/AutoGame";
import { AutoGamePage } from "pages/AutoGamePage/AutoGamePage";
import { BTCModal } from "pages/BTCModal/BTCModal";
import  { detect }  from 'detect-browser';
import { useSelector } from "react-redux";

import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { ForgotPasswordModal } from "./../../pages/ForgotPasswordModal/ForgotPasswordModal";
import { SuccessModal } from "pages/SuccessModal/SuccessModal";
import cn from "classnames";
import { lastOfType } from "glamor";

export const ClientLayout = () => {
  // @ts-ignore
  const user = useSelector((state) => state.userReducer.user);
  const [isSignUpOpen, setIsSignUpOpen] = React.useState(false);
  const [isLoginOpen, setIsLoginOpen] = React.useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = React.useState(false);
  const [isConfirmModalOpen, isConfirm] = useState(false);
  const [isSuccessModalOpen, openSuccessModal] = useState(false);
  const [userEmail, setUserEmail] = useState(false);
  const [isBTCOpen, setIsBTC] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const ref = urlParams.get("ref");
  const browser = detect();
  useEffect(() => {
    if (user===null) {
      setIsLoginOpen(true)
    }
  }, [user])
 
// handle the case where we don't detect the browser

  const openBTCModal = () => {
    let showBTC = localStorage.getItem("showBTC");
    if (!showBTC) {
      setIsBTC(true);
      localStorage.setItem("showBTC", 'true');
    }
  };
  const setIsConfirmModalOpen = (isOpen, email) => {
    isConfirm(isOpen);
    setUserEmail(email);
  };
  const openLogin = () => {
    setIsLoginOpen(true);
  };
  const openRegister = () => {
    setIsSignUpOpen(true);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (ref) {
      setIsSignUpOpen(true);
    }
    let token = localStorage.getItem("access");
    if (location.pathname === "/dyhfds1op") {
      setIsSignUpOpen(true);
    }
    if (location.pathname === "/login") {
      openLogin(true);
    }
    if (token) {
      dispatch(getProfileThunk());
    }
  }, []);
  return (
    <div className={cn(c.ClientLayout , browser.name)}>
      <div className="container">
        <Header
          isSignUpOpen={isSignUpOpen}
          openSignUp={setIsSignUpOpen}
          isLoginOpen={isLoginOpen}
          setIsLoginOpen={setIsLoginOpen}
        />
        <Route
          path="/confirm"
          render={() => <Confirm openLogin={openLogin} />}
        />
        <Route path="/reset-password" render={() => <ResetPassword />} />
        <CLoader />
        <Switch>
          <Route exact path="/" render={() => <Main />} />g
          <Route exact path="/confirm" render={() => <Main />} />
          <Route exact path="/reset-password" render={() => <Main />} />
          <Route exact path="/dyhfds1op" render={() => <Main />} />
          <Route exact path="/login" render={() => <Main />} />
          <Route exact path="/game-auto" render={() => <AutoGamePage />} />
          <Route
            exact
            path="/games/:gameId"
            render={() => <Furtune setIsLoginOpen={setIsLoginOpen} />}
          />
          <Route exact path="/profile" render={() => <Profile />} />
          <Route exact path="/game/auto" render={() => <Profile />} />
          <Route render={() => <ErrorPage />} />
        </Switch>
        <Modal
          appElement={document.getElementById("root")}
          isOpen={isSignUpOpen}
          onRequestClose={() => setIsSignUpOpen(false)}
        >
          <GoogleReCaptchaProvider
            reCaptchaKey="6LeTriUaAAAAANhB3MZvCmUX2ZZ0kNs7uj0YnhYV"
            language="[optional_language]"
            useRecaptchaNet={false}
            scriptProps={{
              async: false, // optional, default to false,
              defer: false, // optional, default to false
              appendTo: "body", // optional, default to "head", can be "head" or "body",
              nonce: undefined, // optional, default undefined
            }}
          >
            <SignUp
              setIsOpen={setIsSignUpOpen}
              isConfirmModalOpen={isConfirmModalOpen}
              setIsConfirmModalOpen={setIsConfirmModalOpen}
              openLogin={openLogin}
            />
          </GoogleReCaptchaProvider>
        </Modal>
        ,
        <Modal
          appElement={document.getElementById("root")}
          isOpen={isLoginOpen}
          onRequestClose={() => setIsLoginOpen(false)}
        >
          <Login
            setIsOpen={setIsLoginOpen}
            openRegister={openRegister}
            openBTCModal={openBTCModal}
            setIsForgotPasswordOpen={setIsForgotPasswordOpen}
          />
        </Modal>
        <ConfirmModal
          isConfirmModalOpen={isConfirmModalOpen}
          setIsConfirmModalOpen={isConfirm}
          email={userEmail}
        />
        <BTCModal isBTCOpen={isBTCOpen} setIsBTC={setIsBTC} />
        {/* <GoogleReCaptcha onVerify={() => {}} /> */}
        <Modal
          appElement={document.getElementById("root")}
          isOpen={isForgotPasswordOpen}
          onRequestClose={() => setIsForgotPasswordOpen(false)}
        >
          <ForgotPasswordModal
            setIsForgotPasswordOpen={setIsForgotPasswordOpen}
            setIsLoginOpen={setIsLoginOpen}
            openSuccessModal={openSuccessModal}
          />
        </Modal>
        <Modal
          appElement={document.getElementById("root")}
          isOpen={isSuccessModalOpen}
          onRequestClose={() => openSuccessModal(false)}
        >
          <SuccessModal setIsOpen={openSuccessModal} />
        </Modal>
      </div>
    </div>
  );
};
