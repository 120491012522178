import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { logout } from "store/reducers/user-reducer/thunk";
import classes from "./Header.module.scss";
export const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className={classes.HeaderContainer}>
      <div className="container no-padding-top">
        <header className={classes.Header}>
          <div className={classes.navigation}>
            <NavLink to="/adm/1" className={classes.logo}>
              Admin Console
            </NavLink>
            <div className={classes.buttons}>
              <NavLink
                to="/adm/1"
                className={classes.button}
                activeClassName={classes.active}
              >
                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#212121"
                >
                  <path
                    d="M17 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 1V5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 1V5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 9H19"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className={classes.label}>Calendar of games</div>
              </NavLink>
              <NavLink
                to="/adm/winners/1"
                className={classes.button}
                activeClassName={classes.active}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#212121"
                >
                  <path
                    d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 13C7 13 8.5 15 11 15C13.5 15 15 13 15 13"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 8H8.01"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 8H14.01"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className={classes.label}>Winners</div>
              </NavLink>
              <NavLink
                to="/adm/statistics"
                className={classes.button}
                activeClassName={classes.active}
              >
                <svg
                  width="24"
                  height="16"
                  viewBox="0 0 24 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#212121"
                >
                  <path
                    d="M23 1L13.5 12.0833L8.5 6.25L1 15"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 1H23V8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className={classes.label}>Statistics</div>
              </NavLink>
              <NavLink
                to="/adm/users/1"
                className={classes.button}
                activeClassName={classes.active}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="20"
                  viewBox="0 0 17 20"
                  fill="none"
                  stroke="#212121"
                >
                  <path
                    d="M16.5 19V17C16.5 15.9391 16.0786 14.9217 15.3284 14.1716C14.5783 13.4214 13.5609 13 12.5 13H4.5C3.43913 13 2.42172 13.4214 1.67157 14.1716C0.921427 14.9217 0.5 15.9391 0.5 17V19"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.5 9C10.7091 9 12.5 7.20914 12.5 5C12.5 2.79086 10.7091 1 8.5 1C6.29086 1 4.5 2.79086 4.5 5C4.5 7.20914 6.29086 9 8.5 9Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className={classes.label}>Users</div>
              </NavLink>
              <NavLink
                to="/adm/settings"
                className={classes.button}
                activeClassName={classes.active}
              >
                <svg width="17" className={classes.fillHover} height="20" style={{fill: '#505050'}} version="1.1" id="Шар_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
                  <path d="M306.5,512h-101c-11.3,0-20.5-9.2-20.5-20.5v-54.6l-9.1-4.6l-40,40c-7.7,7.7-21.3,7.7-29,0L39.6,405c-8-8-8-21,0-29l40-40
                    l-4.6-9.1H20.5C9.2,326.9,0,317.8,0,306.5v-101C0,194.2,9.2,185,20.5,185h54.6l4.5-9.1l-40-40c-8-8-8-21,0-29l67.3-67.3
                    c8-8,21-8,29,0l40,40l9.1-4.5V20.5C185,9.2,194.2,0,205.5,0h100.9c11.3,0,20.5,9.2,20.5,20.5v54.6l9.1,4.6l40-40c8-8,21-8,29,0
                    l67.3,67.3c3.8,3.8,6,9.1,6,14.5s-2.2,10.6-6,14.5l-40,40l4.6,9.1h54.6c11.3,0,20.5,9.2,20.5,20.5v100.9c0,11.3-9.2,20.5-20.5,20.5
                    h-54.6l-4.6,9.1l40,40c3.8,3.8,6,9,6,14.5c0,5.4-2.2,10.6-6,14.5L405,472.4c-3.8,3.8-9,6-14.5,6l0,0c-5.4,0-10.6-2.2-14.5-6l-40-40
                    l-9.1,4.6v54.6C326.9,502.8,317.8,512,306.5,512z M226,471h60v-46.8c0-7.8,4.4-14.9,11.3-18.3l33.7-16.8c7.9-3.9,17.4-2.4,23.6,3.8
                    l36,36l38.3-38.3l-36-36c-6.2-6.2-7.8-15.7-3.8-23.6l16.8-33.7c3.5-6.9,10.6-11.3,18.3-11.3H471v-60h-46.8
                    c-7.8,0-14.9-4.4-18.3-11.3l-16.8-33.6c-3.9-7.9-2.4-17.4,3.8-23.6l36-36l-38.3-38.3l-36,36c-6.3,6.2-15.8,7.7-23.6,3.8l-33.7-16.8
                    c-6.9-3.5-11.3-10.6-11.3-18.3V41h-60v46.8c0,7.8-4.4,14.8-11.3,18.3L181,122.9c-7.9,3.9-17.4,2.4-23.6-3.8l-36-36l-38.3,38.3l36,36
                    c6.2,6.2,7.8,15.8,3.8,23.6l-16.8,33.7c-3.5,6.9-10.6,11.3-18.3,11.3H41v60h46.8c7.8,0,14.8,4.4,18.3,11.3l16.8,33.7
                    c3.9,7.9,2.4,17.4-3.8,23.6l-36,36l38.3,38.3l36-36c6.2-6.2,15.8-7.8,23.6-3.8l33.6,16.8c6.9,3.5,11.3,10.6,11.3,18.3L226,471
                    L226,471z M205.5,87.8h0.2H205.5z"/>
                  <path d="M256,343.8L256,343.8c-48.5,0-87.8-39.4-87.8-87.8s39.4-87.8,87.8-87.8c23.5,0,45.5,9.1,62.1,25.7s25.7,38.6,25.7,62.1
                    c0,23.4-9.2,45.5-25.7,62.1C301.5,334.7,279.5,343.8,256,343.8z M256,209.2c-25.9,0-46.9,21-46.9,46.8s21,46.8,46.8,46.8l0,0l0,0
                    c12.5,0,24.2-4.9,33.1-13.7s13.7-20.6,13.7-33.1C302.9,230.2,281.9,209.2,256,209.2z"/>
                </svg>
                <div className={classes.label}>Settings</div>
              </NavLink>
            </div>
          </div>
          <div className={classes.button}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#212121"
            >
              <path
                d="M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 15L19 10L14 5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M19 10H7" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <div
              className={classes.label}
              onClick={() => {
                dispatch(logout());
                history.push("/");
              }}
            >
              Sign out
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};
export default Header;
