import { CForm } from "components-сommon/CForm/CForm";
import { Button } from "components/button/Button";
import { ImageUploader } from "components/imageUploader/ImageUploader";
import { Input } from "components/input/Input";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProfileThunk } from "store/reducers/user-reducer/thunk";
import { validateEmail } from "utils/validators/validators";
import c from "./ProfileSettings.module.scss";
import cn from "classnames"
export const ProfileSettings = () => {
  const [username, setUsername] = useState(
    // @ts-ignore
    useSelector((state) => state.userReducer.user.username)
  );
  const [avatarError, setAvatarError] = useState(false);

  const [email, setEmail] = useState(
    // @ts-ignore
    useSelector((state) => state.userReducer.user.email)
  );
  const [avatar, setAvatar] = useState(null);
  const [password, setPassword] = useState("");
  // @ts-ignore
  const [wallet, setWallet] = useState(
    useSelector((state) =>
      // @ts-ignore
      state.userReducer.user.wallet ? state.userReducer.user.wallet : ""
    )
  );
  const dispatch = useDispatch();
  let formData = new FormData();
  formData.append("username", username);
  if (avatar) {
    formData.append("avatar", avatar);
  }
  if (wallet) {
    formData.append("wallet", wallet);
  }

  if (password) {
    formData.append("password", password);
  }

  let submitHandle = () => {
    dispatch(setProfileThunk(formData));
  };
  let errorHandle = () => {};
  const isError = false;

  return (
    <div className={c.profileSettingsWrapper}>
      <CForm
        onSubmit={submitHandle}
        onError={errorHandle}
        serverErrors={isError}
        respond={"profile"}
      >
        <div className={c.item}>
          <Input
            unkey="userName"
            label={"User name "}
            value={username}
            onChange={setUsername}
            validate={[
              (val) => (val && val.length > 0) || "This field is required",
              (val) => val.length < 200 || "This field is too long",
            ]}
          />
          <div className={c.subtitle}>To be shown as winner’s name</div>
        </div>
        <div className={cn(c.item , "disabled-field")}>
          <Input
            unkey="userEmail"
            label={"Email"}
            readonly
            value={email}
            onChange={setEmail}
            validate={validateEmail}
          />
        </div>
        <div className={c.item}>
          <div className={c.title}>Avatar</div>
          <ImageUploader
            image={avatar}
            setImage={setAvatar}
            setIsError={setAvatarError}
          />
          {avatarError && (
            <div className={c.imageHelperError}>image size is too large</div>
          )}
        </div>

        <div className={cn(c.item , "disabled-field")}>
          <Input
         
            unkey="password"
            label={"Password"}
            readonly
            type="password"
            value={"........"}
            onChange={setPassword}
            validate={[(val) => val.length < 15 || "This field is too long"]}
          />
        </div>

        <div className={c.item}>
          <Input
            unkey="wallet"
            label={"Your BTC Wallet address"}
            type="text"
            value={wallet}
            onChange={setWallet}
            validate={[
              // (val) => (val && val.length > 0) || "This field is required",
              (val) => val.length < 1000 || "This field is too long",
            ]}
          />
          <div className={c.subtitle}>
            We will send your prize to this Bitcoin wallet. Don't have a wallet?
            <span className={c.yellow}>Create one here </span>
          </div>
        </div>
        <div className={c.buttonWrapper}>
          <Button submit={!avatarError && true} width={"100%"}>
            <div>Save</div>
          </Button>
        </div>
      </CForm>
    </div>
  );
};
