import React from "react";
import "./App.css";
import "./scss/main.scss";

import { ClientLayout } from "layouts/ClientLayout/ClientLayout";
import { AdminLayout } from "layouts/AdminLayout/AdminLayout";
import { Route, Switch } from "react-router-dom";

// @ts-ignore
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useSelector } from "react-redux";

// @ts-ignore

function App() {
  // @ts-ignore
  const user = useSelector((state) => state.userReducer.user);

  return (
    <div className="App">
      <Switch>
        {user && user.role === 0 && (
          <Route path="/adm" render={() => <AdminLayout />} />
        )}

        <Route path="/" render={() => <ClientLayout />} />
      </Switch>
      <ToastContainer autoClose={10000} />
    </div>
  );
}

export default App;
