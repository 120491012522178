import CCalendar from "components-сommon/CCalendar/CCalendar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { UpcomingGames } from "./components/UpcomingGames/UpcomingGames";
import {
  formatNumber,
  formatNumberSpace,
} from "utils/formatters/numberFormatter";
import classes from "./DatePicker.module.scss";
import cn from "classnames";
import { dateFormatterGame, newDateIOSCreator } from "utils/formatters/dateFormatter";
import { lastOfType } from "glamor";
import { getNextGameThunk } from "store/reducers/games-reducer/thunk";
export const DatePicker = ({ nextGame }) => {
  const [dayValue, setDayValue] = React.useState(newDateIOSCreator(nextGame));

  const [dayPreview, setDayPreview] = React.useState(dayValue.getDate());

  // @ts-ignore
  const events = useSelector((state) => state.gamesReducer.games.results);
  //need to  compare  day from store and day in state only by day and month
  // because time  is different
  let publishedDates = events.filter((e) => {
    return e.is_published;
  });
  const eventDates = publishedDates.map((element) => {
    let date = new Date(element.start);
    return date;
  });
  let eventBuyedDates = [];
  publishedDates.map((element) => {
    if (!element.user_has_tickets) {
      eventBuyedDates.push(new Date(element.start))
    }
  });
  let eventPastDates = [];
  publishedDates.map((element) => {
    let now = new Date().getTime();
    let start = new Date(element.start).getTime();
    if (start<now) {
      eventPastDates.push(new Date(element.start))
    }
  });

  const compareDates = (firstDate, secondDate) => {
    return (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate()
    );
  };
  // const eventDates = [new Date(), new Date(date.setDate(date.getDate() + 2))];

  let formatDay = (value) => {
    let m = value.toLocaleString("en-us", { weekday: "long" });
    let d = value.getDate();

    return `${m}, ${d}`;
  };

  let setDay = (value) => {
    if (value !== null) {
      if (value.getDate() < 10) {
        // @ts-ignore
        setDayPreview(`0${value.getDate()}`);
      } else {
        // @ts-ignore
        setDayPreview(`${value.getDate()}`);
      }
      setDayValue(value);
    }
  };

  return (
    <>
      <div className={classes.datePickerWrapper}>
        <div
          className={cn(
            classes.datePickerWrapper__dayWrapper,
            "mobile-hidden tablet-hidden"
          )}
        >
          <div className={classes.day}>{dayPreview}</div>
          <div className="calendar-after"></div>
        </div>
        <div className={classes.datePickerWrapper__calendarWrapper}>
          <CCalendar
            onDatesSelect={setDay}
            multiDaySelect={false}
            eventDates={eventDates}
            eventBuyedDates={eventBuyedDates}
            eventPastDates={eventPastDates}
            disablePrevDates={false}
            value={dayValue}
          >
            <div className={classes.datePickerWrapper__dayWrapper}>
              <div className={classes.day}>{dayPreview}</div>
              <div className="calendar-after"></div>
            </div>
          </CCalendar>
        </div>
      </div>
      <div className={classes.bottomConrent}>
        <div className={cn(classes.month, classes.withPadding)}>
          {formatDay(dayValue)}
        </div>

        <div className={classes.eventWrapper}>
          {events.map((e, i) => {
            if (compareDates(newDateIOSCreator(e.start), dayValue)) {
              return (
                <div key={`events-${i}`}>
                  <div className={classes.event}>
                    <div>
                      <div className={classes.time}>
                        {dateFormatterGame(e.start)}
                      </div>
                      <div className={classes.amount}>
                        ${formatNumber(e.amount)}
                      </div>
                    </div>
                    <NavLink to={`/games/${e.id}`}>
                      <div className={classes.reviewGame}>Game Info</div>
                    </NavLink>
                  </div>
                  <div className={classes.winners}>
                    {e.winners.map((w, index) => {
                      return (
                        <div
                          className={classes.winner}
                          key={`winners-${index}`}
                        >
                          <div>
                            <div className={classes.winner__index}>
                              {index + 1}
                            </div>
                            <div className={classes.winner__avatar}>
                              {w.user.avatar && (
                                <img
                                  src={`https://app.bitcoinbingo.io${w.user.avatar}`}
                                />
                              )}
                            </div>
                            <div className={classes.winner__useranme}>
                              {w.user.username}
                            </div>
                          </div>

                          <div>
                            <div className={classes.winner__amount}>
                              <div>{formatNumberSpace(w.amount)}</div>
                              <div className="icon-diamond"></div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            }
          })}
        </div>
        <UpcomingGames/>
      </div>
    </>
  );
};
