import classNames from "classnames";
import { Button } from "components/button/Button";
import { Input } from "components/input/Input";
import React, { useEffect } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import Recaptcha from "react-recaptcha";
import {
  closeModalHelper,
  openModalHelper,
} from "utils/modalHelper/modalHelper";
import { FirstStep } from "./FirstStep/FirstStep";
import { SecondStep } from "./SecondStep/SecondStep";
import classes from "./SignUp.module.scss";

export const SignUp = (props) => {
  let [isFirstStep, setIsFirstStep] = React.useState(true);
  let wrapperClasses = classNames({
    ["main-layout"]: "main-layout padding-none-mobile",
    mt225: !isFirstStep,
  });
  useEffect(() => {
    openModalHelper();
  }, []);

  useEffect(() => {
    return () => {
      closeModalHelper();
    };
  }, []);

  return (
    <div className={classNames(wrapperClasses, "padding-none-mobile")}>
      <div
        className="iconClose icon-sign-up"
        onClick={() => {
          props.setIsOpen(false);
        }}
      ></div>

      <div className={classes.loginWrapper}>
        {isFirstStep ? (
          <FirstStep
            setIsOpen={props.setIsOpen}
            setIsConfirmModalOpen={props.setIsConfirmModalOpen}
            openLogin={props.openLogin}
          />
        ) : (
          <SecondStep />
        )}

        <div className={classes.buttonNextWrapper}></div>
      </div>
    </div>
  );
};
