import { gamesAPI } from "api/admin/gamesAPI";
import { usersAPI } from "api/admin/usersAPI";
import { winnersAPI } from "api/admin/winnersAPI";
import {
  setGamesAC,
  setUsersAC,
  setWinnersAC,
  setWinnersCountAC,
} from "./actions";
export const getGamesThunkAdmin = (page) => {
  return (dispatch) => {
    gamesAPI.games
      .getList(page)
      .then((response) => {
        dispatch(setGamesAC(response.data));
      })
      .catch((error) => {});
  };
};
export const getUsersThunk = (page) => {
  return (dispatch) => {
    usersAPI.users
      .getUsers(page)
      .then((response) => {
        dispatch(setUsersAC(response.data));
      })
      .catch((error) => {});
  };
};

export const createGameThunk = (game) => {
  game.amount = game.amount.replace(",", "");
  game.amount = parseInt(game.amount.replace("$", ""));
  game.is_published = false;

  return (dispatch) => {
    gamesAPI.games
      .create(game)
      .then((response) => {})
      .catch((error) => {});
  };
};
export const startGameThunk = (id, game, redirectHandle) => {
  return (dispatch, getState) => {
    gamesAPI.games
      .patchGame(id, game)
      .then((response) => {
        redirectHandle(id);
      })
      .catch((error) => {});
  };
};
export const finishGameThunk = (game, redirectHandle) => {
  let newGame = game;
  newGame.status = 2;
  return (dispatch, getState) => {
    gamesAPI.games
      .patchGame(game.id, newGame)
      .then((response) => {
        redirectHandle();
      })
      .catch((error) => {});
  };
};
export const unpublishGameThunk = (game) => {
  let newGame = game;
  newGame.is_published = false;
  return (dispatch, getState) => {
    gamesAPI.games
      .patchGame(game.id, newGame)
      .then((response) => {})
      .catch((error) => {});
  };
};
export const publishGameThunk = (game) => {
  let newGame = game;
  newGame.is_published = true;
  return (dispatch, getState) => {
    gamesAPI.games
      .patchGame(game.id, newGame)
      .then((response) => {})
      .catch((error) => {});
  };
};

export const setTicketsInGame = () => {
  return (dispatch, getState) => {
    let game = getState().furtuneReducer.game;
    let newGameWinNumbers = [];
    getState().adminReducer.ticketNumbers.map((n) => {
      if (n) {
        newGameWinNumbers.push(n);
      }
    });

    gamesAPI.games
      .patchGame(getState().furtuneReducer.game.id, {
        ...game,
        win_numbers: newGameWinNumbers,
      })
      .then((response) => {
        // dispatch();
      })
      .catch((error) => {});
  };
};
export const getWinnersThunk = () => {
  return async (dispatch, getState) => {
    let response = await winnersAPI.winners.getWinners();
    dispatch(setWinnersAC(response.data.results));
    dispatch(setWinnersCountAC(response.data.count));
  };
};
export const payWinnersThunk = (id) => {
  return async (dispatch, getState) => {
    let response = await winnersAPI.winners.pay(id);
    // dispatch(setWinnersAC(response.data.results));
    // dispatch(setWinnersCountAC(response.data.count));
  };
};
