import React, { useState } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import { CForm } from "components-сommon/CForm/CForm";
import { Button } from "components/button/Button";
import c from "../SignUp/SignUp.module.scss";
import { Input } from "components/input/Input";
import { useDispatch } from "react-redux";
import { setProfileThunk } from "store/reducers/user-reducer/thunk";
export const BTCModal = ({ setIsBTC, isBTCOpen }) => {
  const [isError, setIsError] = useState(false);
  const [wallet, setWallet] = useState("");
  const dispatch = useDispatch();
  let validateBTC = [
    (val) => val.length < 1000 || "This field is too long",
    // (val) => (val && val.length > 0) || "This field is required",
    // (val) => "asd123".match(/^[13][a-km-zA-HJ-NP-Z0-9]{26,33}$/),
  ];
  let formData = new FormData();

  formData.append("wallet", wallet);
  let submitHandle = () => {
    dispatch(
      setProfileThunk(formData, () => {
        setIsBTC(false);
      })
    );
  };
  let errorHandle = () => {};

  let wrapperClasses = classNames({
    ["main-layout"]: "main-layout",
  });
  return (
    <Modal
      appElement={document.getElementById("root")}
      onRequestClose={() => setIsBTC(false)}
      isOpen={isBTCOpen}
    >
      <div className={wrapperClasses}>
        <div className="iconClose" onClick={() => setIsBTC(false)}></div>

        <div className={c.loginWrapper}>
          <div className={c.loginWrapper__pageTitle}>BTC Wallet address</div>

          <CForm
            onSubmit={submitHandle}
            onError={errorHandle}
            serverErrors={isError}
          >
            <div className={c.item}>
              <Input
                label={"Your BTC Wallet address"}
                value={wallet}
                onChange={setWallet}
                validate={validateBTC}
              />
              <div className={c.subtitle}>
                We will send your prize to this Bitcoin wallet.
                <br /> Don't have a wallet?
                <span className={c.yellow}> Create one here</span>
              </div>
            </div>

            <div className={c.buttonNextWrapper}>
              <Button submit={true} width={"100%"}>
                Save
              </Button>
            </div>
          </CForm>
        </div>
      </div>
    </Modal>
  );
};
