import React from "react";
import classes from "./Statistic.module.scss";
import activeUsers from "../../../asserts/images/activeUsers.png";
import gamesPlayed from "../../../asserts/images/gamesPlayed.png";
import bitcoin from "../../../asserts/images/bitcoin.png";
import cn from "classnames";
export const Statistic = (props) => {
  let formatNumber = (value) => {
    return Number(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className={cn(classes.statistic)}>
      <div className={classes.statistic__title}>Our statistics</div>

      <div className={classes.statistic__item}>
        <div className={classes.image}>
          <img src={activeUsers} />
        </div>

        <div className={classes.data}>
          <div className={classes.data__label}>Active users </div>
          <div>{formatNumber(props.activeUsers)}</div>
        </div>
      </div>
      <div className={classes.statistic__item}>
        <div className={classes.image}>
          <img src={gamesPlayed} alt="" />
        </div>

        <div className={classes.data}>
          <div className={classes.data__label}>Games played </div>
          <div>{formatNumber(props.gamesPlayed)}</div>
        </div>
      </div>
      <div className={classes.statistic__item}>
        <div className={classes.image}>
          <img src={bitcoin} alt="" />
        </div>

        <div className={classes.data}>
          <div className={classes.data__label}>Amount of winnings </div>
          <div>${formatNumber(props.winningsAmount)}</div>
        </div>
      </div>
    </div>
  );
};
