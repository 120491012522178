import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import c from "./CSelect.module.scss";
import cn from "classnames";
const CSelect = ({ options, label, value, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [seleectedLabel, setSeleectedLabel] = useState('');
  useEffect(() => {
    let index = options.map((el) => {
      return el.value;
    }).indexOf(value);
    if (index>-1) {
      setSeleectedLabel(options[index].label)
    } else {
      setSeleectedLabel(value)
    }
  }, [value, options])
  return (
    <div className={c.selectWrapper}>
      <div className={c.label}>{label}</div>
      <div
        className={cn(c.data, disabled ? c.disabled : "")}
        onClick={() => {
          !isOpen ? setIsOpen(true) : setIsOpen(false);
        }}
      >
        <div className={c.value}>{seleectedLabel}</div>
        <div className={c.icon}></div>
      </div>
      <div className={cn(c.values, !isOpen && c.hidden)}>
        <div className={c.values__data}>
          {options.map((option, i) => {
            return (
              <div key={i}>
                <div
                  className={c.values__data__value}
                  onClick={() => {
                    onChange(option.value);
                    setIsOpen(false);
                  }}
                >
                  {option.label}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

CSelect.defaultProps = {
  disabled: false,
};
export default CSelect;
