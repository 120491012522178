import { gamesAPI } from "api/games/gamesAPI";
import { setWinNumbersAC } from "../admin-reducer/actions";
import { setGameAC } from "./actions";
import { displayToast } from "toast/toast";

export const getGameThunk = (gameId, isAdmin = false) => {
  return (dispatch, getState) => {
    gamesAPI.games
      .getGame(gameId)
      .then((response) => {
        if (response.data.all_game_tickets === null) {
          response.data.all_game_tickets = [];
        }

        if (isAdmin) {
          dispatch(setGameAC(response.data));
          dispatch(setWinNumbersAC(getState().furtuneReducer.game.win_numbers));
        } else {
          dispatch(setGameAC(response.data));
        }
      })
      .catch((error) => {});
  };
};
export const getTicketThunk = async (gameId, numbers , isFree) => {
  return async (dispatch) => {
    if(!isFree){
      await gamesAPI.games
      .getTicket(gameId, { numbers })
      .then((response) => {
        //nedd to dispatch user get all ticket from BE
      })
      .catch((error) => {
        if (error.response && error.response.data.msg) {
          displayToast(error.response.data.msg);
        }
      });
    }else{
      await gamesAPI.games
      .getFreeTicket(gameId, { numbers })
      .then((response) => {
        //nedd to dispatch user get all ticket from BE
      })
      .catch((error) => {});
    }
   
  };
};
