import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUpcomingGamesThunk } from "store/reducers/games-reducer/thunk";
import { Game } from "./game/Game";
import c from "./MyGames.module.scss";
export const MyGames = () => {
  const dispatch = useDispatch();
  const games = useSelector((state) => state.gamesReducer.upcomingGames);
  useEffect(() => {
    dispatch(getUpcomingGamesThunk());
  }, []);
  return (
    <div className={c.myGamesWrapper}>
      {games &&
        games.results.map((g, i) => {
          return <Game game={g} key={`my-games-layout-${i}`} />;
        })}
    </div>
  );
};
