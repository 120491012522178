import { Circle } from "components/circle/Circle";
import { Dots } from "components/dots/Dots";
import React from "react";
import {
  dateFormatterGame,
  dateFormatterProfile,
} from "utils/formatters/dateFormatter";
import c from "../MyGames.module.scss";
export const Game = ({ game, showWinNumbers }) => {
  let ticketNumbers = game.user_tickets[0].numbers;
  const checkIsNumberWinning = (number) => {
    if (game.win_numbers) {
      return game.win_numbers.indexOf(number) >= 0;
    }
  };
  const amount = game.user_tickets[0].amount;
  const isPaid = game.user_tickets[0].is_paid;

  return (
    <div className={c.gameWrapper}>
      <div>{dateFormatterProfile(game.start)}</div>
      <div className={c.cards}>
        <div className={c.cardContainer}>
          <div className={c.title}>
            <div>My ticket</div>
            {showWinNumbers && amount && (
              <div className={c.title__winData}>
                <div className="profile-win-icon"></div>
                <div className={c.data}>
                  <div>{amount}</div>
                  {isPaid ?<div className={c.paid}>Paid</div>:<div className={c.paid}>Not paid</div> }

                </div>
              </div>
            )}
          </div>
          <div className={c.dotsContainer}>
            <Dots count={21} />
          </div>

          <div className={c.userNumbers}>
            {ticketNumbers.map((t, index) => {
              return (
                <div className={c.circleWrapper} key={`${index}`}>
                  <Circle
                    active={checkIsNumberWinning(t) && "#ffc107"}
                    width={"35px"}
                    height={"35px"}
                    border={
                      checkIsNumberWinning(t) ? "none" : "2px solid #9AE1F1"
                    }
                  >
                    <div className={c.number}>{t ? t : "-"}</div>
                  </Circle>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
Game.defaultProps = {
  showWinNumbers: false,
};
