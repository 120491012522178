import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/redux-store";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <React.StrictMode>
        {/* <GoogleReCaptchaProvider
          reCaptchaKey="6LeTriUaAAAAANhB3MZvCmUX2ZZ0kNs7uj0YnhYV"
          language="[optional_language]"
          useRecaptchaNet={false}
          scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: "body", // optional, default to "head", can be "head" or "body",
            nonce: undefined, // optional, default undefined
          }}
        > */}
        <App />
        {/* </GoogleReCaptchaProvider>, */}
      </React.StrictMode>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
