import OutsideAlerter from "hooks/useOutsideAlerter";
import React, { useEffect, useState, useRef} from "react";
import ReactDOM from "react-dom";
import styles from "./CMenu.module.scss";
var classNames = require('classnames/bind');
var cx = classNames.bind(styles);


export const CMenu = ({ children, value, onChange, hideOnScroll, fixOnScreen, onClick }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const [node, setNode] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [t, setT] = useState(false);
  
  const show = () => {
    // @ts-ignore
    clearTimeout(t)
    setIsVisible(true)
  }
  const hide = () => {
    setT(
      // @ts-ignore
      setTimeout(() => {
        setIsVisible(false)
      }, 300)
    )
  }
  const getElPosition = function getCoords(elem) { // crossbrowser version
    var box = elem.getBoundingClientRect();
    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top  = box.top +  scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
}
  const setPosition = () => {
    let parent = ref.current.parentNode;
    setWidth(parent.offsetWidth);
    setLeft(getElPosition(parent).left);
    
    let top = getElPosition(parent).top+parent.offsetHeight - window.pageYOffset;
    if (fixOnScreen) {
      setTop((top<0)?(0):(top));
    } else {
      setTop(top);
    }
  }
  useEffect(() => {
    if (value) {
      show()
    } else {
      hide()
    }
  }, [value])
  useEffect(() => {
    let element = document.body;
    let node = document.createElement("DIV");
    // @ts-ignore
    setNode(node)
    element.appendChild(node)
    setPosition()
    setTimeout(() => {
      setPosition()
    }, 0);
    let listener = (event) => {
      if (hideOnScroll) {
        onChange(false);
      }
      setPosition()
    }
    window.addEventListener('scroll', listener, false);
    return function cleanup() {
      node.remove();
      window.removeEventListener('scroll', listener, false)
    };
  }, []);

  const menuStyle = {
    'visibility': (isVisible)?('visible'):('collapse'),
    'top': `${top}px`,
    'left': `${left}px`,
    'minWidth': `${width}px`,
  };

  let className = cx({
    'c-menu': true,
    'c-position-engine scroll c-menu--square': true,
    'c-transition--fade-enter-to c-transition--fade-enter-active': value,
    'c-transition--fade-leave-to c-transition--fade-leave-active': !value,
  });

  const onOutsideHandle = () => {
    onChange(false)
  }
  let contentJsx = <OutsideAlerter onOutside={onOutsideHandle}>
    {/* @ts-ignore */}
    <div className={className} style={menuStyle} onClick={onClick}>
      {isVisible&&children}
    </div>
  </OutsideAlerter>

  return <div ref={ref}>
    {(node)&&ReactDOM.createPortal(
      contentJsx,
      // @ts-ignore
      node,
    )}
  </div>;
};

CMenu.defaultProps = {
  onClick: () => {}
};