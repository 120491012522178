import React from "react";
import c from "../Tickets.module.scss";
export const Ticket = (props) => {
  const divStyle = {
    width: props.size,
    height: props.size,
  };

  let numbers = props.numbers.map((n, i) => {
    return (
      <div key={`numbers-in-tickets-${i}`}>
        <div className={c.ticket} style={divStyle}>
          {n}
        </div>
      </div>
    );
  });
  return (
    <div className={c.ticketWrapper}>
      {props.hideTitle ? (
        ""
      ) : (
        <div className={c.ticket__title}>{(props.ticket.status==='Regular')?('Ticket'):('Bonus ticket')} #{props.index}</div>
      )}

      <div className={c.items}>{numbers}</div>
    </div>
  );
};
Ticket.defaultProps = {
  numbers: [1, 2, 3],
};
