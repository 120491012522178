export const SET_GAMES_ADMIN = "SET_GAMES_ADMIN";
export const SET_USERS = "SET_USERS";
export const SET_TICKET_ADMIN = "SET_TICKET_ADMIN";
export const SET_WIN_NUMBERS = "SET_WIN_NUMBERS";
export const SET_WINNERS = "SET_WINNERS";
export const SET_WINNERS_COUNT = "SET_WINNERS_COUNT";

export const setGamesAC = (payload) => {
  return { type: SET_GAMES_ADMIN, payload };
};
export const setUsersAC = (payload) => {
  return { type: SET_USERS, payload };
};
export const setWinnersAC = (payload) => {
  return { type: SET_WINNERS, payload };
};
export const setWinnersCountAC = (payload) => {
  return { type: SET_WINNERS_COUNT, payload };
};

export const setTicketAdminAC = (index, number) => {
  return { type: SET_TICKET_ADMIN, index, number };
};
export const setWinNumbersAC = (numbers) => {
  let newNumbers = [];
  for (let i = 0; i < numbers.length; i++) {
    newNumbers.push(numbers[i]);
  }
  for (let i = numbers.length; i < 6; i++) {
    newNumbers.push(false);
  }

  return { type: SET_WIN_NUMBERS, newNumbers };
};
