import { instance } from "api/axios-instance";

export const AuthAPI = {
  user: {
    signUpAPI: (user) => {
      return instance.post(`auth/signup/`, user);
    },
    signInAPI: (user) => {
      return instance.post(`auth/signin/`, user);
    },
    get: () => {
      let token = localStorage.getItem("access");
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return instance.get(`users/profile/`);
    },
    set: (user) => {
      return instance.put(`users/profile/`, user);
    },
    refreshToken: () => {
      let token = localStorage.getItem("refresh");

      return instance.post("auth/refresh/", { refresh: token });
    },
    resetPassword: (email) => {
      return instance.post("auth/password/reset/", { email });
    },

    confirm: (uid, token) => {
      return instance.get(`/auth/activate/${uid}/${token}/`);
    },

    changePassword: (token) => {
      return instance.get(
        `/auth/password/reset/${token.uidb64}/${token.token}/confirm/`
      );
    },
    setNewPassword: (password) => {
      return instance.patch(`/auth/password/change/`, {
        new_password1: password,
        new_password2: password,
      });
    },
    reactivate: (email) => {
      return instance.post(`/auth/reactivate/`, {
        email: email,
      });
    },
  },
};
