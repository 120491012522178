import React, {useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getHomeUpcomingGamesThunk } from "store/reducers/games-reducer/thunk";
import classes from "./UpcomingGames.module.scss";
import { UpcomingGame } from "./components/UpcomingGame/UpcomingGame";

export const UpcomingGames = () => {
  const dispatch = useDispatch();
  const games = useSelector((state) => state.gamesReducer.homeUpcomingGames);
  useEffect(() => {
    dispatch(getHomeUpcomingGamesThunk());
  }, [])
  const upcomingGamesJsx = () => {
    if (games) {
      return games.results.map((el, i) => {
        return <UpcomingGame game={el} key={i} />
      })
    }
    return ''
  }
  return (
    <div className={classes.UpcomingGames}>
      <div className={classes.title}>Upcoming games</div>
      {upcomingGamesJsx()}
    </div>
  )
}
