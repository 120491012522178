import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import c from "./AGameLive.module.scss";
import { dateFormatterGame } from "utils/formatters/dateFormatter";
import { formatNumber } from "utils/formatters/numberFormatter";
import { useDispatch, useSelector } from "react-redux";
import { getGameThunk } from "store/reducers/furtune-reducer/thunk";
import { NavLink, useHistory, useParams } from "react-router-dom";
import cn from "classnames";
import { BuyTicket } from "pages/Furtune/buyTicket/BuyTicket";
import { IFrame } from "pages/Furtune/Iframe/IFrame";
import { GameNotStarted } from "pages/Furtune/gameNotStarted/GameNotStarted";
import { WinningNumbers } from "pages/Furtune/winningNumbers/WinningNumbers";
import { PickNumber } from "pages/Furtune/buyTicket/pickNumber/PickNumber";
import { Button } from "components/button/Button";
import { finishGameThunk } from "store/reducers/admin-reducer/thunk";
import { GameWinners } from "pages/Main/gameWinners/GameWinners";
import { setWinNumbersAC } from "store/reducers/admin-reducer/actions";
export const AGameLive = (props) => {
  // @ts-ignore
  const game = useSelector((state) => state.furtuneReducer.game);
  const ticketNumbers = useSelector(
    // @ts-ignore
    (state) => state.adminReducer.ticketNumbers
  );
  const {
    start,
    amount,
    is_finished,
    status,
    embed_code,
    winners,
    all_game_tickets,
  } = game;
  const history = useHistory();
  // const [isDisabled, setIsDisabled] = useState(status === 2 ? true : false);
  const isDisabled = status === 2 ? true : false;
  useEffect(() => {
    document.body.classList.add("background");

    return () => {
      document.body.classList.remove("background");
      dispatch(setWinNumbersAC([false, false, false, false, false, false]));
    };
  }, []);
  const dispatch = useDispatch();
  //@ts-ignore
  const { gameId } = useParams();
  let canFinish = ticketNumbers.some((t) => t === false);
  const redirectHadnle = () => {
    history.push("/adm/1");
  };
  const finishGame = () => {
    if (ticketNumbers.some((t) => t === false)) {
    } else {
      dispatch(finishGameThunk(game));
      dispatch(setWinNumbersAC([false, false, false, false, false, false]));
      dispatch(getGameThunk(gameId, true));
    }
  };
  useEffect(() => {
    dispatch(getGameThunk(gameId, true));
  }, []);
  return (
    <div className={c.liveGamePage}>
      <div className="container">
        <div className={c.furtune}>
          <div className={c.furtune__ticketPicker}>
            <div className="main-layout">
              <div className={cn(c.head, status > 0 ? c.live : "")}>
                <div className={c.title}>
                  <div className={c.title__sub}>
                    {start && dateFormatterGame(start)}
                  </div>
                  <div className={c.title__main}>
                    {amount && "$" + formatNumber(amount)}
                  </div>
                </div>
                <div
                  className={cn(
                    c.status,
                    status === 1 && c.red,
                    status === 2 && c.black
                  )}
                >
                  {status === 0 && "Not Started"}
                  {status === 1 && "Live"}
                  {status === 2 && "Finished "}
                </div>
              </div>
              <div className={c.buyTicketWrapper}>
                <div className={c.buyTicketWrapper__text}>Select Number</div>
                <div className={c.buyTicketWrapper__numberPicker}>
                  <div className={c.pickerHeader}>
                    <div className={c.left}>
                      <div className={c.title}>
                        {embed_code === ""
                          ? "Winning numbers"
                          : "Input winning numbers"}
                      </div>
                      <div className={c.subtitle}>
                        {all_game_tickets && all_game_tickets.length} tickets
                        used
                      </div>
                    </div>
                  </div>

                  <div className={c.pickers}>
                    <PickNumber
                      min={1}
                      max={7}
                      index={0}
                      withAdmin={true}
                      gameId={gameId}
                      disabled={isDisabled || embed_code === ""}
                    />
                    <div className={c.circles}>
                      <div className={c.circlesIcon}></div>
                    </div>
                    <PickNumber
                      min={8}
                      max={14}
                      index={1}
                      withAdmin={true}
                      gameId={gameId}
                      disabled={isDisabled || embed_code === ""}
                    />
                    <div className={cn(c.circles, "mobile-hidden")}>
                      <div className={c.circlesIcon}></div>
                    </div>
                    <PickNumber
                      min={15}
                      max={21}
                      index={2}
                      withAdmin={true}
                      gameId={gameId}
                      disabled={isDisabled || embed_code === ""}
                    />
                    <div className={c.circles}>
                      <div className={c.circlesIcon}></div>
                    </div>
                    <PickNumber
                      min={22}
                      max={28}
                      index={3}
                      withAdmin={true}
                      gameId={gameId}
                      disabled={isDisabled || embed_code === ""}
                    />
                    <div className={c.circles}>
                      <div className={cn(c.circlesIcon, "mobile-hidden")}></div>
                    </div>
                    <PickNumber
                      min={29}
                      max={35}
                      index={4}
                      withAdmin={true}
                      gameId={gameId}
                      disabled={isDisabled || embed_code === ""}
                    />
                  </div>

                  <div className={c.pickers}>
                    <PickNumber
                      min={1}
                      max={7}
                      index={5}
                      withAdmin={true}
                      withCircle={false}
                      gameId={gameId}
                      disabled={isDisabled || embed_code === ""}
                    />
                    <div className={c.circles}>
                      <div className={cn(c.circlesIcon, "no-background")}></div>
                    </div>
                    <PickNumber
                      min={8}
                      max={14}
                      index={5}
                      withAdmin={true}
                      withCircle={false}
                      gameId={gameId}
                      disabled={isDisabled || embed_code === ""}
                    />
                    <div className={cn(c.circles, "mobile-hidden")}>
                      <div className={cn(c.circlesIcon, c.none)}></div>
                    </div>
                    <PickNumber
                      min={15}
                      max={21}
                      index={5}
                      withAdmin={true}
                      gameId={gameId}
                      border={"2px solid #FF3D3D"}
                      disabled={isDisabled || embed_code === ""}
                    />
                    <div className={c.circles}>
                      <div
                        className={cn(c.circlesIcon, c.none, "no-background")}
                      ></div>
                    </div>
                    <PickNumber
                      min={22}
                      max={28}
                      index={5}
                      withAdmin={true}
                      withCircle={false}
                      gameId={gameId}
                      disabled={isDisabled || embed_code === ""}
                    />
                    <div className={c.circles}>
                      <div
                        className={cn(c.circlesIcon, c.none, "no-background")}
                      ></div>
                    </div>
                    <PickNumber
                      min={29}
                      max={35}
                      index={5}
                      withAdmin={true}
                      withCircle={false}
                      gameId={gameId}
                      disabled={isDisabled || embed_code === ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={cn(
              c.furtune__ticket,
              status === 0 && c.ticketNotSelected
            )}
          >
            <div className="second-layout">
              <div className={c.ticket}>
                {status === 1 && (
                  <div className={c.ticket__game}>
                    <div>Game</div>
                    <div className={c.buttonWrapper}>
                      <Button
                        disabled={canFinish}
                        width={"338px"}
                        onClick={() => {
                          finishGame();
                        }}
                      >
                        Finish game
                      </Button>
                    </div>
                  </div>
                )}
                {embed_code !== "" && (
                  <div className={c.ticket__video}>
                    <div className={c.title}>Video</div>
                    <div>
                      <IFrame code={embed_code} admin={true}></IFrame>
                    </div>
                  </div>
                )}

                <div className={c.ticket__winners}>
                  <div className={c.title}>
                    Winners {winners && winners.length}/
                    {all_game_tickets && all_game_tickets.length}
                  </div>
                  <div>
                    <GameWinners items={winners} page="admin" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
