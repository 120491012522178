import { instance } from "api/axios-instance";
export const gamesAPI = {
  games: {
    getGames: () => {
      let token = localStorage.getItem("access");

      if (token)
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return instance.get(`games/`);
    },
    getStatistics: () => {
      return instance.get(`games/statistics/`);
    },
    getUpcoming: () => {
      let token = localStorage.getItem("access");

      if (token)
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      return instance.get(`games/upcoming_games/`);
    },
    getHomeUpcoming: () => {
      return instance.get(`games/`, {
        params: {
          date_from: new Date(),
          ordering: "start",
        }
      });
    },
    getHistoryOfGames: () => {
      let token = localStorage.getItem("access");

      if (token)
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      return instance.get(`/games/history/`);
    },
    getGame: (id) => {
      let token = localStorage.getItem("access");

      if (token)
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      return instance.get(`games/${id}/`);
    },
    getTicket: (id, numbers) => {
      return instance.post(`/games/${id}/get_ticket/`, numbers);
    },
    getFreeTicket: (id, numbers) => {
      return instance.post(`/games/${id}/get_free_ticket/`, numbers);
    },
    nextGame: () => {
      return instance.get(
        `/games/?limit=1&status=0&ordering=start&date_from=${new Date().toISOString()}`
      );
    },
  },
};
