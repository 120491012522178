import React from "react";
import c from "./Circle.module.scss";
import cn from "classnames";
export const Circle = (props) => {
  const divStyle = {
    width: props.width,
    height: props.height,
    // minHeight: props.height,
    // minWidth: props.width,
    background: props.active,
    border: props.border,
  };

  return (
    <div
      className={cn(
        c.circleWrapper,
        props.smallCircle && c.smallCircle,
        props.respond && c.respond
      )}
      style={divStyle}
    >
      <div className={c.circle}>{props.children}</div>
    </div>
  );
};
Circle.defaultProps = {
  width: "104px",
  height: "104px",
  border: "none",
};
