import { Button } from "components/button/Button";
import { Circle } from "components/circle/Circle";
import { Dots } from "components/dots/Dots";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { Redirect, useHistory } from "react-router-dom";
import c from "../Furtune.module.scss";
import t from "../winningNumbers/WinningNumbers.module.scss";
import cn from "classnames";
import { setNumbers } from "store/reducers/furtune-reducer/actions";
import { displayToast } from "toast/toast";
export const GameNotStarted = ({
  ticketNumbers,
  buyTicket,
  setIsLoginOpen,
  // @ts-ignore
  freeTickets,
}) => {
  // @ts-ignore
  const isWallet = useSelector((state) => state.userReducer.user.wallet);
  const userTickets = useSelector(
    // @ts-ignore
    (state) => state.furtuneReducer.game.user_tickets
  );
  const dispatch = useDispatch();
  const [isCleared , setIsCleared ] = useState(false)

  // @ts-ignore
  const history = useHistory();
  return (
    <div>
      <div className={c.myTicket}>
        <div className={c.title}>Payment</div>
        <div className={c.cardContainer}>
          <div className={c.card}></div>
          <div className={c.title}>My Ticket </div>
          <div className={c.dotsContainer}>
            <Dots count={21} />
          </div>

          <div className={c.userNumbers}>
            {ticketNumbers.map((t, index) => {
              return (
                <div
                  className={c.circleWrapper}
                  key={` u numbers num - ${index}`}
                >
                  <Circle
                    background="unset"
                    width={"35px"}
                    height={"35px"}
                    border={"2px solid #9AE1F1"}
                  >
                    <div className={cn(c.number , "small-ball-ios")}>{t ? t : "-"}</div>
                  </Circle>
                </div>
              );
            })}
          </div>
        </div>

        {
          <>
            <div className={c.cardSubTitle}>
              <div className={c.left}>Total:</div>{" "}
              <div className={c.right}> Free</div>
            </div>
            <div className={c.buttonWrapper}>
              {userTickets.length <1 && (
                <Button
                  width="340px"
                  disabled={ticketNumbers.indexOf(false) > -1}
                  onClick={() => {
                    if (!localStorage.getItem("access")) {
                      setIsLoginOpen(true);
                    } else {
                      if (ticketNumbers.indexOf(false) === -1) {
                        if (!isWallet) {
                          buyTicket();
                          // dispatch(
                          //   setNumbers([false, false, false, false, false])
                          // );
                        } else {
                          buyTicket();
                          // dispatch(
                          //   setNumbers([false, false, false, false, false])
                          // );
                        
                        }
                      } else {
                        displayToast('Select ticket numbers');
                      }
                    }
                  }}
                >
                  Get ticket
                </Button>
              )}

              {freeTickets !== 0 && userTickets.length   >= 1 && isCleared&& (
                <Button
                  backgroundColor={"#25b5db"}
                  width="340px"
                  onClick={() => {
                    if (!localStorage.getItem("access")) {
                      setIsLoginOpen(true);
                    } else {
                      if (ticketNumbers.indexOf(false) === -1) {
                        if (!isWallet) {
                          buyTicket(true);
                        } else {
                          buyTicket(true);
                        }
                        setIsCleared(false)
                      }
                    }
                  }}
                >
                 Get bonus ticket
                </Button>
              )} {
                freeTickets !== 0 && userTickets.length >= 1 && !isCleared&& (
                <Button
                  backgroundColor={"#25b5db"}
                  width="340px"
                  onClick={() => {
                    dispatch(
                      setNumbers([false, false, false, false, false])
                    );
                    setIsCleared(true)
                  }}
                >
                  Use bonus ticket
                </Button>
              )}
            </div>
            { localStorage.getItem("access") && freeTickets !== 0&&(<div className={"flex center subtitle-yellow"}>
              You have {freeTickets} bonus tickets
            </div>)}
          </>
        }

        {userTickets.length > 0 && (
          <div className={t.myTickets}>
            <div className={t.title}>My tickets</div>
            <div className={t.tickets}>
            {userTickets.map((ticketItem , index)=>{
                    return<div key={`user ticket -game-not-stated-index- ${index}`}>
                     <div className={t.tickets__title} >{(ticketItem.status==='Regular')?('Ticket'):('Bonus ticket')} #{index + 1}</div>
              <div className={t.itemsUser}>
                {ticketItem.numbers.map((ticket, index) => {
                  return (
                    <div
                      className={cn(t.ticket, t.disabled)}
                      key={`u numbers num - ${index}`}
                    >
                      {ticket}
                    </div>
                  );
                })}
              </div>
                    </div>
                  })}              
              
             


            </div>
          </div>
        )}
      </div>
    </div>
  );
};
