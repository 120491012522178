import { Circle } from "components/circle/Circle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pickNumberAC } from "store/reducers/furtune-reducer/actions";
import c from "./PickNumberAuto.module.scss";
import cx from "classnames";
import { setTicketAdminAC } from "store/reducers/admin-reducer/actions";
import { setTicketsInGame } from "store/reducers/admin-reducer/thunk";
import { getGameThunk } from "store/reducers/furtune-reducer/thunk";
import gif1 from "../../../../asserts/gif/balls.gif";
import gif2 from "../../../../asserts/gif/balls_2.gif";
import gif3 from "../../../../asserts/gif/balls_3.gif";
import { toggleIsFetchingAC } from "store/reducers/user-reducer/actions";
export const PickNumberAuto = ({
  min,
  max,
  index,
  withAdmin,
  withCircle,
  gameId,
  border,
  disablePick,
  showGif,
  value,
  withGif,
  gifType,
  width,
}) => {
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };
  const gifts = [gif1, gif2];
  // const [loaded, setIsLoaded] = useState(false);
  const [giftSrc, setGiftSrc] = useState(gifts[getRandomInt(2)]);
  let allNumbers = useSelector(
    // @ts-ignore
    (state) => state.furtuneReducer.game.win_numbers
  );
  const pickNumberHandle = (element) => {
    if (withAdmin) {
      if (index === 0 || allNumbers[index - 1]) {
        dispatch(setTicketAdminAC(index, element));
        dispatch(setTicketsInGame());
        dispatch(getGameThunk(gameId, true));
      }
    } else {
      dispatch(pickNumberAC(index, element));
    }
  };
  const [canShowNumber, setCanShow] = useState(false);
  useEffect(() => {
    if (showGif === true) setCanShow(true);
  }, [showGif]);

  const dispatch = useDispatch();

  let numbers = [];
  for (let i = min; i <= max; i++) {
    numbers.push(i);
  }
  // if (showGif && !loaded) {
  //   dispatch(toggleIsFetchingAC(true));
  // }

  return (
    <div className={c.pickNumberWrapper}>
      <Circle border={withCircle && border} respond={true} 
        width={`${width}px`}
        height={`${width}px`}
      >
        {withCircle &&
          (showGif ? (
            <div>
              <img
                src={gifType ? gif3 : giftSrc}
                className={c.gif}
                onLoad={() => {
                  // setIsLoaded(true);
                  dispatch(toggleIsFetchingAC(false));
                }}
              />
            </div>
          ) : (
            <div className={cx(c.pickedNumber , "center-ios")}>
              {value ? value : <div className={c.numberNotSelected}>-</div>}
            </div>
          ))}
      </Circle>

      <div className={c.numbersContainer}>
        <div className={c.numbers}>
          {numbers.map((element) => {
            return (
              <div
                key={`pick-number-elements-${element}`}
                className={cx(c.number)}
                onClick={() => {
                  if (!disablePick) {
                    pickNumberHandle(element);
                  }
                }}
              >
                <Circle
                  width={"36px"}
                  height={"36px"}
                  smallCircle={true}
                  respond={true}
                  active={element === value && !showGif ? "#FFC107" : "#6490A0"}
                >
                  {element}
                </Circle>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

PickNumberAuto.defaultProps = {
  value: "-",
  withAdmin: false,
  withCircle: true,
  gameId: false,
  border: "2px solid #9AE1F1",
  disablePick: false,
  showGif: false,
  withGif: false,
  gifType: false,
  width: 104,
};
