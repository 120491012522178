import FormContext from "../../context/formContext";
import React, { useContext, useState, useRef, useEffect } from "react";
import c from "./AButton.module.scss";
import cn from "classnames";

var classNames = require("classnames/bind");
var cx = classNames.bind(c);
export const AButton = ({
  children,
  onClick,
  icon,
  round,
  className,
  fill,
  submit,
  reject,
  bigger,
  submitParam,
  onKeyDownEnter,
}) => {
  const onSubmitForm = useContext(FormContext).onSubmit;
  const onRejectForm = useContext(FormContext).onReject;
  // @ts-ignore
  const subscribe = useContext(FormContext).subscribe;
  const [isSubscribed, setIsSubscribed] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    if (!isSubscribed) {
      setIsSubscribed(true);
      if (submit) {
        subscribe({
          ref: ref,
        });
      }
    }
  }, []);
  const onClickHandle = (e) => {
    if (submit) {
      onSubmitForm(e, submitParam);
    } else if (reject) {
      onRejectForm(e);
    } else {
      onClick(e);
    }
  };
  let classNameCx = cx({
    AButton: true,
    icon: icon,
    fill: fill,
    children: children,
    round: round,
    [className]: true,
    bigger: bigger,
  });
  return (
    <button
      className={cn(classNameCx, !children && c.white)}
      onClick={onClickHandle}
      ref={ref}
    >
      {icon && <div className={c.icon}>{icon}</div>}
      <div className={c.label}>{children}</div>
    </button>
  );
};
AButton.defaultProps = {
  icon: "",
  round: false,
  submit: false,
  reject: false,
  bigger: false,
  onClick: () => {},
  onKeyDownEnter: () => {},
  fill: true,
  className: "",
  children: "",
  submitParam: "",
};
