import { Button } from "components/button/Button";
import { Input } from "components/input/Input";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordThunk } from "store/reducers/user-reducer/thunk";
import { validateEmail } from "utils/validators/validators";
import c from "./ForgotPassword.module.scss";
import cn from "classnames";
import { CForm } from "components-сommon/CForm/CForm";
export const ForgotPasswordModal = ({
  setIsForgotPasswordOpen,
  setIsLoginOpen,
  openSuccessModal,
}) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const userInpEmail = useSelector((state) => state.userReducer.userInputEmail);
  const [userEmail, setUserEmail] = useState(userInpEmail);
  const [serverError, setServerError] = useState("");
  const back = () => {
    setIsForgotPasswordOpen(false);
    setIsLoginOpen(true);
  };

  const submitEmail = () => {
    dispatch(resetPasswordThunk(userEmail, setServerError));
    openSuccessModal(true);
    setIsForgotPasswordOpen(false);
  };

  const errorHandle = (err) => {
  };
  return (
    <div className="main-layout">
      <div
        className={cn("iconClose", c.iconForgot)}
        onClick={() => {
          setIsForgotPasswordOpen(false);
        }}
      ></div>
      <div className={c.wrapper}>
        <div className={c.title}>Forgot Password?</div>
        <CForm onSubmit={submitEmail} onError={errorHandle}>
          <div className={c.item}>
            <Input
              label={"Email"}
              value={userEmail}
              onChange={setUserEmail}
              validate={validateEmail}
            />
          </div>
          <div className={c.buttonWrapper}>
            <Button
              backgroundColor="linear-gradient(270.14deg, #119490 0.1%, #16A49E 98.75%)"
              onClick={() => {
                back();
              }}
            >
              Back
            </Button>
            <Button submit={true}>Confirm</Button>
          </div>
        </CForm>
      </div>
    </div>
  );
};
