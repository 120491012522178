import { instance } from "api/axios-instance";

export const configAPI = {
  config: {
    get: () => {
      return instance.get(`config/1`);
    },
    update: (body) => {
      return instance.put(`config/1`, body);
    },
  },
};
