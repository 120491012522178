import { SET_USER, IS_ERROR, TOGGLE_IS_FETCHING, SET_USER_INP_EMAIL } from "./actions";

let initialState = {
  isFetching: false,
  isError: false,
  user: false,
  userInputEmail:'',
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case IS_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
      case SET_USER_INP_EMAIL:
      return {
        ...state,
        userInputEmail: action.payload,
      };

    default:
      return state;
  }
};
