import React from "react";
import loader from "../../asserts/loader/loader.svg";
import Modal from "react-modal";
import { useSelector } from "react-redux";

export const CLoader = () => {
  // @ts-ignore
  const isFetching = useSelector((state) => state.userReducer.isFetching);

  return (
    <Modal appElement={document.getElementById("root")} isOpen={isFetching}>
      <img src={loader} />
    </Modal>
  );
};
