import { APageName } from "components-admin/APageName/APageName";
import { APagination } from "components-admin/APagination/APagination";
import { ATable } from "components-admin/ATable/ATable";
import { ATableCol } from "components-admin/ATableCol/ATableCol";
import { ATableRow } from "components-admin/ATableRow/ATableRow";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUsersThunk } from "store/reducers/admin-reducer/thunk";
import { displayToast } from "toast/toast";
import c from "./Users.module.scss";
export const Users = () => {
  // @ts-ignore
  const { page } = useParams();
  let url = (page) => {
    return `/adm/users/${page}`;
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsersThunk(page));
  }, [page]);
  // @ts-ignore
  const users = useSelector((state) => state.adminReducer.users.results);
  // @ts-ignore
  const usersCount = useSelector((state) => state.adminReducer.users.count);
  const maxPages = Math.ceil(usersCount / 10);
  const onClickHandle = (user) => {
    navigator.clipboard.writeText(user.wallet);
    displayToast("Copied to clipboard", "bottom-left");
  };
  const usersList = users.map((u) => {
    return (
      <ATableRow>
        <ATableCol className={c.email}>
          <div>{u.email}</div>
        </ATableCol>
        <ATableCol className={c.username}>
          <div>{u.username}</div>
        </ATableCol>
        <ATableCol className={c.avatar}>
          <div>
            <img src={`http://app.bitcoinbingo.io/${u.avatar}`} />
          </div>
        </ATableCol>

        <ATableCol className={c.avatar}>
          {u.wallet ? (
            <div onClick={() => onClickHandle(u)} className={c.copyText}>
              Copy
            </div>
          ) : (
            <div className={c.noWallet}></div>
          )}
        </ATableCol>
        <ATableCol className={c.avatar}>{u.is_active ? "Y" : "N"}</ATableCol>
      </ATableRow>
    );
  });

  return (
    <div>
      <div className="container no-padding-top">
        <APageName>Users</APageName>
        <ATable>
          <ATableRow header>
            <ATableCol className={c.email}>
              <div>Email</div>
            </ATableCol>
            <ATableCol className={c.username}>
              <div>Username</div>
            </ATableCol>
            <ATableCol className={c.avatar}>
              <div>Avatar</div>
            </ATableCol>
            <ATableCol className={c.avatar}>
              <div>Wallet</div>
            </ATableCol>

            <ATableCol className={c.avatar}>
              <div>Сonfirmed</div>
            </ATableCol>
          </ATableRow>
          {usersList}
        </ATable>
        <div className={c.paginationWrapper}>
          <APagination
            max={1}
            maxPages={maxPages}
            url={url}
            pageNumber={page}
          />
        </div>
      </div>
    </div>
  );
};
