import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordThunk,
  // @ts-ignore
  resetPasswordThunk,
  setNewPasswordThunk,
} from "store/reducers/user-reducer/thunk";
import { CForm } from "components-сommon/CForm/CForm";
import classes from "../SignUp/SignUp.module.scss";
import { Input } from "components/input/Input";
import { Button } from "components/button/Button";
import c from "./ResetPassword.module.scss";
import cn from "classnames";
import Modal from "react-modal";
import { InvaildPasswordLink } from "./InvaildPasswordLink/InvaildPasswordLink";

// /auth/password/reset/{uidb64}/{token}/confirm/
export const ResetPassword = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  // @ts-ignore
  const isError = useSelector((state) => state.userReducer.isError);
  // @ts-ignore
  const email = useSelector((state) => state.userReducer.user.email);
  const [userPassword, setUserPassword] = useState("");
  const [userRepeatPassword, setUserRepeatPassword] = useState("");
  const history = useHistory();
  const [errors, setErros] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  let token = {
    uidb64: queryString.parse(location.search).uidb64,
    token: queryString.parse(location.search).token,
  };
  const sendRedirect = () => {
    history.push("/profile");
  };
  useEffect(() => {
    setIsOpen(true);
    dispatch(changePasswordThunk(token, setErros));
  }, []);
  // queryString.parse(location.search).token;
  const submitHandle = () => {
    dispatch(setNewPasswordThunk(userPassword, sendRedirect));
  };
  const errorHandle = (e) => {};

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div
        className="iconClose"
        onClick={() => {
          setIsOpen(false);
        }}
      ></div>

      {!errors ? (
        <div className={cn(c.resetWrapper, "main-layout ")}>
          <div className={c.title}>Create new password </div>

          <div className={classes.loginWrapper}>
            <CForm
              onSubmit={submitHandle}
              onError={errorHandle}
              serverErrors={isError}
            >
              <div className={classes.item}>
                <Input
                  unkey="password"
                  label={"Password*"}
                  type="password"
                  value={userPassword}
                  onChange={setUserPassword}
                  validate={[
                    (val) =>
                      (val && val.length > 0) || "This field is required",
                    (val) => val.length < 15 || "This field is too long",
                    (val) =>
                      val.length > 5 ||
                      "Ensure this field has at least 5 characters.",
                  ]}
                />
              </div>
              <div className={classes.item}>
                <Input
                  unkey="userRepeatPassword"
                  label={"Repeat password*"}
                  type="password"
                  value={userRepeatPassword}
                  onChange={setUserRepeatPassword}
                  validate={[
                    (val) =>
                      (val && val.length > 0) || "This field is required",
                    (val) =>
                      (val && val === userPassword) || "Passwords don’t match",
                  ]}
                />
              </div>

              <div className={classes.buttonNextWrapper}></div>
              <Button submit={true} width={"100%"}>
                <div className={classes.buttonNext__title}>Reset password </div>
              </Button>
            </CForm>
          </div>
        </div>
      ) : (
        <InvaildPasswordLink setIsOpen={setIsOpen} />
      )}
    </Modal>
  );
};
