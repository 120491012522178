import { Tickets } from "components/ticketsList/Tickets";
// @ts-ignore
import React, { useEffect, useState } from "react";
// @ts-ignore
import { useDispatch, useSelector } from "react-redux";
import c from "./WinningNumbers.module.scss";
import cn from "classnames";
import { FinishedCard } from "../finished/finishedCard/FinishedCard";
import { GameWinners } from "pages/Main/gameWinners/GameWinners";
// @ts-ignore
import {
  setNumbers,
  setWinNumbersAC,
} from "store/reducers/furtune-reducer/actions";
// @ts-ignore
import { getGameThunk } from "store/reducers/furtune-reducer/thunk";

export const WinningNumbers = ({ status, winNumbers }) => {
  const hasTickets = useSelector(
    // @ts-ignore
    (state) => state.furtuneReducer.game.user_has_tickets
  );
  const tickets_used = useSelector(
    // @ts-ignore
    (state) => state.furtuneReducer.game.tickets_used
  );

  // @ts-ignore
  const winners = useSelector((state) => state.furtuneReducer.game.winners);

  const isUserWin = (number) => {
    if (winNumbers) {
      if (winNumbers.indexOf(number) >= 0) {
        return false;
      } else {
        return true;
      }
    }
  };
  let numbers = [];

  const userTickets = useSelector(
    // @ts-ignore
    (state) => state.furtuneReducer.game.user_tickets
  );

  if (winNumbers) {
    let notSelectedWinNumbersCount;
    let notSelected = [];

    if (winNumbers.length <= 5) {
      notSelectedWinNumbersCount = 6 - winNumbers.length;
    }

    for (let i = 0; i < notSelectedWinNumbersCount; i++) {
      notSelected.push(<div className={cn(c.ticket, c.disabled)} key={`ticket${i}`}>-</div>);
    }
    numbers = winNumbers.map((n, i) => {
      return (
        <div
          className={cn(c.ticket, i === 5 ? c.last : "")}
          key={`winningTicket - ${i}`}
        >
          {n}
        </div>
      );
    });
    numbers = numbers.concat(...notSelected);
  }

  return (
    <>
      {status !== 0 && (
        <div className={c.winningsNumbersWrapper}>
          <div className={c.left}>
            <div className={c.title}>Winning numbers</div>
            <div className={c.subtitle}> {tickets_used} tickets used</div>
            <div className={c.tickets}>
              <div className={c.items}>{numbers ? numbers : []}</div>
            </div>

            {hasTickets && (
              <div className={c.myTickets}>
                <div className={c.title}>My tickets</div>
                <div className={c.tickets}>
                  {userTickets.map((ticketItem , index)=>{
                    return<>
                     <div className={c.tickets__title} key={index}>Ticket #{index  +1 }</div>
                      <div className={c.itemsUser}>
                        {ticketItem.numbers.map((t, i) => {
                          return (
                            <div
                              className={cn(
                                c.ticket,
                                isUserWin(t) ? c.noBorder : ""
                              )}
                              key={`winningNumbers mapping key-${index} rsKey-${i} `}
                            >
                              {t}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  
                  })}
                 
                </div>
              </div>
            )}

            {userTickets.length > 0 &&
              status === 2 &&
              userTickets[0].amount && (
                <FinishedCard
                  winningAmount={userTickets[0].amount}
                  tickets={userTickets[0].numbers}
                />
              )}
          </div>
          <div className={c.right}>
            {status === 2 && winners.length > 0 && (
              <GameWinners
                title={"Winners names"}
                page={"winningNumbers"}
                items={winners}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
