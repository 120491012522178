import React, { useState } from "react";
import Modal from "react-modal";
import c from "./ConfirmModal.module.scss";
import cn from "classnames";
import { Button } from "components/button/Button";
import { useDispatch } from "react-redux";
import { resendEmailThunk } from "store/reducers/user-reducer/thunk";
export const ConfirmModal = ({
  setIsConfirmModalOpen,
  isConfirmModalOpen,
  email,
}) => {
  const dispatch = useDispatch();
  const [afterResend, setAfterResend] = useState(false);
  const resendEmail = () => {
    dispatch(resendEmailThunk(email, setAfterResend));
  };
  return (
    <div className={c.confrimWrapper}>
      <Modal
        appElement={document.getElementById("root")}
        onRequestClose={() => setIsConfirmModalOpen(false)}
        isOpen={isConfirmModalOpen}
      >
        <div
          className="iconClose icon-close-confirmation"
          onClick={() => {
            setIsConfirmModalOpen(false);
          }}
        ></div>

        <div className={cn(c.layout, "main-layout")}>
          {!afterResend ? (
            <>
              <div>
                Thank you for registering at Bitcoin Bingo! <br /> To confirm
                your account please follow the instructions in the email we have
                just sent you.
              </div>
              <div className={c.actions}>
                <div
                  className={c.close}
                  onClick={() => {
                    setIsConfirmModalOpen(false);
                  }}
                >
                  Ok
                </div>
                <div
                  className={c.close}
                  onClick={() => {
                    resendEmail();
                  }}
                >
                  <div>Resend email confirmation</div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={c.textCenter}>
                To confirm your account please follow the instructions in the
                email we have just sent you
              </div>
              <div className={c.successResendButton}>
                <Button
                  width={"227px"}
                  height={"60px"}
                  onClick={() => {
                    setIsConfirmModalOpen(false);
                  }}
                >
                  OK
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
