const {
  PICK_NUMBER,
  SET_NUMBERS,
  SET_GAME,
  SET_WINNING_NUMBERS,
} = require("./actions");

let initialState = {
  tickets: [false, false, false, false, false],
  game: false,
  winningNumbers: [],
};

export const furtuneReducer = (state = initialState, action) => {
  switch (action.type) {
    case PICK_NUMBER:
      return {
        ...state,
        tickets: state.tickets.map((ticket, index) => {
          if (index === action.index) return action.number;
          else {
            return ticket;
          }
        }),
      };
    case SET_GAME:
      return {
        ...state,
        game: action.payload,
        winningNumbers: action.payload.win_numbers,
      };
    case SET_NUMBERS:
      return {
        ...state,
        tickets: action.payload,
      };
    case SET_WINNING_NUMBERS:
      return {
        ...state,
        winningNumbers: action.payload,
      };
    default:
      return state;
  }
};
