import React from "react";
import { useSelector } from "react-redux";
import c from "./GameWinners.module.scss";
import cn from "classnames";
import { formatNumber } from "utils/formatters/numberFormatter";
export const GameWinners = (props) => {
  const statistic = useSelector((state) => {});
  let items = props.items.map((item, i) => {
    return (
      <div key={`game-winners-items-${i}`}>
        <div className={c.item}>
          <div className={c.left}>
            <div className={c.index}>{i + 1}</div>

            {item.user.avatar && (
              <img
                src={"https://app.bitcoinbingo.io" + item.user.avatar}
                className={c.image}
              />
            )}

            <div className={c.info}>{item.user.username}</div>
          </div>
          <div className={c.right}>
            {props.page === "admin" ? (
              <div className="flex">
                <div className={c.amount}>
                  {formatNumber(item.guessed_numbers)} numbers
                </div>
              </div>
            ) : (
              <div className="flex">
                <div className={c.amount}>${formatNumber(item.amount)}</div>
                <div className={c.icon}>
                  <div className="icon-diamond"></div>
                </div>
              </div>
            )}
          </div>
        </div>{" "}
      </div>
    );
  });
  return (
    <div
      className={cn(
        c.gameWinners,
        props.page === "winningNumbers" ? c.winnersPage : "",
        props.page === "admin" ? c.adminPage : "",
      )}
    >
      {props.page !== "admin" && <div className={c.title}>{props.title}</div>}
      <div className={c.items}>{items}</div>
    </div>
  );
};
GameWinners.defaultProps = {
  items: [],
};
