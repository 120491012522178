import { Button } from "components/button/Button";
import { Tickets } from "components/ticketsList/Tickets";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  // @ts-ignore
  getGamesThunk,
  getNextGameThunk,
} from "store/reducers/games-reducer/thunk";
import { dateFormatterGame } from "utils/formatters/dateFormatter";
import { formatNumber } from "utils/formatters/numberFormatter";
import c from "./NextGame.module.scss";
import cn from "classnames";
export const NextGame = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNextGameThunk());
  }, []);
  // @ts-ignore
  const data = useSelector((state) => state.gamesReducer.nextGame);
  const userTickets = useSelector((state) =>
    // @ts-ignore
    state.gamesReducer.nextGame ? state.gamesReducer.nextGame.user_tickets : []
  );

  return (
    <div className={c.nextGameWrapper}>
      <div>
        <div className={c.head}>
          <div>Next game</div>
        </div>
        {data && (
          <div className={c.aboutGame}>
            <div className={c.date}>{dateFormatterGame(data.start)}</div>
            <div className={c.amount}>${data && formatNumber(data.amount)}</div>

            <div className={c.buttonWrapper}>
              <NavLink to={`/games/${data.id}`}>
                <Button onClick={() => {}}>View</Button>
              </NavLink>
            </div>
          </div>
        )}
      </div>

      <div className={cn(c.myTikcets, "mobile-hidden")}>
        <div className={c.title}>My tickets for this game</div>
        {userTickets.length > 0 && <Tickets tickets={userTickets} />}
      </div>
    </div>
  );
};
