import React from "react";
import { formatNumber } from "utils/formatters/numberFormatter";
import c from "./FinishedCard.module.scss";
export const FinishedCard = ({ winningAmount, tickets }) => {
  return (
    <div className={c.cardContainer}>
      <div className={c.sum}> You won ${formatNumber(winningAmount)}</div>
      <div className={c.tickets}>
        {tickets.map((t, i) => {
          return (
            <div className={c.ticket} key={`finished card-item-${i}`}>
              {t}
            </div>
          );
        })}
      </div>
    </div>
  );
};
