import classNames from "classnames";
import { CForm } from "components-сommon/CForm/CForm";
import { Button } from "components/button/Button";
import { Input } from "components/input/Input";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserInpEmailAC } from "store/reducers/user-reducer/actions";
import {
  resendEmailThunk,
  signInThunk,
  
} from "store/reducers/user-reducer/thunk";
import c from "../SignUp/SignUp.module.scss";
import l from "./Login.module.scss";
export const Login = (props) => {
  let wrapperClasses = classNames({
    ["main-layout"]: "main-layout",
  });
  const showResetButtonText =
    "Your account is not verified. To verify your account please follow the instruction in the email we sent you after sign up";
  const dispatch = useDispatch();
  // @ts-ignore
  const isFetching = useSelector((state) => state.userReducer.isFetching);

  // @ts-ignore
  const userInpEmail = useSelector((state) => state.userReducer.userInputEmail);
  const history = useHistory();
  // const isError = useSelector((state) => state.userReducer.isError);
  const [isError, setIsError] = useState(false);
  const closeModal = () => {
    props.setIsOpen(false);
  };
  const sendRedirectIfAdmin = (role) => {
    if (role) {
      history.push("/adm/1");
    }
  };
  const openResendPasswordModal = ()=>{
    props.setIsOpen(false);
    props.setIsForgotPasswordOpen(true);
  }
  const onInputEmail = (e)=>{
    dispatch(setUserInpEmailAC(e))
  } 
  let submitHandle = () => {
    dispatch(
      signInThunk(
        {
          email: userInpEmail,
          password: userPassword,
        },
        closeModal,
        setIsError,
        sendRedirectIfAdmin,
        props.openBTCModal
      )
    );
    // isError && props.setIsOpen(false);
  };
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let validateEmail = [
    (val) => (val && val.length > 0) || "This field is required",
    (val) => val.length < 40 || "This field is too long",
    (val) => emailRE.test(val) != false || "Email is not valid",
  ];
  let errorHandle = () => {};
  const isEmail = () => {
    if (emailRE.test(userInpEmail)) {
      return userInpEmail;
    } else {
      return false;
    }
  };
  return (
    <div className={wrapperClasses}>
      <div className="iconClose" onClick={() => props.setIsOpen(false)}></div>
      <div className={c.loginWrapper}>
        <div className={c.loginWrapper__pageTitle}>Login</div>

        <CForm
          onSubmit={submitHandle}
          onError={errorHandle}
          serverErrors={isError}
        >
          <div className={c.item}>
            <Input
              label={"Email"}
              value={userInpEmail}
              onChange={onInputEmail}
              validate={validateEmail}
            />
          </div>

          <div className={c.item}>
            <Input
              handle={isEmail}
              showButton ={true}
              openModal ={openResendPasswordModal}
              label={"Password"}
              type="password"
              value={userPassword}
              onChange={setUserPassword}
              validate={[
                (val) => (val && val.length > 0) || "This field is required",
                (val) => val.length < 30 || "This field is too long",
              ]}
             
            />
          </div>
          {isError ? (
            <div className={c.red}>
              {
                // @ts-ignore
                isError.detail === showResetButtonText ? (
                  <div>
                    <div>{showResetButtonText}</div>
                    <span>
                      <div
                        className={l.forgotPasswordWrapper}
                        onClick={() => {
                          dispatch(resendEmailThunk(userInpEmail, closeModal));
                        }}
                      >
                        <div className={l.forgotPassword}>Resend email</div>
                      </div>
                    </span>
                  </div>
                ) : (
                  // @ts-ignore
                  isError.detail
                )
              }
            </div>
          ) : (
            ""
          )}

          <div className={c.buttonNextWrapper}>
            <Button submit={true} width={"100%"}>
              Login
            </Button>
            {/* <div className={c.haveAccount}>
              Don't have an account?{" "}
              <div
                className={c.underline}
                onClick={() => {
                  closeModal();
                  props.openRegister();
                }}
              >
                Sign up
              </div>
            </div> */}
          </div>
        </CForm>
      </div>
    </div>
  );
};
