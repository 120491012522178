import { Input } from "components/input/Input";
import React, { useState } from "react";
import { resetPasswordThunk } from "store/reducers/user-reducer/thunk";
import cn from "classnames";
import c from "../ResetPassword.module.scss";
import classes from "../../SignUp/SignUp.module.scss";
import { Button } from "components/button/Button";
import { useDispatch } from "react-redux";
import { CForm } from "components-сommon/CForm/CForm";

export const InvaildPasswordLink = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [userEmail, setUserEmail] = useState("");
  const submit = () => {
    dispatch(resetPasswordThunk(userEmail, setIsOpen));
  };
  const errorHandle = () => {
    console.log("error");
  };
  const isError = () => {
    console.log("err 2 ");
  };
  let validateEmail = [
    (val) => (val && val.length > 0) || "This field is required",
    (val) => val.length < 40 || "This field is too long",
    (val) => emailRE.test(val) != false || "Email is not valid",
  ];
  return (
    <div className={cn(c.resetWrapper, "main-layout ")}>
      <div className={c.title}> Invaild reset password link </div>
      <CForm onSubmit={submit} onError={errorHandle} serverErrors={isError}>
        <div className={c.userEmailWrapper}>
          <div className={classes.item}>
            <Input
              label={"Email"}
              value={userEmail}
              onChange={setUserEmail}
              validate={validateEmail}
            />
          </div>
        </div>

        <div
          className={cn(
            classes.buttonNextWrapper,
            "flex justify-center",
            c.mt41
          )}
        >
          <Button submit={true}>Resend email</Button>
        </div>
      </CForm>
    </div>
  );
};
