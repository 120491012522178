import React, { useState } from "react";
import styles from "./APagination.module.scss";
import { NavLink } from "react-router-dom";
var classNames = require("classnames/bind");
var cx = classNames.bind(styles);

const Page = ({ number, selected, setPage, href }) => {
  let classNameCX = cx({
    pageWrapper: true,
    selected: number === selected,
  });
  return (
    <NavLink
      to={href}
      className={classNameCX}
      onClick={() => {
        setPage(number);
      }}
    >
      <span className={styles.page}>{number}</span>
    </NavLink>
  );
};
export const APagination = ({ maxPages, pageNumber, max, url }) => {
  const currentPage = Number(pageNumber);
  let [portionNumber, setPortionNumber] = useState(Math.ceil(pageNumber / max));
  let pages = [];
  let setCurrentPageAndPortion = (page) => {
    setPortionNumber(Math.ceil(page / max));
  };
  for (let i = 0; i < maxPages - 1; i++) {
    pages.push(i);
  }
  let portionCount = Math.ceil(maxPages / max);
  let leftPortionNumber;
  if (max < 3) {
    leftPortionNumber =
      (portionNumber - 2) * max < 0 ? 0 : (portionNumber - 2) * max;
  }
  if (max >= 3) {
    leftPortionNumber = (portionNumber - 1) * max - 3;
  }

  let rightPortionNumber = portionNumber * max;

  if (max >= 3) {
    if (portionNumber === 1) {
      setPortionNumber(2);
    }
    if (portionNumber === 2) {
      leftPortionNumber = 0;
    }
    if (portionNumber > 2) leftPortionNumber = leftPortionNumber + 2;

    if (portionNumber === portionCount) leftPortionNumber -= 3;
    if (portionNumber === portionCount - 1) setPortionNumber(portionCount);
  }

  if (max < 3) {
    if (portionNumber + 2 >= portionCount) {
      leftPortionNumber = portionCount - 4;
      rightPortionNumber += 1;
      portionNumber = portionCount;
    }
    if (rightPortionNumber <= 3) {
      rightPortionNumber = 4;
    }
    if (leftPortionNumber <= 2) {
      leftPortionNumber = 0;
    }
  }

  return (
    <div className={styles.pagination}>
      {currentPage !== 1 && (
        <NavLink
          to={url(currentPage == 1 ? 1 : currentPage - 1)}
          className={`${styles.pageWrapper} ${styles.pagePrew}`}
          onClick={() => {
            if (currentPage !== 1) setCurrentPageAndPortion(currentPage - 1);
          }}
        >
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            xmlns="http://www.w3.org/2000/svg"
            fill="#797E84"
          >
            <path d="M7.41016 10.59L2.83016 6L7.41016 1.41L6.00016 0L0.000156403 6L6.00016 12L7.41016 10.59Z" />
          </svg>
        </NavLink>
      )}

      {leftPortionNumber !== 0 && (
        <>
          <Page
            number={1}
            href={url(1)}
            selected={currentPage}
            setPage={setCurrentPageAndPortion}
          />
          <NavLink
            to={url(leftPortionNumber)}
            className={styles.showMore}
            onClick={() => {
              setCurrentPageAndPortion(leftPortionNumber);
            }}
          >
            <span>...</span>
          </NavLink>
        </>
      )}
      {pages
        .filter(
          (item) => item >= leftPortionNumber && item <= rightPortionNumber
        )
        .map((element) => {
          return (
            <Page
              key={element + 1}
              number={element + 1}
              href={url(element + 1)}
              selected={currentPage}
              setPage={setCurrentPageAndPortion}
            />
          );
        })}
      {portionNumber !== portionCount && (
        <NavLink
          to={url(rightPortionNumber + 2)}
          className={styles.showMore}
          onClick={() => {
            setCurrentPageAndPortion(rightPortionNumber + 2);
          }}
        >
          <span>...</span>
        </NavLink>
      )}
      <Page
        number={maxPages}
        href={url(maxPages)}
        selected={currentPage}
        setPage={setCurrentPageAndPortion}
      />
      {currentPage !== maxPages && (
        <NavLink
          className={`${styles.pageWrapper} ${styles.pageNext}`}
          onClick={() => {
            if (currentPage !== maxPages + 1)
              setCurrentPageAndPortion(currentPage + 1);
          }}
          to={url(
            currentPage === maxPages + 1 ? maxPages + 1 : currentPage + 1
          )}
        >
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            xmlns="http://www.w3.org/2000/svg"
            fill="#797E84"
          >
            <path d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z" />
          </svg>
        </NavLink>
      )}
    </div>
  );
};
APagination.defaultProps = {
  pageNumber: 1,
};
