import { widthAuthRedirect } from "hoc/withAuthRedirect";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/reducers/user-reducer/thunk";
import { NextGame } from "./nextGame/NextGame";
import c from "./Profile.module.scss";
import { Tabs } from "./tabs/Tabs";
import cn from "classnames";
const Profile = () => {
  // @ts-ignore
  const user = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  let signOut = () => {
    dispatch(logout());
  };
  return (
    <div className={c.profileWrapper}>
      <div className={cn(c.ticketsData, "main-layout height100")}>
        <div className={c.profile}>
          <div className={c.profile__info}>
            <div className={c.content}>
              <div className={c.imageWrapper}>
                {user.avatar && (
                  <img src={`http://app.bitcoinbingo.io/${user.avatar}`} />
                )}
              </div>

              <div className={c.userData}>
                <div className={c.userName}> {user.username}</div>
                <div
                  className={cn(c.signoutButton, "desktop-hidden --flex")}
                  onClick={signOut}
                >
                  Sign out
                </div>
              </div>
              <div
                className={cn(c.signoutButton, "tablet-hidden mobile-hidden")}
                onClick={signOut}
              >
                Sign out
              </div>
            </div>
          </div>
          <Tabs />
        </div>
      </div>
      <div className={c.gameReminderWrapper}>
        <div className={cn(c.ticketsLayout, "second-layout")}>
          <NextGame />
        </div>
      </div>
    </div>
  );
};
export default widthAuthRedirect(Profile);
