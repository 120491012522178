import { Button } from "components/button/Button";
import React, { useEffect } from "react";
import classes from "./GameReminder.module.scss";
import ballsImage from "../../../asserts/images/GameReminder.png";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "utils/formatters/numberFormatter";
import { dateFormatterGame } from "utils/formatters/dateFormatter";
import { getGamesThunk } from "store/reducers/games-reducer/thunk";
export const GameReminder = () => {
  const dispatch = useDispatch();

  // const user = useSelector((state) => state.userReducer.user);
  const data = useSelector((state) => state.gamesReducer.nextGame);
  return (
    <div className={classes.gameReminder}>
      {data && (
        <div className={classes.info}>
          <div className={classes.reminder}>
            Next Game on {dateFormatterGame(data.start)}
            {/* June, 12 at 12:00pm PST */}
          </div>
          <div className={classes.giftWrapper}>
            <div className={classes.gift}>
              ${data && formatNumber(data.amount)}
            </div>
          </div>

          <NavLink to={`/games/${data.id}`}>
            <Button width="270px" border onClick={() => {}}>
              Play now
            </Button>
          </NavLink>
        </div>
      )}

      <div className={classes.image}>
        <img src={ballsImage} alt="" />
      </div>
    </div>
  );
};
