import FormContext, { FieldContext } from "../../context/formContext";
import React, { ReactElement, MouseEvent, useState } from "react";
import cn from "classnames";
export const CForm = ({
  children,
  onSubmit,
  onReject,
  onError,
  serverErrors,
  respond,
}) => {
  const [errorsList, setErrorsList] = useState([]);
  const [subscribeList, setSubscribeList] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);
  const onNextHandle = (e) => {
    let index = subscribeList
      .map((el) => {
        if (el.ref) {
          return el.ref.current;
        }
        return false;
      })
      .indexOf(e.target);
    const next = subscribeList[index + 1];
    if (index + 1 == subscribeList.length) {
    }
    if (next) {
      const nexEl = next.ref.current;
      nexEl.focus();
    }
  };
  const onSubmitHandle = (e, submitParam) => {
    setIsSubmited(true);
    if (errorsList.length > 0) {
      onError(errorsList);
    } else {
      onSubmit(e, submitParam);
    }
    submitFields();
  };
  const submitFields = () => {
    for (let i = 0; i < subscribeList.length; i++) {
      const el = subscribeList[i];
      if (el.onSubmit) {
        el.onSubmit();
      }
    }
  };
  const onErrorHandle = (error) => {
    let index = errorsList
      .map((el) => {
        return el.fieldName;
      })
      .indexOf(error.fieldName);
    if (index > -1) {
      if (error.error == "") {
        errorsList.splice(index, 1);
      } else {
        errorsList[index].error = error.error;
      }
    } else {
      if (error.error) {
        errorsList.push(error);
      }
    }
  };
  const subscribeHandle = (subscribeData) => {
    subscribeList.push(subscribeData);
  };
  return (
    <div
      className={cn(
        "form",
        respond === "profile" && "respond-mobile-form-profile-page"
      )}
    >
      <FormContext.Provider
        value={{
          onSubmit: onSubmitHandle,
          onReject,
          subscribe: subscribeHandle,
        }}
      >
        <FieldContext.Provider
          value={{
            onError: onErrorHandle,
            subscribe: subscribeHandle,
            serverErrors: serverErrors,
            isSubmited: isSubmited,
            onNext: onNextHandle,
          }}
        >
          {children}
        </FieldContext.Provider>
      </FormContext.Provider>
    </div>
  );
};
CForm.defaultProps = {
  onReject: () => {},
  serverErrors: {},
  className: "",
  respond: "",
};
