import { APagination } from "components-admin/APagination/APagination";
import { ATable } from "components-admin/ATable/ATable";
import { ATableCol } from "components-admin/ATableCol/ATableCol";
import { ATableRow } from "components-admin/ATableRow/ATableRow";
import { APageName } from "components-admin/APageName/APageName";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import c from "./Winners.module.scss";
import { AButton } from "components-admin/AButton/AButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getWinnersThunk,
  payWinnersThunk,
} from "store/reducers/admin-reducer/thunk";
import {
  dateFormatterGame,
  dateFormatterProfile,
  dateFormatterWinners,
} from "utils/formatters/dateFormatter";
import { displayToast } from "toast/toast";
var classNames = require("classnames/bind");
var cx = classNames.bind(c);
export const Winners = () => {
  // @ts-ignore
  const { page } = useParams();
  let url = (page) => {
    return `/adm/winners/${page}`;
  };
  let classNamePaginationCX = cx({
    "flex justify-end": true,
    PaginationContainer: true,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWinnersThunk());
  }, []);
  // @ts-ignore
  const winners = useSelector((state) => state.adminReducer.winners);
  // @ts-ignore
  const winnersCount = useSelector((state) => state.adminReducer.winnersCount);
  const maxPages = Math.ceil(winnersCount / 10);

  const winnersList = winners.map((w) => {
    return (
      <ATableRow>
        <ATableCol className={c.date}>
          {dateFormatterWinners(w.game_date)}
        </ATableCol>
        <ATableCol className={c.winner}>{w.user.username}</ATableCol>
        <ATableCol className={c.email}>{w.user.email}</ATableCol>
        <ATableCol className={c.amount}>{w.amount}</ATableCol>
        <ATableCol className={c.wallet}>
          {w.user.wallet ? (
            <div onClick={() => onClickHandle(w.user)} className={c.copyText}>
              Copy
            </div>
          ) : (
            <div className={c.noWallet}></div>
          )}
        </ATableCol>
        <ATableCol className={c.status}>
          {w.is_paid ? "Paid" : "Unpaid"}
        </ATableCol>
        <ATableCol className={c.actions}>
          {!w.is_paid && <AButton onClick={() => onPayHandle(w)}>Pay</AButton>}
        </ATableCol>
      </ATableRow>
    );
  });

  const onPayHandle = (user) => {
    dispatch(payWinnersThunk(user.id));
    dispatch(getWinnersThunk());
  };
  const onClickHandle = (user) => {
    navigator.clipboard.writeText(user.wallet);
    displayToast("Copied to clipboard", "bottom-left");
  };

  return (
    <div className={c.Winners}>
      <div className="container no-padding-top">
        <APageName>Winners</APageName>
        <ATable>
          <ATableRow header>
            <ATableCol className={c.date}>Game date</ATableCol>
            <ATableCol className={c.winner}>Winner name</ATableCol>
            <ATableCol className={c.email}>Email</ATableCol>
            <ATableCol className={c.amount}>Amount of winnings</ATableCol>
            <ATableCol className={c.wallet}>Bitcoin wallet</ATableCol>
            <ATableCol className={c.status}>Status</ATableCol>
            <ATableCol className={c.actions}>Actions</ATableCol>
          </ATableRow>
          {winnersList}
        </ATable>
        <div className={classNamePaginationCX}>
          {maxPages > 1 && (
            <APagination
              max={1}
              maxPages={maxPages}
              url={url}
              pageNumber={page}
            />
          )}
        </div>
      </div>
    </div>
  );
};
