export const TOGGLE_IS_FETCHING = "TOGGLE_IS_FETCHING";
export const IS_ERROR = "IS_ERROR";
export const SET_USER = "GET_USER";
export const SET_USER_INP_EMAIL = "SET_USER_INP_EMAIL";
export const toggleIsFetchingAC = (payload) => {
  return { type: TOGGLE_IS_FETCHING, payload };
};
export const IsErrorAC = (payload) => {
  return { type: IS_ERROR, payload };
};
export const setUserAC = (payload) => {
  return { type: SET_USER, payload };
};export const setUserInpEmailAC = (payload) => {
  return { type: SET_USER_INP_EMAIL, payload };
};
