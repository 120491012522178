import { instance } from "api/axios-instance";

export const usersAPI = {
  users: {
    getList: (page) => {
      const limit = 10;
      return instance.get(`games/`, {
        params: {
          limit: limit,
          offset: Math.ceil((page - 1) * limit),
        },
      });
    },
    getUsers: (page) => {
      const limit = 10;
      return instance.get(`users/`, {
        params: {
          limit: limit,
          offset: Math.ceil((page - 1) * limit),
        },
      });
    },
  },
};
