import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

let mapStatePropsToRedirect = (state) => ({ isAuth: state.userReducer.user });

export const widthAuthRedirect = (Component) => {
  class RedirectComponent extends React.Component {
    render() {
      if (!localStorage.getItem("access")) return <Redirect to="/" />;

      return <Component {...this.props} />;
    }
  }

  return connect(mapStatePropsToRedirect)(RedirectComponent);
};
