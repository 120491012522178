import React, { ReactElement } from "react";
import styles from "./Field.module.scss";
var classNames = require("classnames/bind");
var cx = classNames.bind(styles);

export const Field = ({ children, error, label, touched, isSubmited }) => {
  let className = cx({
    field: true,
  });
  return (
    <div className={className}>
      {/* {isSubmited.toString()} */}
      {label && <div className={styles.label}>{label}</div>}
      <div
        className={cx(
          styles.fieldContainer,
          error && touched && isSubmited && styles.inputError
        )}
      >
        {children}
      </div>

      {error && touched && isSubmited && (
        <div className={styles.error}>{error}</div>
      )}
    </div>
  );
};
