import { Button } from "components/button/Button";
import { Input } from "components/input/Input";
import React, { useState } from "react";
import classes from "../SignUp.module.scss";

export const SecondStep = () => {
  const [bitcoinWallet, setBitcoinWallet] = useState("");
  return (
    <div>
      <div className={classes.loginWrapper__pageTitle}>BTC Wallet address</div>

      <div className={classes.item}>
        <div className={classes.title}>Your BTC Wallet address</div>
        <Input value={bitcoinWallet} onChange={setBitcoinWallet} />
        <div className={classes.subtitle}>
          We will send your prize to this Bitcoin wallet. <br />
          Don't have a wallet?
          <span className={classes.yellow}> Create one here</span>
        </div>
      </div>

      <Button
        submit={true}
        // @ts-ignore
        style={{ background: "rgba(64, 64, 64, 1)", color: "white" }}
      >
        Sign up
      </Button>
    </div>
  );
};
