import React, { useState, useEffect, useRef } from "react";
import c from "./BuyTicket.module.scss";
import { PickNumber } from "./pickNumber/PickNumber";
import cn from "classnames";
import { useSelector } from "react-redux";
export const BuyTicket = ({ freeTicketsCount }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(104)
  const { all_game_tickets, user_has_tickets } = useSelector(
    // @ts-ignore
    (state) => state.furtuneReducer.game
  );
  
  useEffect(() => {
    function resizeHandle() {
      if (window.innerWidth>767) {
        setWidth(ref.current.offsetWidth/8)
      }
      else {
        setWidth(104)
      }
      // setWidth(ref.current.offsetWidth/8)
    }
    resizeHandle()
    window.addEventListener("resize", resizeHandle);
    return () => {
      window.removeEventListener("resize", resizeHandle);
    }
  }, [])
  return (
    <div className={c.buyTicketWrapper} ref={ref}>
      <div className={c.buyTicketWrapper__text}>Buy ticket</div>
      <div className={c.buyTicketWrapper__numberPicker}>
        <div className={c.pickerHeader}>
          <div className={c.left}>
            <div className={c.title}>Choose your numbers</div>
            {/* <div className={c.subtitle}>
              {" "}
              {all_game_tickets.length} tickets used
            </div> */}
          </div>
          <div className={c.right}>
            <div className={c.title}>
              {/* <div className="how-to-play-icon"></div>
              <div className={c.text}>How to play?</div> */}
            </div>
          </div>
        </div>

        <div className={c.pickers}>
          <PickNumber min={1} max={7} index={0} width={width} />
          <div className={c.circles} style={{paddingTop: `${width/2-2}px`}}>
            <div className={c.circlesIcon}></div>
          </div>
          <PickNumber min={8} max={14} index={1} width={width} />
          <div className={cn(c.circles, "mobile-hidden")} style={{paddingTop: `${width/2-2}px`}}>
            <div className={c.circlesIcon}></div>
          </div>
          <PickNumber min={15} max={21} index={2} width={width} />
          <div className={c.circles} style={{paddingTop: `${width/2-2}px`}}>
            <div className={c.circlesIcon}></div>
          </div>
          <PickNumber min={22} max={28} index={3} width={width} />
          <div className={c.circles} style={{paddingTop: `${width/2-2}px`}}>
            <div className={cn(c.circlesIcon, "mobile-hidden")}></div>
          </div>
          <PickNumber min={29} max={35} index={4} width={width} />
        </div>
      </div>
    </div>
  );
};
