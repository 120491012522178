import React, { ReactElement } from "react";
import styles from "./AField.module.scss";
var classNames = require("classnames/bind");
var cx = classNames.bind(styles);

export const AField = ({
  children,
  error,
  label,
  touched,
  icon,
  className,
  onClickIcon,
  onClick,
}) => {
  let classNameCx = cx({
    Afield: true,
    icon: icon,
    [className]: true,
  });

  return (
    <div className={classNameCx} onClick={onClick}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.fieldContainer}>
        <div className={styles.fieldIcon} onClick={onClickIcon}>
          {icon}
        </div>
        <div className={classNames(styles.children, "flex")}>{children}</div>
      </div>
      {error && touched && <div className={styles.error}>{error}</div>}
    </div>
  );
};

AField.defaultProps = {
  icon: "",
  className: "",
  onClickIcon: () => {},
  onClick: () => {},
};
