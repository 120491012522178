// @ts-ignore
import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./ADateTime.module.scss";
import { AField } from "components-admin/AField/AField";
import { FieldContext } from "../../context/formContext";
import { generateId } from "hooks/global";
import { CMenu } from "components-сommon/СMenu/СMenu";
import { dateFormatterGame } from "utils/formatters/dateFormatter";
import TimePicker from "react-times";
import cn from "classnames";
import "react-times/css/material/default.css";
// or you can use classic theme
import "react-times/css/classic/default.css";
import ACalendar from "pages-admin/Games/components/aCalendar/ACalendar";
import moment from "moment";
export const ADateTime = ({
  value,
  onChange,
  validate,
  label,
  type,
  error,
  unkey,
  icon,
  className,
  hideOnScroll,
  fixOnScreen,
  disabled,
}) => {
  let currentDate = new Date();
  currentDate.setDate(new Date().getDate() + 1);
  currentDate.setMinutes(0);
  const [date, setDate] = React.useState(currentDate);
  const [merediem, setMerediem] = useState("");
  const [inpVal, setInpVal] = useState(currentDate);
  // const [inpTimeVal, setInpTimeVal] = useState("");
  const [valFromPicker, setValFromPicker] = useState("");
  useEffect(() => {
let date = new Date(value);
let hours = date.getHours();
let minutes = date.getMinutes();
if(minutes !== 0 && minutes !== 30){
  minutes = 0
}
if(hours> 12){
  setValFromPicker(`${date.getHours()-12 + ":" + `${minutes ? minutes : "00"} PM` }`)
}
else{
  setValFromPicker(`${date.getHours() + ":" + `${minutes ? minutes : "00"} AM` }`)

}
  } , [value])
  const fillInputHandle = (ampt) => {
    // @ts-ignore
    let d = dateFormatterGame(date, false, merediem);

    // @ts-ignore
    setInpVal(d);
  };

  let onBlurTimeHandle = () => {
    if (valFromPicker) {
      var dateArr = valFromPicker.split(":");
      var merediem = valFromPicker.split(" ")[1];

      setMerediem(merediem);
      if (dateArr.length === 2) {
        // let hour = Number(dateArr[0]);
        let hour = merediem === "AM" ? Number(dateArr[0]) : Number(dateArr[0]) +12;
        let minute = Number(dateArr[1].split(" ")[0]);
        date.setHours(hour);
        date.setMinutes(minute);
        // date.setDate(new Date().getDate() + 1);
        onChange(new Date(date));
        setTouched(true);
        validateHandle(date);
        setDate(date);
      } else {
      }
    }
  };
  useEffect(() => {
    onBlurTimeHandle();
  }, [valFromPicker]);
  let setDateHandle = (value) => {
    if (value) {
      setDateHandleProps(value);
      onChange(new Date(date));
      setTouched(true);
      validateHandle(date);
      setDate(date);
    }
  };
  let setDateHandleProps = (value) => {
    if (value) {
      date.setFullYear(value.getFullYear());
      date.setMonth(value.getMonth());
      date.setDate(value.getDate());
      date.setHours(value.getHours());
      date.setMinutes(value.getMinutes());
      fillInputHandle();
    }
  };
  const [validateError, setValidateError] = useState("");
  const [serverError, setServerError] = useState("");
  const [touched, setTouched] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  const onError = useContext(FieldContext).onError;
  const subscribe = useContext(FieldContext).subscribe;
  const serverErrors = useContext(FieldContext).serverErrors;
  const onFocusHandle = () => {
    setIsOpen(true);
  };
  useEffect(() => {
    setDateHandleProps(value);
  }, [value]);
  useEffect(() => {
    if (serverErrors) {
      if (serverErrors[unkey]) {
        if (serverErrors[unkey].length) {
          setServerError(serverErrors[unkey][0]);
        }
      }
    }
  }, [serverErrors, unkey]);
  const [key, setKey] = useState("");
  useEffect(() => {
    setKey(generateId());
  }, []);

  const validateHandle = (value) => {
    setValidateError("");
    for (let i = 0; i < validate.length; i++) {
      const func = validate[i];
      let result = func(value);
      if (typeof result === "string") {
        setValidateError(result);
      }
    }
  };
  useEffect(() => {
    validateHandle(value);
    if (!isSubscribed) {
      setIsSubscribed(true);
      subscribe({
        fieldName: key ? key : "",
        onSubmit: () => {
          setTouched(true);
        },
      });
    }
    onError({
      fieldName: key ? key : "",
      error: validateError,
    });
  }, [validateError]);
  let dropJsx = (
    <CMenu
      value={isOpen}
      onChange={(val) => {
        setIsOpen(val);
      }}
      hideOnScroll={hideOnScroll}
      fixOnScreen={fixOnScreen}
    >
      <div className={classes.cMenuWrapper}>
        <TimePicker
          theme="classic"
          timeMode={12}
          colorPalette="light"
          time={valFromPicker}
          onTimeChange={(e) => {

            setValFromPicker(`${e.hour}:${e.minute} ${e.meridiem}`);
          }}
        />

        <ACalendar
          enableAll={true}
          disablePrevDates={true}
          onDatesSelect={(val) => {
            val.setMinutes(date.getMinutes());
            val.setHours(date.getHours())
            setDateHandle(val);
          }}
          multiDaySelect={false}
          className="admin"
        />
      </div>
    </CMenu>
  );
  const onClickIconHandle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={cn(classes.inputWrapper, disabled && classes.disabled)}>
      <AField
        error={validateError || error || serverError}
        touched={touched}
        label={label}
        icon={icon}
        className={className}
        onClickIcon={onClickIconHandle}
      >
        <input
          readOnly
          value={inpVal + "EST"}
          type={type}
          onFocus={onFocusHandle}
        />
      </AField>
      {dropJsx}
    </div>
  );
};
ADateTime.defaultProps = {
  value: "",
  validate: [],
  type: "string ",
  label: "",
  error: "",
  unkey: "",
  icon: "",
  className: "",
  hideOnScroll: true,
  fixOnScreen: true,
  disabled: false,
};
