import { gamesAPI } from "api/games/gamesAPI";
import {
  setGamesAC,
  setHistoryOfGamesAC,
  setNextGameAC,
  setStatistics,
  setUpcomingGames,
  setHomeUpcomingGames,
} from "./actions";
export const getGamesThunk = () => {
  return (dispatch) => {
    gamesAPI.games
      .getGames()
      .then((response) => {
        dispatch(setGamesAC(response.data));
      })
      .catch((error) => {});
  };
};
export const getStatisticsThunk = () => {
  return (dispatch) => {
    gamesAPI.games
      .getStatistics()
      .then((response) => {
        dispatch(setStatistics(response.data));
        // dispatch(setGamesAC(response.data));
      })
      .catch((error) => {});
  };
};

export const getNextGameThunk = () => {
  return (dispatch) => {
    gamesAPI.games
      .nextGame()
      .then((response) => {
        dispatch(setNextGameAC(response.data.results[0]));
      })
      .catch((error) => {});
  };
};
export const getUpcomingGamesThunk = () => {
  return (dispatch) => {
    gamesAPI.games
      .getUpcoming()
      .then((response) => {
        dispatch(setUpcomingGames(response.data));
      })
      .catch((error) => {});
  };
};
export const getHomeUpcomingGamesThunk = () => {
  return (dispatch) => {
    gamesAPI.games
      .getHomeUpcoming()
      .then((response) => {
        dispatch(setHomeUpcomingGames(response.data));
      })
      .catch((error) => {});
  };
};
export const getHistoryOfGames = () => {
  return (dispatch) => {
    gamesAPI.games
      .getHistoryOfGames()
      .then((response) => {
        dispatch(setHistoryOfGamesAC(response.data));
      })
      .catch((error) => {});
  };
};
