import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { confirmThunk } from "store/reducers/user-reducer/thunk";
import c from "./Confirm.module.scss";
import Modal from "react-modal";
import cn from "classnames";
import { Button } from "components/button/Button";
export const Confirm = ({ openLogin }) => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isError, setISetIsError] = useState(false);
  useEffect(() => {
    dispatch(
      confirmThunk(
        queryString.parse(location.search).uidb64,
        queryString.parse(location.search).token,
        setISetIsError
      )
    );
    // history.push("/");
    setIsOpen(true);
  }, []);
  const onReqCloseModal = () => {
    // setIsOpen(false);
    history.push("/");
  };
  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={isOpen}
      onRequestClose={() => onReqCloseModal()}
    >
      <div
        className={cn("iconClose", c.iconClose)}
        onClick={() => {
          onReqCloseModal();
        }}
      ></div>

      {!isError && (
        <div className={cn("main-layout", c.popup)}>
          <div className={c.text}>Email Address Verified</div>
          <div className={c.buttonWrapper}>
            <Button
              height="62px"
              width="218px"
              onClick={() => {
                onReqCloseModal();
                openLogin();
              }}
            >
              Proceed to site
            </Button>
          </div>
        </div>
      )}
      {isError && (
        <div className={cn("main-layout", c.popup)}>
          <div className={c.text}>Invalid verification link</div>
          <div className={c.buttonWrapper}>
            <Button
              height="62px"
              width="218px"
              onClick={() => {
                onReqCloseModal();
              }}
            >
              Back to home page
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
