import React, { useContext, useEffect, useState, useRef } from "react";
import classes from "./Input.module.scss";
import cx from "classnames";
import { Field } from "components/Field/Field";
import { FieldContext } from "../../context/formContext";
import { generateId } from "hooks/global";
import { resetPasswordThunk } from "store/reducers/user-reducer/thunk";
import { useDispatch } from "react-redux";
import { closeToast, displayToast } from "toast/toast";
import { useOnKeyDownEnter } from "../../hooks/keyDown";
export const Input = ({
  value,
  onChange,
  validate,
  label,
  type,
  error,
  unkey,
  showButton,
  handle,
  onKeyDownEnter,
  openModal,
  readonly,
  additionalField
}) => {
  const [validateError, setValidateError] = useState("");
  const [serverError, setServerError] = useState("");
  const [touched, setTouched] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const onError = useContext(FieldContext).onError;
  const subscribe = useContext(FieldContext).subscribe;
  const isSubmited = useContext(FieldContext).isSubmited;
  const serverErrors = useContext(FieldContext).serverErrors;
  const onNext = useContext(FieldContext).onNext;
  const ref = useRef(null);
  const keyDownEnter = useOnKeyDownEnter;
  const dispatch = useDispatch();
  useEffect(() => {
    if (serverErrors) {
      if (serverErrors[unkey]) {
        if (serverErrors[unkey].length) {
          setServerError(serverErrors[unkey][0]);
        }
      }
    }
  }, [serverErrors, unkey]);
  const [key, setKey] = useState("");
  useEffect(() => {
    setKey(generateId());
  }, []);

  const onKeyDownEnterHandle = (e) => {
    keyDownEnter(e, () => {
      onNext(e);
      if (onKeyDownEnter) {
        onKeyDownEnter(e);
      }
    });
  };
  const validateHandle = (value) => {
    setValidateError("");
    for (let i = 0; i < validate.length; i++) {
      const func = validate[i];
      let result = func(value);
      if (typeof result === "string") {
        setValidateError(result);
      }
    }
  };
  const onChangeHandle = (e) => {
    onChange(e.target.value);
    setTouched(true);
    validateHandle(e.target.value);
  };
  useEffect(() => {
    validateHandle(value);
    if (!isSubscribed) {
      setIsSubscribed(true);
      subscribe({
        fieldName: key ? key : "",
        ref: ref,
        onSubmit: () => {
          setTouched(true);
        },
      });
    }
    onError({
      fieldName: key ? key : "",
      error: validateError,
    });
  }, [validateError]);
  let className = cx({
    input: true,
  });

  return (
    <div className={classes.inputWrapper}>
      <Field
        error={validateError || error || serverError}
        touched={touched}
        isSubmited={isSubmited}
        label={label}
      >
        <input
          disabled={readonly}
          ref={ref}
          onChange={onChangeHandle}
          value={value}
          type={type}
          onKeyDown={onKeyDownEnterHandle}
        />
        {showButton && (
          <div
            className={classes.forgotPasswordWrapper}
            onClick={() => {
              openModal()
              if (handle()) {
                dispatch(resetPasswordThunk(handle()));
              } else {
                setValidateError("Email Is Incorrect");
              }
            }}
          >
            <div className={classes.forgotPassword}>Forgot?</div>
          </div>
        )}
      </Field>
    </div>
  );
};
Input.defaultProps = {
  value: "",
  validate: [],
  type: "string ",
  label: "",
  error: "",
  unkey: "",
  showButton: false,
  readonly: false,
  handle: () => {},
  onKeyDownEnter: () => {},
  openModal:()=>{},
  additionalField:''
};
