import { gamesAPI } from "api/admin/gamesAPI";
import { AButton } from "components-admin/AButton/AButton";
import { ADateTime } from "components-admin/ADateTime/ADateTime";
import { AInput } from "components-admin/AInput/AInput";
import { CForm } from "components-сommon/CForm/CForm";
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import {
  createGameThunk,
  getGamesThunkAdmin,
} from "store/reducers/admin-reducer/thunk";
import c from "./CreateEditGameModal.module.scss";
import cn from "classnames";
export const CreateEditGameModal = ({ id, onSave, closeModal }) => {
  const [amount, setAmount] = useState("");
  const [embed_code, setEmbed_code] = useState("");
  const [isPublished, setIsPublished] = useState("");
  const [start, setStart] = useState(
    new Date(
      new Date(new Date().setDate(new Date().getDate() + 1)).setMinutes(0)
    )
  );
  const [timezones, setTimezones] = useState([]);
  const [slectedTimezone, setSelectedTimezone] = useState("Etc/GMT");
  const dispatch = useDispatch();
  const [isAutoGameCreate, setIsAutoGameCreate] = useState(false);
  let isEditMode = id !== true && id !== false;
  // console.log("Is Edit mode " + isEditMode);
  useEffect(() => {
    gamesAPI.info().then((response) => {
      setTimezones(response.data.timezones);
    });
  }, []);
  const submitHandle = (e, action) => {
    let month = start.getMonth() + 1;
    if (month < 10) {
      // @ts-ignore
      month = "0" + month;
    }
    let date = start.getDate();
    if (date < 10) {
      // @ts-ignore
      date = "0" + date;
    }
    let hour = start.getHours();
    if (hour < 10) {
      // @ts-ignore
      hour = "0" + hour;
    }
    let minute = start.getMinutes();
    if (minute < 10) {
      // @ts-ignore
      minute = "0" + minute;
    }
    let year = start.getFullYear();
    let params = {
      start: `${year}-${month}-${date}T${hour}:${minute}:00`,
      embed_code,
      amount,
    };
    if (action === "save") {
      // params.is_published = false;
    }
    if (action === "publish") {
      // params.is_published = true;
    }
    if (id === true) {
      if (isAutoGameCreate) {
        setEmbed_code("");
      }
      if (!isAutoGameCreate) {
        dispatch(
          createGameThunk({
            start: `${year}-${month}-${date}T${hour}:${minute}:00`,
            embed_code,
            amount,
            is_published: true,
            timezone: slectedTimezone,
          })
        );
      }
      if (isAutoGameCreate) {
        dispatch(
          createGameThunk({
            start: `${year}-${month}-${date}T${hour}:${minute}:00`,
            embed_code: "",
            amount,
            is_published: true,
            timezone: slectedTimezone,
          })
        );
      }

      onSave();
    }
    if (id !== false && id !== true) {
      if (action !== "save") {
        gamesAPI.games
          .edit(id, params)
          .then((response) => {
            setAmount(response.data.amount);
            setEmbed_code(response.data.embed_code);
            setIsAutoGameCreate(!response.data.embed_code);
            setStart(new Date(response.data.start));

            onSave();
          })
          .catch((error) => {});
      }
    }
  };

  const editGame = () => {
    let month = start.getMonth() + 1;
    if (month < 10) {
      // @ts-ignore
      month = "0" + month;
    }
    let date = start.getDate();
    if (date < 10) {
      // @ts-ignore
      date = "0" + date;
    }
    let hour = start.getHours();
    if (hour < 10) {
      // @ts-ignore
      hour = "0" + hour;
    }
    let minute = start.getMinutes();
    if (minute < 10) {
      // @ts-ignore
      minute = "0" + minute;
    }
    let year = start.getFullYear();
    let params = {
      start: `${year}-${month}-${date}T${hour}:${minute}:00`,
      embed_code: embed_code,
      amount,
    };

    if (typeof params.amount === "string") {
      params.amount = params.amount.replace(",", "");
      // @ts-ignore
      params.amount = parseInt(params.amount.replace("$", ""));
    }

    gamesAPI.games
      .edit(id, params)
      .then((response) => {
        setAmount(response.data.amount);
        setEmbed_code(response.data.embed_code);
        setIsAutoGameCreate(!response.data.embed_code);

        setStart(new Date(response.data.start));
        onSave();
      })
      .catch((error) => {});
  };

  const rejectHandle = () => {
    console.log("rejectHandle");
  };
  const errorHandle = (e) => {
    console.log("errorHandle");
  };
  const isError = () => {};
  useEffect(() => {
    if (id !== true && id) {
      gamesAPI.games
        .getGame(id)
        .then((response) => {
          setAmount(response.data.amount);
          setEmbed_code(response.data.embed_code);
          setIsAutoGameCreate(!response.data.embed_code);

          setStart(new Date(response.data.start));
          setIsPublished(response.data.is_published);
        })
        .catch((error) => {});
    }
  }, [id]);

  const dateIconJsx = (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#16A49E"
    >
      <path
        d="M17 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 1V5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 1V5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 9H19" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
  return (
    <div className={c.CreateEditGameModal}>
      <div
        className="iconCloseAdmin"
        onClick={() => {
          closeModal();
        }}
      ></div>

      <div className={c.title}>{id === true ? "Create game" : "Edit game"}</div>
      <CForm
        onSubmit={submitHandle}
        onReject={rejectHandle}
        onError={errorHandle}
        serverErrors={isError}
      >
        <div className={cn(c.row, "flex", isPublished && c.disabled)}>
          <ADateTime
            className={c.ADateTime}
            icon={dateIconJsx}
            unkey="date"
            label="Date and time"
            value={start}
            onChange={setStart}
            validate={[(val) => val || "This field is required"]}
            disabled={isPublished}
          />
   
        </div>
        <div className={c.row}>
          <AInput
            type="number"
            unkey="prizeAmount"
            label="Prize amount"
            withFormatter={true}
            value={amount}
            onChange={setAmount}
            validate={[
              (val) => (val && val.length > 0) || "This field is required",
              (val) => val.length < 10 || "This field is too long",
            ]}
          />
        </div>
        <div className={c.row}>
          <AInput
            unkey="embedCode"
            label="Video feed link (youtube or twitch)"
            value={embed_code}
            placeholder={"<Iframe/>"}
            onChange={setEmbed_code}
          />
        </div>

        <div className={cn(c.row, c.pointer)}>
         
          <label
            htmlFor="withauto"
            className={c.pointer}
            style={{ opacity: embed_code.length <= 0 ? 1 : 0 }}
          >
            Autogame (winning numbers generate automatically, no video)
          </label>
        </div>

        <div className={c.buttonWrapper}>
          <AButton
            submitParam="save"
            round
            className={c.save}
            bigger
            onClick={() => {
              onSave();
            }}
          >
            <div>Cancel</div>
          </AButton>
          {isEditMode ? (
            <AButton
              submitParam="publish"
              fill
              className={c.publish}
              bigger
              onClick={editGame}
            >
              <div>Save changes </div>
            </AButton>
          ) : (
            <AButton
              submitParam="publish"
              fill
              submit
              className={c.publish}
              bigger
            >
              <div>Save </div>
            </AButton>
          )}
        </div>
      </CForm>
    </div>
  );
};

CreateEditGameModal.defaultProps = {
  id: "",
};
