import FormContext from "../../context/formContext";
import React, { useContext, useState, useRef, useEffect } from "react";
import classes from "../button/Button.module.scss";
import cn from "classnames";
export const Button = ({
  width,
  height,
  submit,
  reject,
  onClick,
  children,
  onKeyDownEnter,
  disabled,
  border,
  backgroundColor
}) => {
  const onSubmitForm = useContext(FormContext).onSubmit;
  const onRejectForm = useContext(FormContext).onReject;
  const subscribe = useContext(FormContext).subscribe;
  const [isSubscribed, setIsSubscribed] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    if (!isSubscribed) {
      setIsSubscribed(true);
      if (submit) {
        subscribe({
          ref: ref,
        });
      }
    }
  }, []);
  const divStyle = {
    width: width,
    height: height,
    background :(backgroundColor ? backgroundColor :'')
  };
  const onClickHandle = (e) => {
    if (submit) {
      onSubmitForm(e);
    } else if (reject) {
      onRejectForm(e);
    } else {
      onClick(e);
    }
  };

  return (
    <button
      ref={ref}
      onClick={() => {
        onClickHandle();
      }}
      style={divStyle}
      className={cn({
        [classes.border]: border,
        [classes.disabled]: disabled,
        [classes.buttonWrapper]: true
      })}
    >
      {children}
    </button>
  );
};
Button.defaultProps = {
  width: "270px",
  height: "77px",
  onClick: () => {},
  onKeyDownEnter: () => {},
  reject: false,
  submit: false,
  disabled: false,
  backgroundColor:false
};
