import { css } from "glamor";
import { toast } from "react-toastify";
let toastId = null;

export const displayToast = (value , position="top-right") => {
  if (!toast.isActive(toastId)) {
    toastId = toast(value, {
      // @ts-ignore
      className: css({
        fontWeight: "bold",
      }),
      closeOnClick: false,
      toastId: "my_toast",
      // @ts-ignore
      autoClose: true,
      closeButton: true,
      position: position,
    });
  } else {
  }
};
export const closeToast = () => {
  toast.isActive(null);
};
