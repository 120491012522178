import { instance } from "api/axios-instance";

export const gamesAPI = {
  games: {
    getList: (page) => {
      const limit = 10;
      return instance.get(`games/`, {
        params: {
          limit: limit,
          ordering: "-start",
          offset: Math.ceil((page - 1) * limit),
        },
      });
    },
    create: (game) => {
      return instance.post(`games/`, game);
    },
    getGame: (id) => {
      return instance.get(`games/${id}/`);
    },
    edit: (id, game) => {
      return instance.put(`games/${id}/`, game);
    },
    patchGame: (id, game) => {
      return instance.patch(`games/${id}/`, game);
    },
  },
  info: () => {
    return instance.get(`info/`);
  },
};
